<template>
    <div id="theme-banner-one">
        <div class="illustration wow zoomIn animated" data-wow-delay="0.5s" data-wow-duration="2s">
            <img :src="$websiteEnvironment.assetsDir + '/website-svgs/coming-soon/' + 'coming-soon-book.png'"
                 alt="" class="wow fadeInDown animated">
        </div>
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt="" class="shape-three">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt="" class="shape-four">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt="" class="shape-five">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt="" class="shape-six">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt="" class="shape-seven">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt="" class="shape-eight">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt="" class="shape-nine">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt="" class="shape-ten">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt="" class="shape-eleven">
        <div class="container">
            <div id="mainWrapper" class="main-wrapper text-center pt-5 pb-0">
                <div class="row wow fadeInUp animated">
                    <div class="col-md-6 col-sm-12">
                        <div class="row">
                            <img src="assets/images/logo/logo-svg.svg" class="m-auto" alt="tamayoz-logo" width="100px" height="150px">
                        </div>
                        <div class="row mt-5">
                            <h1 class="main-title m-auto" data-wow-delay="0.4s">.. COMING SOON</h1>
                        </div>

                        <div class="row mt-5" id="onlyOnSm">
                            <img :src="$websiteEnvironment.assetsDir + '/website-svgs/coming-soon/' + 'coming-soon-book.png'"
                                 alt="" class="wow fadeInDown animated">
                        </div>

                        <!-- Timer -->
                        <!--                        <CountDown date="2021-01-03 20:00:00"></CountDown>-->
                    </div>

                    <div class="col-md-6 col-sm-12"></div>
                </div>

            </div> <!-- /.main-wrapper -->
        </div> <!-- /.container -->
    </div>
</template>

<script>
    import CountDown from "../../../shared/components/CountDown";

    export default {
        name: "ZadiComingSoon",
        metaInfo: {
            title: 'انتظرونا',
        },
        components: {CountDown}
    }
</script>

<style scoped>
    #theme-banner-one {
        background: none;
        position: relative;
    }

    #theme-banner-one .main-wrapper {
        padding: auto;
    }

    #theme-banner-one .illustration {
        position: absolute;
        left: 0%;
        top: 20%;
        width: 40%;
    }

    /*Hide for larger screens*/
    #onlyOnSm {
        display: none;
    }

    #mainWrapper {
        margin-top: 5%;
        margin-bottom: 20%;
    }

    /*show for small screens */
    @media screen and (max-width: 1023px) {
        #onlyOnSm {
            display: block;
        }

        #mainWrapper {
            margin-top: 5%;
            margin-bottom: 5%;
        }
    }

    @media (min-width: 1400px) {
        #theme-banner-one .illustration {
            position: absolute;
            left: 0%;
            top: 20%;
            width: 36%;
        }
    }

    @media (min-width: 1920px) {
        #theme-banner-one .illustration {
            position: absolute;
            left: 0%;
            top: 20%;
            width: 30%;
        }
    }
</style>
