<template>
  <!--
        =============================================
          Contact Banner
        ==============================================
   -->
  <div class="agn-contact-banner">
    <div class="container">
      <h2 class="title">هل لديك تساؤلات واستفسارات؟ <br><small>لا تتردد بالتواصل معنا</small></h2>
      <router-link tag="a" :to="{name:'ContactUs'}" class="contact-button line-button-one">تواصل معنا</router-link>
    </div> <!-- /.contianer -->
  </div> <!-- /.agn-contact-banner -->
</template>

<script>
  export default {
    name: 'website-contact-banner'
  }
</script>

<style scoped>

</style>
