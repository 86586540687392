<template>
  <div>
    <Loader :isLoading="isLoading"></Loader>
    <div id="theme-banner-one">
      <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
        <img :src="$websiteEnvironment.assetsDir + '/website-svgs/questions-and-answers/' + 'Group-1573.svg'"
             alt="" class="wow fadeInDown animated">
      </div>
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt=""
           class="shape-three">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt=""
           class="shape-four">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt=""
           class="shape-five">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt=""
           class="shape-six">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt=""
           class="shape-seven">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt=""
           class="shape-eight">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt=""
           class="shape-nine">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt=""
           class="shape-ten">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt=""
           class="shape-eleven">
      <div class="container">
        <div class="main-wrapper">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">سؤال وجواب</h1>
          <h4 class="wow fadeInUp animated title mt-5" data-wow-delay="0.9s">
            لديك سؤال ؟ قد تجد إجابتك هنا
          </h4>
        </div>

        <div class="row text-center">
          <div class="col-12">
            <a v-for="tag in tagsList" :class="{active:tag.isActive}"
               class="contact-button line-button-one mx-3 my-3"
               href="javascript:"
               @click="tagClicked(tag)">
              {{ tag.name }}
            </a>
          </div>
          <div class="col-12">
            <a class="btn btn-outline-danger  mx-3 my-3"
               href="javascript:"
               @click="resetFilters()">
              حذف المرشحات
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-150">
        <div class="col-12">
          <div class="faq-tab-wrapper">
            <div class="faq-panel">
              <div id="accordion" class="panel-group theme-accordion">
                <div v-for="(faq, index) in FAQList" class="panel wow fadeInUp animated" data-wow-delay="0.7s"
                     data-wow-duration="0.5s" style="margin: 0 !important;">
                  <div :class="index ? 'panel-heading' : 'panel-heading active-panel'">
                    <h6 class="panel-title">
                      <a aria-expanded="true" data-parent="#accordion"
                         data-toggle="collapse"
                         v-bind:href="'#'+faq.id+''"
                      >
                        {{ faq.title }}
                      </a>
                    </h6>
                  </div>
                  <div v-bind:id="''+faq.id+''" :class="index ? '' : 'show'"
                       class="panel-collapse collapse">
                    <div class="panel-body">
                      <ul>
                        <li>
                          <p id="message" v-html="faq.short_desc"></p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="element-section mb-150">
                  <div class="container">
                    <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
                      <span v-show="!isPagenateBackHidden" class="theme-pager prev mx-4"
                            v-on:click="paginate('previous')">
                          <span class="flaticon-next mx-2"></span>
                           &nbsp;&nbsp;السابق
                      </span>
                      <span v-show="canLoadMore" class="theme-pager next mx-4" v-on:click="paginate('next')">
                        التالي &nbsp;&nbsp;
                        <span class="flaticon-back mx-2"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div> <!-- end #accordion -->
            </div> <!-- End of .faq-panel -->
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <h2 style="text-align: center">أو , يمكنك سؤالنا مباشرةً</h2>
      </div>
    </div>
    <contact-form type="Message"></contact-form>
  </div>
</template>

<script>
import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
import Loader from '../../shared/components/Loader'
import {RepositoryFactory} from "../repositories/RepositoryFactory";
import contactForm from "../website-components/website-contact/contactForm";

const blogRepository = RepositoryFactory.get('blog');
const feedbackRepo = RepositoryFactory.get('feedback');
export default {
  name: 'Faqs',
  metaInfo: {
    title: 'سؤال و جواب'
  },
  components: {
    DynamicMasterLayout,
    Loader,
    contactForm
  },
  data() {
    return {
      FAQList: [],
      OriginalFAQList: [],
      tagsList: [], question: {
        type: 'Message',
        author: '',
        email: '',
        mobile: '',
        message: '',
      },
      selectedType: 'FAQ',
      isEmptyResult: true,
      page: '',
      tagId: '',
      nextPageUrl: '',
      prevPageUrl: '',
      perPage: 5,
      canLoadMore: true,
      isPagenateBackHidden: true,
      isLoading: true,
    }
  },
  created() {
    this.getBlogByType(this.selectedType);
  },
  methods: {
    init() {
      this.$objectHelpers.initObjectMembers(this.question);
      this.question.type = "Message";
      this.$refs.validator.reset();
    },
    resetFilters() {
      this.tagId = '';
      this.getBlogByType(this.selectedType)
    },
    getBlogByType(Type) {
      this.isLoading = true;
      blogRepository.fetchBlogByType(Type, this.page, this.perPage, this.tagId).then((res) => {
        this.OriginalFAQList = res.blogs.entities;
        this.FAQList = this.OriginalFAQList;
        this.tagsList = res.tags;
        if (this.tagId === '') {
          this.tagsList = this.tagsList.map(obj => ({...obj, isActive: false}));
        } else {
          this.tagsList.forEach(tagObject => {
            if (tagObject.id === this.tagId)
              tagObject.isActive = this.tagId
          });
        }
        this.isLoading = false;
        this.isEmptyResult = this.FAQList.length == 0 ? true : false;
        this.prevPageUrl = res.blogs.prev_page_url;
        this.isPagenateBackHidden = this.prevPageUrl != '' ? false : true;
        this.nextPageUrl = res.blogs.next_page_url;
        this.canLoadMore = this.nextPageUrl != '' ? true : false;
      });
    },
    tagClicked(tag) {
      this.tagId = tag.id;
      this.tagsList.forEach(tagObject => {
        tagObject.isActive = tagObject.id === tag.id;
      });
      this.getBlogByType(this.selectedType);
    },
    paginate(paginateType) {
      this.isLoading = true;
      this.getBlogByType(this.selectedType,
        paginateType == 'next' ?
          this.page = this.nextPageUrl.split(/[? &]/)[1]
          :
          this.page = this.prevPageUrl.split(/[? &]/)[1]
      );
    }
  }
}
</script>

<style scoped>
#theme-banner-one .illustration {
  position: absolute;
  left: -10%;
  top: 0%;
  width: 40%;
}

.faq-tab-wrapper .faq-panel .panel {
  background: #fff;
  /* box-shadow: 0px 10px 14px 0px rgba(90, 111, 155, 0.05); */
  box-shadow: none;
  margin-bottom: 30px;
}

.active {
  background-color: #4B364E !important;
  color: #fff !important
}

.faq-tab-wrapper .faq-panel .panel .panel-heading .panel-title a:before {
  content: '+' !important;

}

.faq-tab-wrapper .faq-panel .panel .panel-heading.active-panel .panel-title a:before {
  content: '-' !important;
}
</style>
