<template>
  <div class="component-skeleton">
    <div class="image-skeleton"></div>
    <div class="text-skeleton">
      <div class="title-skeleton"></div>
      <div class="description-skeleton"></div>
    </div>
  </div>
</template>


<script>
export default {
  props: {},
};
</script>

<style scoped>
/* Skeleton container */
.component-skeleton {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Image skeleton */
.image-skeleton {
  width: 52px; /* Adjust to the size of your image */
  height: 52px; /* Adjust to the size of your image */
  background-color: #e0e0e0;
  border-radius: 5px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Text skeleton */
.text-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.title-skeleton {
  width: 50%;
  height: 18px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.description-skeleton {
  width: 80%;
  height: 18px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Animation for skeleton loader */
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
</style>
