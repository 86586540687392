var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agn-about-us"
  }, [_c('img', {
    staticClass: "shape-one",
    attrs: {
      "src": "assets/images/website/website-services/shape/shape-61.svg",
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-two",
    attrs: {
      "src": "assets/images/website/website-services/shape/shape-64.svg",
      "alt": "",
      "data-aos": "fade-right"
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": "assets/images/website/website-services/shape/shape-65.svg",
      "alt": "",
      "data-aos": "fade-left"
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": "assets/images/website/website-services/shape/shape-60.svg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('div', {
    staticClass: "theme-title-one text-center"
  }, [_c('h2', {
    staticClass: "main-title"
  }, [_vm._v("خدمات المؤسسة")]), _c('div', {
    staticClass: "row p-5"
  }, _vm._l(_vm.servicesList, function (service, index) {
    return _c('div', {
      key: index,
      staticClass: "col-lg-4 col-md-6 col-sm-12 p-4 wow fadeInUp animated"
    }, [_c('img', {
      staticClass: "m-auto CustomImageHeight",
      attrs: {
        "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + service.image
      }
    }), _c('h4', {
      staticClass: "title"
    }, [_vm._v(_vm._s(service.title))]), _c('p', [_vm._v(_vm._s(service.description))])]);
  }), 0)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }