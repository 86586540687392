var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-empty-state"
  }, [_c('img', {
    attrs: {
      "src": "/assets/images/website/global-search/empty-search-icon.svg"
    }
  })]);
}]

export { render, staticRenderFns }