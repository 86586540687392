var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agn-contact-banner"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('router-link', {
    staticClass: "contact-button line-button-one",
    attrs: {
      "tag": "a",
      "to": {
        name: 'ContactUs'
      }
    }
  }, [_vm._v("تواصل معنا")])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "title"
  }, [_vm._v("هل لديك تساؤلات واستفسارات؟ "), _c('br'), _c('small', [_vm._v("لا تتردد بالتواصل معنا")])]);
}]

export { render, staticRenderFns }