var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entity-tab",
    style: {
      borderBottom: _vm.isActive && !_vm.disabled ? '2px solid #745A6E' : 'unset',
      cursor: !_vm.disabled ? 'pointer' : 'not-allowed'
    },
    on: {
      "click": _vm.clickHandler
    }
  }, [_c('p', {
    staticClass: "entity-tab-text",
    style: {
      color: _vm.isActive && !_vm.disabled ? '#745A6E' : '#667085'
    }
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "entity-tab-count"
  }, [_vm._v(_vm._s(_vm.count))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }