<template>
  <div>
    <loader :is-loading="isLoading"/>
    <div class="our-blog blog-masonry">
      <div class="container text-center" v-if="blogs.length == 0">
        <h5>لا يوجد نتائج</h5>
      </div>
      <div class="container" v-else>
        <div class="row masnory-blog-wrapper">
          <div class="isotop-item col-lg-4" v-for="(item, index) in blogs"
               :key="index"
               @click="NavigateToDetailsPage(item.id)">
            <div class="single-blog-post">
              <div class="img-holder">
                <img v-if="item.image_preview_url" :src="item.image_preview_url" alt="">
                <img v-if="!item.image_preview_url"
                     :src="$websiteEnvironment.assetsDir + '/website-svgs/news/'+'Group-1574.svg'"
                     alt="">
              </div>

              <div class="post-data">
                <h5 class="blog-title-one title" style="font-family: Cairo !important">
                  <a href="javascript:"> {{item.title}} </a>
                </h5>
                <p class="blog-title-tow title" style="font-family: Cairo !important">
                  <a href="javascript:" v-html="item.short_desc"/>
                </p>
                <a href="javascript:" class="read-more"><i class="flaticon-back-1"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="element-section mb-150">
      <div class="container">
        <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
                            <span v-show="!isPagenateBackHidden" v-on:click="paginate('previous')"
                                  class="theme-pager prev mx-4">
                                <span class="flaticon-next mx-2"></span>
                                 &nbsp;&nbsp;السابق
                            </span>
          <span v-show="canLoadMore" v-on:click="paginate('next')" class="theme-pager next mx-4">
                                التالي &nbsp;&nbsp;
                                <span class="flaticon-back mx-2"></span>
                             </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/RepositoryFactory";
  import Loader from '../../../shared/components/Loader'

  const blogRepository = RepositoryFactory.get('blog')

  export default {
    name: "Blogs",
    props: {
      type: {
        type: String,
        default: 'News'
      },
    },
    components: {
      Loader
    },
    data() {
      return {
        blogs: [],
        page:'',
        nextPageUrl:'',
        prevPageUrl :'',
        perPage: 6,
        canLoadMore: true,
        isPagenateBackHidden: true,
        isLoading:true
      }
    },
    methods: {
      getBlogByType(filter) {
        blogRepository.fetchBlogByType(filter.type, this.page, this.perPage).then((res) => {
          this.blogs.push(...res.blogs.entities);
          this.isLoading = false;
          this.prevPageUrl = res.blogs.prev_page_url;
          this.isPagenateBackHidden = this.prevPageUrl != '' ? false : true;
          this.nextPageUrl = res.blogs.next_page_url;
          this.canLoadMore =  this.nextPageUrl  != '' ? true : false;
        });
      },

      NavigateToDetailsPage(blogId) {
        this.$router.push({name: 'Full-Details', query: {id: blogId}});
      },

      paginate(paginateType) {
        this.isLoading = true;
        this.blogs = [];

        this.getBlogByType(
          {type: this.type},
          paginateType == 'next' ?
                  this.page = this.nextPageUrl.split(/[? &]/)[1]
            :
                this.page = this.prevPageUrl.split(/[? &]/)[1]
        );
      }
    },
    created() {
      this.getBlogByType({type: this.type});
    }
  }
</script>

<style scoped>

</style>
