<template>
  <div class="training-form">
    <Loader :isLoading="isLoading"></Loader>
    <div class="solid-inner-banner">
      <div class="container">
        <img :src="$websiteEnvironment.assetsDir + '/website-forms/Group 2218.png'" alt=""
             class="centered" style="padding-bottom:30px">
        <h4 class="pb-4 font-wight-bold"><b>طلب تطوع</b></h4>
        <h5 class=" font-wight-bold">لدى مؤسسة التميز التنموية</h5>
      </div>
    </div>
    <div class="solid-inner-banner banner">
      <div class="contact-form">
        <ValidationObserver ref="validator">
          <form @submit.prevent="onSubmit" class="form form-style-two" data-toggle="validator" id="contact-form"
                novalidate="true">
            <div class="row">
              <div class="col-lg-6 card-p paddingBottom" style="padding-right: 5%; padding-left: 2%">
                <div class="card cardWidth paddingBottom" style="border-radius: 15px">
                  <div class="container">
                    <h5 class="firstMobileFontSize sub-heading"><b>المعلومات الشخصية</b></h5>
                    <forms-input name="الاسم الثلاثي" type="text" v-model="personalInfo.fullname"
                                 validationRules="required"/>
                    <div class="row">
                      <div class="col-lg-4 p-r">
                        <h6 class="pb-5  inputLabel secondMobileFontSize labelColor"
                            style="padding-right:16%; padding-top:10px">
                          الجنس</h6>
                      </div>
                      <div class="col-lg-8 mp" style="padding-right:5%">
                        <ValidationProvider name="الجنس" rules="required" v-slot="{ errors }">
                          <div class="form-check form-check-inline" style="padding-right:15px">
                            <input class="payment-radio-button" id="male" name="gender"
                                   type="radio" v-model="personalInfo.gender"
                                   value="male"
                            >
                            <h6 class="labelColor" style="padding-right:10px"><b>ذكر</b></h6>
                          </div>

                          <div class="form-check form-check-inline" style="padding-right:50px">
                            <input class="payment-radio-button" id="female" name="gender"
                                   type="radio" v-model="personalInfo.gender"
                                   value="female"
                            >
                            <h6 class="labelColor" style="padding-right:10px"><b>أنثى</b></h6>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>

                    <forms-select :options="nationalityOptions" :selectAction="selectOther" name="الجنسية"
                                  type="text" v-model="personalInfo.nationality" validationRules="required"/>

                    <forms-input name="الجنسية" type="text" v-if="addOtherNationality"
                                 v-model="personalInfo.nationality" validationRules="required"/>
                    <forms-input name="مكان القيد" type="text" v-model="personalInfo.kayd" validationRules="required"/>
                    <div class="row">
                      <div class="col-lg-4 p-r">
                        <h6 class="pb-5 inputLabel secondMobileFontSize labelColor"
                            style="padding-right:16%; padding-top:10px">
                          تاريخ الميلاد</h6>
                      </div>
                      <div class="col-lg-8 mp">
                        <ValidationProvider name="تاريخ الميلاد" rules="required" v-slot="{ errors }">
                          <date-picker align="center" dialog hide-overlay max-date="2010-12-31"
                                       min-date="1990-01-1" v-bind:class="[errors.length > 0 ? 'datePickerBorder' : '']"
                                       v-model="personalInfo.birthday"/>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <forms-input name="مكان الولادة" type="text" v-model="personalInfo.birthplace"
                                 validationRules="required"/>

                    <forms-select :options="educationLevelOptions" :selectAction="selectOther" name="المرحلة الدراسية"
                                  type="text" v-model="personalInfo.education_level"
                                  validationRules="required"/>


                    <forms-input name="اسم المدرسة" type="text" v-if="addSchool" v-model="personalInfo.school"
                                 validationRules="required"/>

                    <forms-input name="الفرع الجامعي" type="text" v-if="addUniversity" v-model="personalInfo.university"
                                 validationRules="required"/>

                    <forms-select :options="educationYearOptions" name="السنة الدراسية" type="text" v-if="addUniversity"
                                  v-model="personalInfo.class" validationRules="required"/>

                    <forms-select :options="schoolYearOptions" name="السنة الدراسية" type="text" v-if="addSchool"
                                  v-model="personalInfo.class" validationRules="required"/>

                    <h5 class="firstMobileFontSize mp sub-heading"><b>معلومات التواصل</b></h5>

                    <forms-input name="رقم الهاتف" type="number" v-model="personalInfo.phone"
                                 validationRules="digits:7"/>

                    <forms-input :validationRules="{ required: true , digits: 10, regex: /^09\d{8}$/}"
                                 name="رقم الواتساب" type="number"
                                 v-model="personalInfo.mobile"/>

                    <forms-input name="العنوان" type="text" v-model="personalInfo.address" validationRules="required"/>

                    <forms-input :reduceFontSize="true"
                                 :validationRules="{ required: true, regex: /http(?:s):\/\/(?:www\.)facebook\.com\/.+/i}"
                                 name="رابط حساب الفيسبوك"
                                 type="text"
                                 v-model="personalInfo.facebook_profile"/>

                  </div>
                </div>
              </div>
              <div class="col-lg-6 card-p" style="padding-right:2%; padding-left: 5%">
                <div class="card cardWidth paddingBottom" style="border-radius: 15px">
                  <div class="container">
                    <div class="row justify-content-between mt-4">
                      <div class="col-6">
                        <h5 class="firstMobileFontSize pl-4 pb-5 mp"><b>معلومات التطوع</b></h5>
                      </div>
                    </div>

                    <forms-select :isMultiple="true" :options="volunteeringOptions" :selectAction="selectOther"
                                  name="مجالات التطوع" placeholder="اختر مجال أو أكثر" type="text" v-model="field"
                                  validationRules="required"/>

                    <forms-select :isMultiple="true" :options="daysOptions" :selectAction="selectOther"
                                  name=" أيام التطوع" placeholder="اختر يوم أو عدة أيام" type="text"
                                  v-model="personalInfo.days"
                                  validationRules="required"/>

                    <forms-input :reduceFontSize="true" name="ساعات التطوع يومياً" type="number"
                                 v-model="personalInfo.hours" validationRules="required|numeric|max_value:24"/>

                  </div>
                </div>
                <forms-card :data-array="personalInfo.other_skills" :data-object="{skill: '',desc: ''}"
                            :is-padding-top="true"
                            add-title="إضافة مهارة" title="المهارات">
                  <template slot="inputs" slot-scope="{item,index}">
                    <forms-select :customWidth="true" :options="skillsOptions"
                                  :selectAction="checkSkill(item.skill,index)"
                                  name="المهارة"
                                  v-model="item.skill"
                                  validationRules="required"/>

                    <forms-input name="وصفها" type="text" v-model="item.desc" validationRules="required"/>
                  </template>
                </forms-card>
                <forms-card :data-array="personalInfo.previous_volunteer_works" :data-object="{ work: '', place: ''}"
                            :is-padding-top="true"
                            add-title="إضافة عمل" title="أعمال تطوعية سابقة">
                  <template slot="inputs" slot-scope="{item,index}">
                    <forms-input :customWidth="true" name="العمل" type="text" v-model="item.work"
                                 validationRules="required"/>
                    <forms-input name="مكان التطوع" type="text" v-model="item.place" validationRules="required"/>
                  </template>
                </forms-card>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="row justify-content-center sendButton centered" style="padding-top: 150px; padding-bottom: 100px">
        <button class="more-button solid-button-one wow fadeInLeft  animated" data-wow-delay="0.5s" form="contact-form"
                style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;"
                type="submit">
          ارسال
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/RepositoryFactory";
  import Loader from '../../../shared/components/Loader'
  import vSelect from 'vue-select'
  import {DatePicker} from 'vue-drumroll-datetime-picker'
  import 'vue-drumroll-datetime-picker/dist/style.css';
  import formsInput from '@/app/shared/components/formsInput.vue';
  import formsSelect from '@/app/shared/components/formsSelect.vue';
  import formsCard from '../../website-components/forms/formsCard.vue';
  import utilities from "@/app/shared/utilities/index"


  const volunteerFormService = RepositoryFactory.get('form');

  export default {
    name: 'websiteTrainingForm',
    metaInfo: {
      title: ' طلب تطوع',
    },

    components: {
      Loader,
      vSelect,
      DatePicker,
      formsInput,
      formsSelect,
      formsCard
    },
    data() {
      return {
        daysOptions: [
          'الأحد',
          'الاثنين',
          'الثلاثاء',
          'الأربعاء',
          'الخميس'
        ],
        personalInfo: {
          fullname: '',
          birthday: '',
          birthplace: '',
          gender: '',
          kayd: '',
          nationality: '',
          phone: '',
          mobile: '',
          education_level: '',
          school: '',
          class: '',
          university: '',
          hours: '',
          field: '',
          facebook_profile: '',
          other_skills: [{
            skill: '',
            desc: ''
          }],
          days: [],
          previous_volunteer_works: [{
            work: '',
            place: ''
          }]
        },
        genderOptions: [
          {
            name: 'ذكر',
            value: 'male'
          },
          {
            name: 'أنثى',
            value: 'female'
          }
        ],
        nationalityOptions: [
          'سورية', 'فلسطينية', 'غير ذلك'],
        educationLevelOptions: ['إعدادي', 'ثانوي', 'جامعي', 'تارك الدراسة'],
        volunteeringOptions: ["خدمات تعليمية", "خدمات إدارية ومكتبية", "إقامة أنشطة وفعاليات", "إعطاء محاضرات وورشات عمل", "القيام بزيارات منزلية للأسر المستفيدة", "خدمات طبية وصحية"],
        educationYearOptions: ['1', '2', '3', '4', '5', '6', 'خريج'],
        schoolYearOptions: [],
        skillsOptions: ['الكترونية', 'تعليمية', 'تدريبية', 'التعامل مع الأطفال', 'لايوجد'],
        isLoading: false,
        addOtherNationality: false,
        addSchool: false,
        addUniversity: false,
        field: []
      }
    },
    methods: {
      init() {
        this.$objectHelpers.initObjectMembers(this.personalInfo);
        this.personalInfo.days = [];
        this.personalInfo.previous_volunteer_works = [{
          work: '',
          place: ''
        }];
        this.personalInfo.other_skills = [{
          skill: '',
          desc: ''
        }];
        this.$refs.validator.reset();
      },
      selectOther(selected) {
        if (selected === 'غير ذلك') {
          this.addOtherNationality = true;
          this.personalInfo.nationality = '';
        }
        if ((selected === 'سورية') || (selected === 'فلسطينية'))
          this.addOtherNationality = false;
        if (selected === 'إعدادي' || selected === 'ثانوي') {
          this.setSchoolYears();
          this.addSchool = true;
          this.addUniversity = false;
          this.personalInfo.university = '';
        }
        if (selected === 'جامعي') {
          this.addUniversity = true;
          this.addSchool = false;
          this.personalInfo.school = '';
        }
        if (selected === 'تارك الدراسة') {
          this.addUniversity = false;
          this.addSchool = false;
          this.personalInfo.university = '';
          this.personalInfo.school = '';
          this.personalInfo.class = '';
        }
      },
      checkSkill(selected, index) {
        if (selected === 'لايوجد') {
          this.personalInfo.other_skills[index].desc = 'لا يوجد'
        }
      },
      setSchoolYears(){
        if(this.personalInfo.education_level === 'إعدادي')
          this.schoolYearOptions = ['الثامن','التاسع'];
        else
           this.schoolYearOptions = ['العاشر','الحادي عشر','البكالوريا'];
      },
      selectDay(day) {
        if (this.personalInfo.days.includes(day))
          this.personalInfo.days = this.personalInfo.days.filter(item => item !== day);
        else
          this.personalInfo.days.push(day)
      },
      checkPreviousVolunteerWork() {
        let checkPlace = false;
        this.personalInfo.previous_volunteer_works.forEach(item => {
          if (item.work !== '' && item.place === '')
            checkPlace = true;
        });
        return checkPlace
      },
      onSubmit() {
        this.$refs.validator.validate().then(success => {
          if (!success) {
            utilities.checkForm(this.$refs.validator);
          } else {
            if (this.personalInfo.previous_volunteer_works[0].work === '')
              this.personalInfo.previous_volunteer_works = [];
            if (this.checkPreviousVolunteerWork()) {
              this.$swal({
                icon: 'warning',
                text: 'يجب إدخال مكان العمل التطوعي السابق',
                confirmButtonText: 'حسناَ',
              });
            } else {
              this.personalInfo.field = this.field.toString();
              this.isLoading = true;
              volunteerFormService.saveVolunteer(this.personalInfo).then(response => {
                this.isLoading = false;
                if (response.code === 201) {
                  this.$swal({
                    icon: 'success',
                    title: 'نجح',
                    text: response.message
                  });
                }
                this.init();
              });
            }
          }
        });
      },
    }
  }
</script>

<style>
  @import '../../../../../public/assets/css/formsStyle.css';
</style>



