<template>
	<!-- =============================================
                      Theme Main Menu
      ============================================== -->
	<div v-bind:class="{ changeNavbarColor: isWhite }">
	<div class="theme-main-menu theme-menu-one">
		<div class="logo my-1">
			<a href="/">
				<img src="assets/images/logo/logo-svg.svg" alt="tamayoz-logo" width="80px" height="60px">
			</a>
		</div>
		<nav id="mega-menu-holder" class="navbar navbar-expand-lg" style="margin-right: -80px">
			<div class="container nav-container">
				<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
						aria-label="Toggle navigation">
					<i class="flaticon-setup"></i>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav">
						<li class="nav-item">
							<router-link :to="{ name: 'Home' }" class="nav-link">الرئيسية</router-link>
						</li>
						<li class="nav-item dropdown position-relative">
							<router-link :to="{ name: 'Kareem' }" class="nav-link">
								تبرع كريم
							</router-link>
						</li>
						<li class="nav-item dropdown position-relative">
							<router-link :to="{ name: 'ZadiHome' }" class="nav-link">
								 زادي
								<p class="new-feature">جديد</p>
							</router-link>
						</li>
						<li class="nav-item dropdown position-relative">
							<router-link :to="{ name: 'SuccessStories' }" class="nav-link">قصص مؤثرة</router-link>
						</li>
						<li class="nav-item dropdown">
							<router-link :to="{ name: 'Faqs' }" class="nav-link">سؤال و جواب</router-link>
						</li>
						<li class="nav-item dropdown position-relative">
							<router-link :to="{ name: 'News' }" class="nav-link">الأخبار</router-link>
						</li>
						<li class="nav-item dropdown position-relative">
							<a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">النماذج</a>
							<ul class="dropdown-menu">
								<router-link :to="{ name: 'JobApplicationForm' }" class="dropdown-item">توظيف</router-link>
								<router-link :to="{ name: 'TeachingForm' }" class="dropdown-item">تدريس</router-link>
								<router-link :to="{ name: 'TrainingForm' }" class="dropdown-item">تدريب</router-link>
								<router-link :to="{ name: 'VolunteerForm' }" class="dropdown-item">تطوع</router-link>
							</ul>
						</li>
						<li class="nav-item dropdown position-relative">
							<router-link :to="{ name: 'EcommerceHome' }" class="nav-link">متجر التميز</router-link>
						</li>
<!--						<li class="nav-item dropdown position-relative">-->
<!--							<a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">تبرع الآن</a>-->
<!--							<ul class="dropdown-menu">-->
<!--								<router-link :to="{ name: 'DonationForm' }" class="dropdown-item">التبرع</router-link>-->
<!--								<router-link :to="{ name: 'DonationInviteFormComingSoon' }" class="dropdown-item">دعوة للتبرع</router-link>-->
<!--							</ul>-->
<!--						</li>-->
					</ul>
				</div>
			</div> <!-- /.container -->
		</nav> <!-- /#mega-menu-holder -->
		<div class="header-right-widget">
			<ul>
        <li>
          <GlobalSearch />
        </li>
				<li>
					<router-link :to="{ name: 'orphanSponsorshipSteps' }" class="contact-us white-shdw-button">
						خطوات الكفالة
						<i class="icon flaticon-back"></i>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
	</div>
	<!-- /.theme-main-menu -->
</template>

<script>
  import GlobalSearch from '@/app/website/website-components/global-search/components/global-search.vue';

	export default {
		name: 'the-navbar',
    components: {
      GlobalSearch
    },
		props:{
			isWhite: {
				type: Boolean,
				default: false
			},
		},
		watch: {
			'$route'() {
				$('.navbar-collapse').collapse('hide');
			}
		}
	}
</script>

<style scoped>
	ul {
		white-space: nowrap;
	}

  .header-right-widget ul {
    display: flex;
    gap: 15px;
    align-items: center;
  }

	.white-shdw-button {
		padding: 0 0;
    box-shadow: none;
    padding-inline-end: 15px;
	}

	.white-shdw-button:hover {
    box-shadow: none;
    background: transparent;
	}
	ul li a:hover {
		color: rgba(61, 12, 48, 0.82) !important;
	}
	.changeNavbarColor .theme-menu-one .navbar-nav .nav-item .nav-link {
		color:#fff !important;
	}
	.changeNavbarColor .theme-main-menu.fixed {
		background: #483c51 !important;
	}
	.changeNavbarColor .navbar .navbar-toggler{
		color: white;
	}
	.new-feature {
		display: inline;
		padding: 5px 7px;
		border-radius: 6px;
		background: rgb(190 177 128);
		color: #fff;
		margin-right: 3px;
		letter-spacing: 1px;
		font-weight: 600;
		font-size: 12px;
		animation: fillInOut 1.2s ease-in-out infinite;
	}
	@media screen and (max-width: 991px) {
		.changeNavbarColor .navbar-collapse {
			background-color: #483c51!important;
		}
	}
	@keyframes fillInOut {
		0% {
			background: rgb(190 177 128);
			color: #fff;
		}
		100% {
			background: #fff;
			color: rgb(190 177 128);
		}
	}
</style>
