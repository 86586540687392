<template>
  <div class="row">
    <div class="col-lg-4 p-r ">
      <h6 :class="{ 'reduceFontSize' : reduceFontSize }" class="pb-5 inputLabel secondMobileFontSize labelColor"
          style="padding-right:14%; padding-top:10px">
        {{name}} </h6>
    </div>
    <div class="col-lg-8 mp paddingTop">
      <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
        <input :class="{ 'customWidth': customWidth }" :style="[errors.length > 0 ? {'border-color': '#DE3557'} : {}]"
               :type="type"
               class="form-control"
               v-model="computedValue">
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  export default {
    name: "formsInput",
    props: {
      name: {type: String, required: false},
      value: {type: [Number, String]},
      type: {type: String, required: true},
      placeholder: {type: String, default: ''},
      validationRules: {type: [String, Object], required: true},
      customWidth: {type: Boolean, default: false},
      reduceFontSize: {type: Boolean, default: false}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },
  }
</script>

<style>
  @import '../../../../public/assets/css/formsStyle.css';
</style>
