<template>
  <SearchButton @click="openModal" />
</template>

<script>
import SearchButton from './search-button.vue';

export default {
  name: 'GlobalSearch',
  components: {
    SearchButton,
  },
  methods: {
    openModal() {
      $(`#global-search-modal`).modal('show');
      this.$emit('open');
    },
    closeModal() {
      $(`#global-search-modal`).modal('hide');
    },
  },
};
</script>
