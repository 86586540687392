import EcommerceHome from './ecommerce-views/ecommerceHome.vue'
import MasterLayout from '../../layouts/MasterLayout'

const ecommerceRoutes = [
    {
        path: '/ecommerce',
        name: 'EcommerceHome',
        component: EcommerceHome,
        meta: {layout: MasterLayout}
    }

]

export default ecommerceRoutes
