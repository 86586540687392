<template>
  <span>
    <template v-if="searchTerm.length === 0">
      {{ content }}
    </template>
    <template v-else>
      <span
        v-for="(part, index) in highlightedParts"
        :key="index"
        :class="{ highlight: isHighlighted(part) }"
      >{{ part }}</span>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },
  computed: {
    regex() {
      return new RegExp(`(${this.searchTerm})`, 'ig');
    },
    highlightedParts() {
      if (this.searchTerm.length === 0) return [this.content];
      return this.content.split(this.regex).filter(Boolean);
    },
  },
  methods: {
    isHighlighted(part) {
      return part.toLowerCase() === this.searchTerm.toLowerCase();
    },
  },
};
</script>

<style scoped>
.highlight {
  background-color: #ffff0b;
}
</style>
