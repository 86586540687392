<template>
  <div class="main-page-wrapper">
    <the-navbar :isWhite="isWhite" v-if="this.$showNavbar"/>
    <search-box />
    <slot/>
    <warranty-fixed-button v-show="!isHidden"/>
    <the-footer/>
  </div>
</template>

<script>
  import TheNavbar from '@/app/shared/components/TheNavbar.vue'
  import TheFooter from '@/app/shared/components/TheFooter.vue'
  import WarrantyFixedButton from '@/app/shared/components/WarrantyFixedButton.vue'
  import SearchBox from '@/app/website/website-components/global-search/components/search-box.vue';

  export default {
    name: 'MasterLayout',
    components: {
      TheNavbar,
      TheFooter,
      WarrantyFixedButton,
      SearchBox
    },
    data() {
      return {
        isHidden: false,
        isWhite: false
      }
    },
    methods: {
      handleScroll() {
        const routesName = ['TrainingForm', 'VolunteerForm', 'JobApplicationForm', 'TeachingForm', 'Faqs', 'ContactUs', 'Full-Details', 'EcommerceHome', 'DonationForm'];
        if (routesName.includes(this.$route.name)) {
          if (window.scrollY > 100)
            this.isHidden = true;
          else if (window.scrollY <= 100)
            this.isHidden = false;
        }
      }
    },
    created() {
      this.$watch(
        () => this.$route.name,
        () => {
          this.isWhite = this.$route.name === 'EcommerceHome';
          this.isHidden = this.$route.name === 'orphanSponsorshipSteps';
        }, {immediate: true}
      );
      window.addEventListener('scroll', this.handleScroll);
    }
  }
</script>
