var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agn-counter-section"
  }, [_c('img', {
    staticClass: "shape-one",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-55.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-two",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-59.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-61.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "counter-wrapper"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.analytics, function (item, key) {
    return _c('div', {
      staticClass: "col-sm-4"
    }, [_c('div', {
      staticClass: "single-counter-box"
    }, [_c('img', {
      staticClass: "m-auto",
      attrs: {
        "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Ellipse-17.svg'
      }
    }), _c('h3', {
      staticClass: "centered text-white"
    }, [_c('countTo', {
      staticClass: "timer",
      attrs: {
        "startVal": _vm.startVal,
        "endVal": Number(item.value),
        "duration": 3000
      }
    })], 1), _c('p', [_vm._v(_vm._s(item.readable_key))])])]);
  }), 0)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-title-one text-center"
  }, [_c('h2', {
    staticClass: "main-title"
  }, [_vm._v("حقائق وأرقام عن التميّز")])]);
}]

export { render, staticRenderFns }