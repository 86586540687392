var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "global-search-modal",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered modal-md"
  }, [_c('div', {
    staticClass: "modal-content",
    staticStyle: {
      "border-radius": "16px",
      "height": "560px"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('SearchInput', {
    on: {
      "clear": _vm.clearSearchHandler
    },
    model: {
      value: _vm.searchTerm,
      callback: function ($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _vm.searchTerm.length > 0 ? _c('div', {
    staticClass: "modal-header",
    staticStyle: {
      "padding-top": "0px",
      "padding-bottom": "0px"
    }
  }, [_c('div', {
    staticClass: "entity-tabs-container"
  }, [_c('EntityTabItem', {
    attrs: {
      "title": "عام",
      "count": _vm.entitiesCount.blog,
      "disabled": _vm.entitiesCount.blog === 0,
      "is-active": _vm.activeEntityTab === _vm.SearchAutocompleteType.BLOG
    },
    on: {
      "click": function ($event) {
        _vm.activeEntityTab = _vm.SearchAutocompleteType.BLOG;
      }
    }
  }), _c('EntityTabItem', {
    attrs: {
      "title": "زادي",
      "count": _vm.entitiesCount.zadi,
      "disabled": _vm.entitiesCount.zadi === 0,
      "is-active": _vm.activeEntityTab === _vm.SearchAutocompleteType.ZADI
    },
    on: {
      "click": function ($event) {
        _vm.activeEntityTab = _vm.SearchAutocompleteType.ZADI;
      }
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "modal-body",
    staticStyle: {
      "overflow": "auto",
      "height": "497px"
    }
  }, [_vm.searchTerm.length > 0 ? _c('EntityList', {
    attrs: {
      "searchTerm": _vm.searchTerm,
      "type": _vm.activeEntityTab
    }
  }) : _c('RecentSearchResults', {
    on: {
      "select": function ($event) {
        _vm.searchTerm = $event;
      }
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }