var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card cardWidth paddingBottom",
    staticStyle: {
      "border-radius": "15px"
    },
    style: [_vm.isPaddingTop ? {
      'margin-top': '30px'
    } : {}]
  }, [_c('div', {
    staticClass: "row justify-content-between mt-4"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h5', {
    staticClass: "firstMobileFontSize pl-4"
  }, [_c('b', [_vm._v(_vm._s(_vm.title))])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-end mr-3",
    on: {
      "click": function ($event) {
        return _vm.addObject();
      }
    }
  }, [_c('div', {
    staticClass: "pr-2"
  }, [_c('img', {
    staticStyle: {
      "color": "#43A047",
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-forms/add_icon.png',
      "alt": ""
    }
  })]), _c('div', [_c('h6', {
    staticClass: "secondMobileFontSize",
    staticStyle: {
      "color": "#43A047",
      "cursor": "pointer"
    }
  }, [_vm._v(" " + _vm._s(_vm.addTitle) + " ")])])])])]), _vm._l(_vm.dataArray, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "card mp",
      staticStyle: {
        "border-radius": "9px",
        "margin": "30px"
      },
      attrs: {
        "slot": "loop"
      },
      slot: "loop"
    }, [_c('div', {
      staticClass: "container"
    }, [_c('div', {
      staticClass: "row justify-content-end"
    }, [index !== 0 ? _c('img', {
      staticClass: "deleteIcon mt-3 mr-4 ",
      staticStyle: {
        "height": "25px",
        "cursor": "pointer"
      },
      attrs: {
        "src": _vm.$websiteEnvironment.assetsDir + '/website-forms/delete_icon.png',
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeObject(index);
        }
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "pt-5 paddingTop",
      style: [index !== 0 ? {
        'padding-top': '0px !important'
      } : {}]
    }, [_vm._t("inputs", null, {
      "index": index,
      "item": item
    })], 2)])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }