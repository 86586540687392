<template>
  <div
    @click="clickHandler"
    class="entity-tab"
    :style="{
      borderBottom: isActive && !disabled ? '2px solid #745A6E' : 'unset',
      cursor: !disabled ? 'pointer' : 'not-allowed',
    }"
  >
    <p class="entity-tab-text" :style="{ color: isActive && !disabled ? '#745A6E' : '#667085' }">{{ title }}</p>
    <p class="entity-tab-count">{{ count }}</p>
  </div>
</template>

<script>
export default {
  name: 'EntityTabItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isActive: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    clickHandler() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  },
}
</script>

<style scoped>
.entity-tab {
  gap: 5px;
  height: 50px;
  padding: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.entity-tab-text {
  font-size: 14px;
  font-weight: 600;
}
.entity-tab-count {
  padding: 4px;
  height: max-content;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  color: #745A6E;
  background: #FFE8FA;
}
</style>
