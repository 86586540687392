var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "our-blog blog-filer mb-150"
  }, [_c('div', {
    attrs: {
      "id": "theme-banner-four"
    }
  }, [_c('img', {
    staticClass: "screen-one wow fadeInRight animated",
    staticStyle: {
      "margin-top": "100px",
      "width": "1000px"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/news/' + 'Group-1574.svg',
      "alt": "",
      "data-wow-duration": "2s"
    }
  }), _c('img', {
    staticClass: "shape-one",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-two",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "round-shape-one"
  }), _c('div', {
    staticClass: "round-shape-two"
  }), _c('div', {
    staticClass: "round-shape-three"
  }), _c('div', {
    staticClass: "round-shape-four"
  }), _vm._m(0)]), _c('div', {
    staticClass: "container text-center wow fadeInUp animated",
    staticStyle: {
      "width": "50%",
      "margin": "auto"
    }
  }, [_c('div', {
    staticClass: "row justify-content-md-center"
  }, _vm._l(_vm.filtersArray, function (item) {
    return _c('div', {
      staticClass: "col-md-4 col-sm-6 col-xs-6"
    }, [_c('router-link', {
      staticClass: "text-center mb-2",
      staticStyle: {
        "color": "#4B364E"
      },
      attrs: {
        "to": {
          name: ("News" + (item.type))
        },
        "active-class": "customBorderBottom"
      }
    }, [_vm._v(_vm._s(item.text) + " ")])], 1);
  }), 0), _c('hr', {
    staticStyle: {
      "border-top": "1px solid #4B364E"
    }
  })]), _c('transition', {
    staticClass: "row masnory-blog-wrapper",
    attrs: {
      "tag": "div",
      "leave-active-class": "animated fadeOutLeft"
    }
  }, [_c('router-view')], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper text-center"
  }, [_c('h1', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" أخبار"), _c('b', [_vm._v(" التميّز")])]), _c('h3', {
    staticClass: "wow fadeInUp animated mt-150",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_c('h3', [_vm._v(" أخبارنا و آخر الفعاليات والمستجدات ")])])])]);
}]

export { render, staticRenderFns }