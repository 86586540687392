<template>
  <div>
    <div class="our-blog blog-filer mb-150">
      <div id="theme-banner-four">
        <img :src="$websiteEnvironment.assetsDir + '/website-svgs/news/' + 'Group-1574.svg'"
             style="margin-top: 100px; width: 1000px"
             alt="" class="screen-one wow fadeInRight animated" data-wow-duration="2s">
        <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
        <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
        <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt=""
             class="shape-three">
        <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt=""
             class="shape-four">
        <div class="round-shape-one"></div>
        <div class="round-shape-two"></div>
        <div class="round-shape-three"></div>
        <div class="round-shape-four"></div>
        <div class="container">
          <div class="main-wrapper text-center">
            <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
              أخبار<b> التميّز</b>
            </h1>
            <h3 class="wow fadeInUp animated mt-150" data-wow-delay="0.4s">
              <h3> أخبارنا و آخر الفعاليات والمستجدات </h3>
            </h3>
          </div>
        </div>
      </div>

      <!-- Filters -->
      <div class="container text-center wow fadeInUp animated" style="width: 50%; margin:auto">
        <div class="row justify-content-md-center">
          <div class="col-md-4 col-sm-6 col-xs-6" v-for="item in filtersArray">
            <router-link style="color:#4B364E;"
                         class="text-center mb-2" :to="{name: `News${item.type}`}"
                         active-class="customBorderBottom">{{item.text}}
            </router-link>
          </div>
        </div>
        <hr style="border-top: 1px solid #4B364E;">
      </div>
      <transition tag="div" class="row masnory-blog-wrapper"
                  leave-active-class="animated fadeOutLeft"
      >
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
  import {VueMasonryPlugin} from 'vue-masonry';
  import Vue from 'vue'
  import isotope from 'vueisotope'

  Vue.use(VueMasonryPlugin)
  export default {
    name: 'News',
    metaInfo: {
      title: 'الأخبار',
    },
    components: {
      isotope,
    },
    data() {
      return {
        filtersArray: [
          {type: 'News', text: 'الأخبار'},
          {type: 'Event', text: 'الفعاليات'},
          // TODO: hidden
          // {type: 'UpcomingEvent', text: 'الأنشطة القادمة'}
        ],
        tags: [],
      }
    },
    mounted() {
      this.$router.push({name: "NewsNews"})
    }
  }
</script>

<style scoped>

  #theme-banner-four .screen-one {
    position: absolute;
    right: -14%;
    top: 12%;
    z-index: 1;
    width: 45% !important;
  }

  a.customBorderBottom {
    border-bottom: 2px solid #483c51;;
  }

</style>
