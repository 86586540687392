const OS = {
  MAC: 'MAC',
};

async function _isMacOS() {
  try {
    if (navigator.userAgentData) {
      // Use userAgentData if available
      const ua = await navigator.userAgentData.getHighEntropyValues(['platform']);
      return ua.platform.toUpperCase().indexOf(OS.MAC) >= 0;
    } else {
      // Fallback for older browsers
      return navigator.userAgent.toUpperCase().indexOf(OS.MAC) >= 0;
    }
  } catch (err) {
    return false;
  }
}

export const isMacOSMixin = {
  data() {
    return {
      isMacOS: false,
    };
  },
  async created() {
    this.isMacOS = await _isMacOS();
  },
};

export const eventListenerMixin = {
  data() {
    return {
      _event: undefined,
      _handler: undefined,
    }
  },
  methods: {
    addEventListener(event, handler) {
      this._event = event;
      this._handler = handler;
      window.addEventListener(this._event, this._handler);
    },
    removeEventListener() {
      window.removeEventListener(this._event, this._handler);
    },
  },
  mounted() {
    // Ensure addEventListener is called after defining event and handler
    if (this._event && this._handler) {
      window.addEventListener(this._event, this._handler);
    }
  },
  beforeDestroy() {
    // Ensure removeEventListener is called to avoid memory leaks
    if (this._event && this._handler) {
      window.removeEventListener(this._event, this._handler);
    }
  },
};
