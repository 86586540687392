import { httpClient } from "../../../../shared/services";
import { BlogType, SearchAutocompleteType, globalSearchLimit } from '../constants/entities.js';

const { VUE_APP_ZADI_API_BASE_URL } = process.env;
const zadiApiBaseUrl = VUE_APP_ZADI_API_BASE_URL;

/**
  * @param {Number} limit // default = 10
  * @return {Promise<{
  *  count: Number;
  *  data: Array<{
  *    phrase: String;
  *    tamayozId: Number;
  *    suggestion_type: String;
  *    id: String;
  *    type: String;
  *    title: String;
  *    short_desc: String | undefined;
  *  }>
  * }>}
*/
function getFakeResponse(limit = globalSearchLimit) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const response = {
        count: limit,
        data: []
      };

      for (let i = 1; i <= limit; i++) {
        response.data.push({
          phrase: `Sample phrase ${i}`,
          tamayozId: 1000 + i,
          id: `id_${i}`,
          title: `Title ${i}`,
          suggestion_type: `Type${i}`,
          type: i % 2 === 0 ? BlogType.FAQ : BlogType.SUCCESS_STORY,
          short_desc: i % 2 === 0 ? `Short description ${i}` : undefined
        });
      }

      resolve(response);
    }, 1500);
  });
}


export default {
  getFakeResponse,
  /**
   * @param {{ type: String, query: String, limit: Number, mock: Boolean }} param0
   * @return {Promise<{
   *  count: Number;
   *  data: Array<{
   *    phrase: String;
   *    tamayozId: Number;
   *    suggestion_type: String;
   *    id: String;
   *    type: String;
   *    title: String;
   *    short_desc: String | undefined;
   *  }>
   * }>}
   */
  async search({ type, query, mock = false, limit = 10 }) {
    if (mock) {
      return getFakeResponse(limit);
    }
    const url = `${zadiApiBaseUrl}v1/autocomplete?q=${query}&type=${type}&limit=${limit}`;
    const response = (await httpClient.get(url)).data;
    return response;
  },
  /**
   * @param {String} query
   * @returns {Promise<{ blogCounts: Number; zadiCounts: Number }>}
   */
  async getEntitiesCount(query) {
    try {
      const [blogResponse, zadiResponse] = await Promise.all([
        this.search({ query, type: SearchAutocompleteType.BLOG, limit: 0 }),
        this.search({ query, type: SearchAutocompleteType.ZADI, limit: 0 }),
      ])
      return {
        blogCounts: blogResponse.count || 0,
        zadiCounts: zadiResponse.count || 0,
      }
    } catch (err) {
      return {
        blogCounts: 0,
        zadiCounts: 0,
      }
    }
  }
}
