var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "screen-one wow fadeInLeft animated",
    staticStyle: {
      "margin-top": "100px",
      "width": "500px"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/zadi/zadi-finish.svg',
      "alt": "",
      "data-wow-duration": "2s"
    }
  })]), _vm._m(0)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('h4', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" إنتهت المسابقة "), _c('br')]), _c('h4', {
    staticClass: " wow fadeInUp animated mt-5",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" إنتظرونا في المسابقات القادمة "), _c('br'), _vm._v(" شكراً جزيلاُ لمشاركتكم .. ")]), _c('h4', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  })])]);
}]

export { render, staticRenderFns }