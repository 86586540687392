var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: " fixedButton show-in-mobile"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper text-left p-3"
  }, [_c('button', {
    staticClass: "more-button solid-button-one wow fadeInLeft  animated",
    staticStyle: {
      "visibility": "visible",
      "animation-delay": "1.5s",
      "animation-name": "fadeInLeft"
    },
    attrs: {
      "data-wow-delay": "1s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/orphanSponsorshipSteps');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "display": "inline-flex !important",
      "padding-left": "9px"
    },
    attrs: {
      "src": "assets/images/website/orphanSponsorshipSteps/logo.png"
    }
  }), _vm._v(" اكفل يتيم الآن ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }