<template>
  <div class="solid-inner-banner banner" style="padding-top: 70px; padding-bottom: 0px !important;">
    <Loader :isLoading="isLoading"></Loader>
    <ValidationObserver ref="validator">
      <form @submit.prevent="onSubmit" class="form form-style-two" data-toggle="validator" id="contact"
            novalidate="true">
        <div class="card-p paddingBottom" style="padding-right: 4%; padding-left: 4%">
          <div class="card cardWidth paddingBottom" style="border-radius: 15px; z-index: 1">
            <div class="container">
              <div class="row pt-5">
                <div class="col-lg-4">
                  <tm-input :reduceFontSize="true" name="الاسم الثلاثي" type="text"
                            v-model="feedback.author" validationRules="required"/>
                </div>
                <div class="col-lg-4" id="email">
                  <tm-input :reduceFontSize="true" name="البريد الالكتروني" type="text"
                            v-model="feedback.email" validationRules="email"/>
                </div>
                <div class="col-lg-4">
                  <tm-input :reduceFontSize="true" :validationRules="{ required: true , digits: 10, regex: /^09\d{8}$/}"
                            name="رقم الموبايل"
                            type="number"
                            v-model="feedback.mobile"/>
                </div>
              </div>
              <div class="textArea">
                <tm-text-area :inputCol="11" :labelCol="1" :textAreaPadding="true" id="textAreaField"
                              name="السؤال" v-model="feedback.message" validationRules="required"/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="row justify-content-center sendButton centered" style="padding-top: 70px; padding-bottom: 70px">
      <button class="more-button solid-button-one wow fadeInLeft  animated" data-wow-delay="0.5s" form="contact"
              style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;"
              type="submit">
        ارسال
      </button>
    </div>
  </div>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/RepositoryFactory";
  import utilities from "@/app/shared/utilities/index"
  import TmInput from '@/app/shared/components/formsInput.vue';
  import TmTextArea from '@/app/shared/components/formsTextArea.vue'
  import Loader from '@/app/shared/components/Loader'

  const feedbackService = RepositoryFactory.get('feedback');
  export default {
    name: "contactForm",
    components: {
      Loader,
      TmTextArea,
      TmInput
    },
    props: {
      type: {type: String, required: true}
    },
    data() {
      return {
        feedback: {
          type: '',
          author: '',
          email: '',
          mobile: '',
          message: ''
        },
        isLoading: false
      }
    },
    methods: {
      init() {
        this.$objectHelpers.initObjectMembers(this.feedback);
        this.feedback.type = this.type;
        this.$refs.validator.reset();
      },
      onSubmit() {
        this.$refs.validator.validate().then(success => {
          if (!success) {
            utilities.checkForm(this.$refs.validator);
          } else {
            this.feedback.type = this.type;
            this.isLoading = true;
            feedbackService.sendFeedback(this.feedback).then((response) => {
              this.isLoading = false;
              if (response.code === 201) {
                this.$swal({
                  icon: 'success',
                  title: 'تم الإرسال بنجاح',
                });
                this.init()
              } else {
                this.$swal(
                  'عذراً',
                  'حدث خطأ ما، يرجى المحاولة في وقت لاحق',
                  'error'
                )
              }
            })
          }
        });
      }
    }
  }
</script>

<style>
  @import '../../../../../public/assets/css/formsStyle.css';

  #contact .reduceFontSize {
    font-size: 16px !important;
  }

  #contact #email .p-r {
    padding-left: 0px !important;
  }

  #contact #email .inputLabel {
    padding-right: 0px !important;
  }

  .textArea {
    padding-bottom: 60px;
  }

  #textAreaField .form-control {
    width: 97% !important;
  }

  @media (max-width: 575px) {
    .textArea {
      padding-bottom: 20px;
    }

    #textAreaField .form-control {
      width: 100% !important;
    }

    .sendButton {
      padding-bottom: 50px !important;
      padding-top: 50px !important;
    }
  }
</style>
