var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ("form-group has-error has-danger " + (_vm.inputclass))
  }, [_c('label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLabel,
      expression: "isLabel"
    }],
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c('ValidationProvider', {
    attrs: {
      "name": _vm.name,
      "rules": {
        required: true,
        digits: 10,
        regex: /^09\d{8}$/
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.computedValue,
            expression: "computedValue"
          }],
          attrs: {
            "placeholder": _vm.name,
            "data-error": "يجب أن يبدأ رقم الموبايل ب 09",
            "required": "required",
            "type": "number"
          },
          domProps: {
            "value": _vm.computedValue
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) { return; }
              _vm.computedValue = $event.target.value;
            }
          }
        }), _c('span', {
          staticClass: "text-danger mb"
        }, [_vm._v(_vm._s(errors[0]))]), _c('div', {
          staticClass: "help-block with-errors "
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }