var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-creative-content"
  }, [_c('div', {
    staticClass: "inner-wrapper"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$route.params.errorCode))]), _c('b', [_c('p', [_vm._v("عذراً.. "), _c('br'), _vm._v(" " + _vm._s(_vm.$route.params.errorMsg) + " ")])]), _c('a', {
    staticClass: "back-button solid-button-one",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("العودة للرئيسية")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }