var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticStyle: {
      "z-index": "1!important"
    },
    attrs: {
      "id": "theme-banner-five"
    }
  }, [_c('div', {
    staticClass: "mobile-screen-one wow fadeInLeft animated ",
    attrs: {
      "data-wow-duration": "1.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'ecommerce-app.png',
      "alt": ""
    }
  })]), _vm._m(0), _c('div', {
    staticClass: "main-wrapper"
  }, [_vm._m(1), _c('br'), _c('br'), _c('h1', {
    staticClass: "wow fadeInUp animated h4 text-white",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" أحد المشاريع الخيرية في مؤسسة التميز التنموية ")]), _c('h5', {
    staticClass: "wow fadeInUp animated text-white",
    staticStyle: {
      "padding-bottom": "35px"
    },
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" قم بتحميل التطبيق ")]), _c('ul', {
    staticClass: "button-group mobile-group"
  }, [_c('li', [_c('a', {
    staticClass: "google-button wow fadeInRight animated ",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "data-wow-delay": "1.5s",
      "href": "https://play.google.com/store/apps/details?id=com.tamayoz.mall",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'playstore.svg',
      "alt": ""
    }
  }), _c('span', [_vm._v("Get it on")]), _vm._v("Google Play")])]), _c('li', [_c('a', {
    staticClass: "google-button wow fadeInLeft animated ",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "data-wow-delay": "1.5s",
      "href": "https://storage.t-tamayoz.com/ecommerce/apk/t-mall-2.0.0.apk",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "40px !important"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'apk.png',
      "alt": ""
    }
  }), _c('span', [_vm._v("Download as")]), _vm._v("APK Format")])])])])]), _c('div', {
    staticClass: "our-service-app",
    staticStyle: {
      "padding-top": "70px",
      "padding-bottom": "100px"
    }
  }, [_vm._m(2), _vm._v(" "), _c('div', {
    staticClass: "main-content hide-pr"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.aboutAppList, function (item) {
    return _c('div', {
      staticClass: "col-lg-4 single-block p-4"
    }, [_c('div', {
      staticClass: "inner-wrapper"
    }, [_c('div', {
      staticClass: "illustration-box"
    }, [_c('img', {
      staticClass: "bg-shape",
      attrs: {
        "src": _vm.getImgUrl(item.imageUrl),
        "alt": item.imageUrl
      }
    })]), _c('h4', {
      staticClass: "title",
      staticStyle: {
        "margin-top": "-50px"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('p', [_vm._v(_vm._s(item.desc))])])]);
  }), 0)])]), _vm._v(" "), _vm._m(3), _c('div', {
    staticClass: "our-feature-app paddingTop",
    staticStyle: {
      "padding-bottom": "100px"
    },
    attrs: {
      "id": "feature"
    }
  }, [_c('div', {
    staticClass: "container"
  }, _vm._l(_vm.features, function (item) {
    return _c('div', {
      staticClass: "row single-feature-box"
    }, [_c('div', {
      class: ("col-lg-6 order-lg-" + (item.textSort))
    }, [_c('div', {
      staticClass: "text-wrapper"
    }, [_c('h3', {
      staticClass: "title mobile-font-size text-center"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "sub-heading"
    }, [_vm._v(_vm._s(item.subTitle))])])]), _vm._v(" "), _c('div', {
      class: ("col-lg-6 order-lg-" + (item.imagesSort))
    }, [_c('div', {
      staticClass: "feature-img-box"
    }, [_c('div', {
      staticClass: "screen-mockup js-tilt"
    }, [_c('img', {
      staticClass: "mockup wow fadeInUp animated",
      attrs: {
        "src": _vm.getImgUrl(item.mockupImg),
        "data-wow-duration": "1.2s",
        "alt": item.mockupImg
      }
    }), _c('img', {
      staticClass: "screen-one wow fadeInLeft animated",
      style: item.screenOneStyle,
      attrs: {
        "src": _vm.getImgUrl(item.screenOneImg),
        "data-wow-delay": "0.5s",
        "data-wow-duration": "1.2s",
        "alt": item.screenOneImg
      }
    }), _c('img', {
      staticClass: "screen-two wow fadeInRight animated",
      style: item.screenTwoStyle,
      attrs: {
        "src": _vm.getImgUrl(item.screenTwoImg),
        "data-wow-delay": "0.9s",
        "data-wow-duration": "1.2s",
        "alt": item.screenTwoImg
      }
    })])])])]);
  }), 0)]), _vm._m(4), _c('div', {
    staticClass: "feature-img-box"
  }, [_c('div', {
    staticClass: "screen-mockup js-tilt"
  }, [_c('img', {
    staticClass: "mockup centered hide wow fadeInUp animated",
    staticStyle: {
      "right": "30%",
      "max-height": "385px"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'checkAppBackground.svg',
      "alt": "",
      "data-wow-duration": "1.2s"
    }
  }), _c('carousel-3d', {
    attrs: {
      "autoplay": true,
      "height": 400
    }
  }, _vm._l(_vm.checkAppImages, function (imageUrl, i) {
    return _c('slide', {
      key: i,
      attrs: {
        "index": i
      }
    }, [_c('img', {
      staticStyle: {
        "max-width": "50%"
      },
      attrs: {
        "src": _vm.getImgUrl(imageUrl),
        "alt": imageUrl
      }
    })]);
  }), 1)], 1)]), _vm._m(5), _c('div', {
    staticClass: "our-feature-app",
    staticStyle: {
      "padding": "1px"
    }
  }, [_c('div', {
    staticClass: "row single-feature-box"
  }, [_c('div', {
    staticClass: "col-lg-7 order-lg-first p-5"
  }, [_c('div', {
    staticClass: "contact-form"
  }, [_c('ValidationObserver', {
    ref: "validator",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "form form-style-two",
          attrs: {
            "data-toggle": "validator",
            "id": "contact-form",
            "novalidate": "true"
          },
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('div', {
          staticClass: "controls"
        }, [_c('div', {
          staticClass: "row p-2"
        }, [_c('div', {
          staticClass: "col-lg-6"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "الاسم الثلاثي",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('div', {
                staticClass: "form-group p-2"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.feedback.author,
                  expression: "feedback.author"
                }],
                attrs: {
                  "name": "firstName",
                  "placeholder": "الاسم",
                  "type": "text"
                },
                domProps: {
                  "value": _vm.feedback.author
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.$set(_vm.feedback, "author", $event.target.value);
                  }
                }
              })]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-lg-6"
        }, [_c('mobile-input', {
          attrs: {
            "inputclass": "p-2",
            "name": "رقم الهاتف المحمول"
          },
          model: {
            value: _vm.feedback.mobile,
            callback: function ($$v) {
              _vm.$set(_vm.feedback, "mobile", $$v);
            },
            expression: "feedback.mobile"
          }
        })], 1)]), _c('div', {
          staticClass: "row p-2"
        }, [_c('div', {
          staticClass: "col-lg-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "البريد الإلكتروني",
            "rules": "email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('div', {
                staticClass: "form-group p-2"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.feedback.email,
                  expression: "feedback.email"
                }],
                attrs: {
                  "name": "email",
                  "placeholder": "البريد الإلكتروني",
                  "type": "email"
                },
                domProps: {
                  "value": _vm.feedback.email
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.$set(_vm.feedback, "email", $event.target.value);
                  }
                }
              })]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "row p-2"
        }, [_c('div', {
          staticClass: "col-lg-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "الرسالة",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('div', {
                staticClass: "form-group p-2"
              }, [_c('textarea', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.feedback.message,
                  expression: "feedback.message"
                }],
                staticClass: "form_message",
                attrs: {
                  "id": "form_message",
                  "name": "message",
                  "placeholder": "الرسالة",
                  "type": "text"
                },
                domProps: {
                  "value": _vm.feedback.message
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.$set(_vm.feedback, "message", $event.target.value);
                  }
                }
              })]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)])])])];
      }
    }])
  })], 1), _vm._v(" "), _vm._m(6)]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-5  order-lg-last hide"
  }, [_c('div', {
    staticClass: "feature-img-box"
  }, [_c('div', {
    staticClass: "screen-mockup js-tilt"
  }, [_c('img', {
    staticClass: "mockup wow fadeInUp animated",
    staticStyle: {
      "max-width": "80%"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'toldUsBackground.svg',
      "alt": "",
      "data-wow-duration": "1.2s"
    }
  }), _c('img', {
    staticClass: "screen-two wow fadeInLeft animated",
    staticStyle: {
      "max-width": "50%",
      "top": "-3%",
      "left": "10%"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'iphone-x-mockup (14).png',
      "alt": "",
      "data-wow-delay": "0.5s",
      "data-wow-duration": "1.2s"
    }
  }), _c('img', {
    staticClass: "screen-one wow fadeInRight animated",
    staticStyle: {
      "max-width": "60%",
      "right": "15%"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/ecommerce/' + 'iphone-x-mockup (20).png',
      "alt": "",
      "data-wow-delay": "0.9s",
      "data-wow-duration": "1.2s"
    }
  })])])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-shape-holder"
  }, [_c('span', {
    staticClass: "big-round-one wow fadeInLeft animated",
    attrs: {
      "data-wow-duration": "3s"
    }
  }), _c('span', {
    staticClass: "big-round-two wow fadeInLeft animated",
    attrs: {
      "data-wow-duration": "3s"
    }
  }), _c('span', {
    staticClass: "big-round-three wow fadeInLeft animated",
    attrs: {
      "data-wow-duration": "3s"
    }
  }), _c('span', {
    staticClass: "shape-one"
  }), _c('span', {
    staticClass: "shape-two"
  }), _c('span', {
    staticClass: "shape-four"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "wow fadeInUp animated h3 text-white",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" تطبيق "), _c('b', [_vm._v("متجر التميز الإلكتروني ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-title-five text-center"
  }, [_c('h2', {
    staticClass: "main-title mobile-font-size"
  }, [_c('b', [_vm._v("عن التطبيق")])]), _c('div', {
    staticClass: "sub-title p-4",
    staticStyle: {
      "margin-top": "36px",
      "font-size": "20px"
    }
  }, [_vm._v("متجر التميز الإلكتروني هو أحد المشاريع الخيرية في مؤسسة التميز التنموية التي تهدف من خلاله لإيجاد"), _c('br'), _vm._v(" مصادر دعم مستدامة للأيتام من خلال العائد المادي الناتج عن هذا التطبيق والذي يعود إلى كفالة ورعاية الأيتام ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-title-five text-center"
  }, [_c('h2', {
    staticClass: "main-title mobile-font-size"
  }, [_c('b', [_vm._v("ميزات التطبيق")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-title-five text-center",
    staticStyle: {
      "padding-bottom": "100px"
    }
  }, [_c('h2', {
    staticClass: "main-title p-1 mobile-font-size"
  }, [_c('b', [_vm._v("قم بتصفح التطبيق")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "theme-title-five text-center"
  }, [_c('h2', {
    staticClass: "main-title p-4 mobile-font-size"
  }, [_c('b', [_vm._v("أخبرنا عن آرائك")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper text-left p-3"
  }, [_c('button', {
    staticClass: "more-button solid-button-one wow fadeInLeft  animated",
    staticStyle: {
      "visibility": "visible",
      "animation-delay": "1.5s",
      "animation-name": "fadeInLeft"
    },
    attrs: {
      "data-wow-delay": "1s",
      "form": "contact-form",
      "type": "submit"
    }
  }, [_vm._v(" إرسال ")])])]);
}]

export { render, staticRenderFns }