var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    attrs: {
      "id": "theme-banner-four"
    }
  }, [_c('img', {
    staticClass: "screen-one wow fadeInLeft animated",
    staticStyle: {
      "margin-top": "100px",
      "width": "1000px"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/contact-us/' + 'Group-1576.svg',
      "alt": "",
      "data-wow-duration": "2s"
    }
  }), _c('img', {
    staticClass: "shape-one",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-two",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "round-shape-one"
  }), _c('div', {
    staticClass: "round-shape-two"
  }), _c('div', {
    staticClass: "round-shape-three"
  }), _c('div', {
    staticClass: "round-shape-four"
  }), _vm._m(0)]), _c('contact-form', {
    attrs: {
      "type": "Message"
    }
  }), _vm._m(1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('h1', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" تواصل معنا ")]), _c('h4', {
    staticClass: "wow fadeInUp animated mt-5",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" أخبرنا بما يجول في بالك ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-us-section contact-minimal container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "allowfullscreen": "",
      "aria-hidden": "false",
      "frameborder": "0",
      "height": "400em",
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1663.7412049451823!2d36.291312042986625!3d33.48882398715922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1518e0e9a8d34ea9%3A0x73637e4d79db5587!2z2KfZhNiq2YXZitiyINmB2Yog2YPZgdin2YTYqSDYp9mE2YrYqtmK2YU!5e0!3m2!1sen!2snl!4v1605352300764!5m2!1sen!2snl",
      "tabindex": "0",
      "width": "100%"
    }
  })])])]);
}]

export { render, staticRenderFns }