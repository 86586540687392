var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      changeNavbarColor: _vm.isWhite
    }
  }, [_c('div', {
    staticClass: "theme-main-menu theme-menu-one"
  }, [_vm._m(0), _c('nav', {
    staticClass: "navbar navbar-expand-lg",
    staticStyle: {
      "margin-right": "-80px"
    },
    attrs: {
      "id": "mega-menu-holder"
    }
  }, [_c('div', {
    staticClass: "container nav-container"
  }, [_vm._m(1), _c('div', {
    staticClass: "collapse navbar-collapse",
    attrs: {
      "id": "navbarSupportedContent"
    }
  }, [_c('ul', {
    staticClass: "navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'Home'
      }
    }
  }, [_vm._v("الرئيسية")])], 1), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'Kareem'
      }
    }
  }, [_vm._v(" تبرع كريم ")])], 1), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'ZadiHome'
      }
    }
  }, [_vm._v(" زادي "), _c('p', {
    staticClass: "new-feature"
  }, [_vm._v("جديد")])])], 1), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'SuccessStories'
      }
    }
  }, [_vm._v("قصص مؤثرة")])], 1), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'Faqs'
      }
    }
  }, [_vm._v("سؤال و جواب")])], 1), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'News'
      }
    }
  }, [_vm._v("الأخبار")])], 1), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "href": "#",
      "data-toggle": "dropdown"
    }
  }, [_vm._v("النماذج")]), _c('ul', {
    staticClass: "dropdown-menu"
  }, [_c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'JobApplicationForm'
      }
    }
  }, [_vm._v("توظيف")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'TeachingForm'
      }
    }
  }, [_vm._v("تدريس")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'TrainingForm'
      }
    }
  }, [_vm._v("تدريب")]), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": {
        name: 'VolunteerForm'
      }
    }
  }, [_vm._v("تطوع")])], 1)]), _c('li', {
    staticClass: "nav-item dropdown position-relative"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        name: 'EcommerceHome'
      }
    }
  }, [_vm._v("متجر التميز")])], 1)])])])]), _vm._v(" "), _c('div', {
    staticClass: "header-right-widget"
  }, [_c('ul', [_c('li', [_c('GlobalSearch')], 1), _c('li', [_c('router-link', {
    staticClass: "contact-us white-shdw-button",
    attrs: {
      "to": {
        name: 'orphanSponsorshipSteps'
      }
    }
  }, [_vm._v(" خطوات الكفالة "), _c('i', {
    staticClass: "icon flaticon-back"
  })])], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "logo my-1"
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "assets/images/logo/logo-svg.svg",
      "alt": "tamayoz-logo",
      "width": "80px",
      "height": "60px"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "navbar-toggler navbar-toggler-right",
    attrs: {
      "type": "button",
      "data-toggle": "collapse",
      "data-target": "#navbarSupportedContent",
      "aria-controls": "navbarSupportedContent",
      "aria-expanded": "false",
      "aria-label": "Toggle navigation"
    }
  }, [_c('i', {
    staticClass: "flaticon-setup"
  })]);
}]

export { render, staticRenderFns }