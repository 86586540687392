<template>
  <div class="search-empty-state">
    <img src="/assets/images/website/global-search/empty-search-icon.svg" />
  </div>
</template>

<script>
export default {
  name: 'SearchEmptyState',
}
</script>

<style scoped>
.search-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
