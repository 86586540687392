<template>
    <div class="row mt-5">
        <div class="col-md-3">
            <h1>
                {{ seconds }}
            </h1>
            <h5>Seconds</h5>
        </div>
        <div class="col-md-3">
            <h1>
                {{ minutes }}
            </h1>
            <h5>mins</h5>
        </div>
        <div class="col-md-3">
            <h1>
                {{ hours }}
            </h1>
            <h5>hours</h5>
        </div>
        <div class="col-md-3">
            <h1>
                {{ days }}
            </h1>
            <h5>days</h5>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CountDown',
        mounted() {
            window.setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / 1000);
            }, 1000);
        },
        props: {
            date: {
                type: String
            }
        },
        data() {
            return {
                now: Math.trunc((new Date()).getTime() / 1000),
            }
        },
        computed: {
            dateInMilliseconds() {
                return Math.trunc(Date.parse(this.date) / 1000)
            },
            seconds() {
                return (this.dateInMilliseconds - this.now) % 60;
            },
            minutes() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
            },
            hours() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
            },
            days() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
            }
        }
    }
</script>

<style scoped>

</style>
