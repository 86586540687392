<template>
  <div>
    <!--  Bannar  -->
    <Loader :isLoading="isLoading"></Loader>
    <div id="theme-banner-five" style="z-index: 1!important;">
      <div class="mobile-screen-one wow fadeInLeft animated " data-wow-duration="1.5s"><img
        :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'ecommerce-app.png'" alt=""></div>
      <div class="bg-shape-holder">
        <span class="big-round-one wow fadeInLeft animated" data-wow-duration="3s"></span>
        <span class="big-round-two wow fadeInLeft animated" data-wow-duration="3s"></span>
        <span class="big-round-three wow fadeInLeft animated" data-wow-duration="3s"></span>
        <span class="shape-one"></span>
        <span class="shape-two"></span>
        <span class="shape-four"></span>
      </div>
      <div class="main-wrapper">
        <h1 class="wow fadeInUp animated h3 text-white" data-wow-delay="0.4s">
          تطبيق
          <b>متجر التميز الإلكتروني </b>
        </h1>
        <br>
        <br>
        <h1 class="wow fadeInUp animated h4 text-white" data-wow-delay="0.4s">
          أحد المشاريع الخيرية في مؤسسة التميز التنموية
        </h1>
        <h5 class="wow fadeInUp animated text-white" data-wow-delay="0.9s" style="padding-bottom: 35px">
          قم بتحميل التطبيق
        </h5>
        <ul class="button-group mobile-group">
          <li><a class="google-button wow fadeInRight animated " data-wow-delay="1.5s"
                 href="https://play.google.com/store/apps/details?id=com.tamayoz.mall" style="max-width: 100%"
                 target="_blank"><img
            :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'playstore.svg'" alt=""><span>Get it on</span>Google
            Play</a></li>
          <li><a class="google-button wow fadeInLeft animated " data-wow-delay="1.5s"
                 href="https://storage.t-tamayoz.com/ecommerce/apk/t-mall-2.0.0.apk"
                 style="max-width: 100%" target="_blank"><img
            :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'apk.png'" alt=""
            style="width:40px !important;"><span>Download as</span>APK Format</a></li>
        </ul>
      </div>
    </div>
    <!--Our Service-->
    <div class="our-service-app" style="padding-top: 70px; padding-bottom: 100px">
      <div class="theme-title-five text-center">
        <h2 class="main-title mobile-font-size"><b>عن التطبيق</b></h2>
        <div class="sub-title p-4" style="margin-top: 36px; font-size: 20px">متجر التميز الإلكتروني هو أحد المشاريع
          الخيرية في مؤسسة التميز التنموية التي تهدف من خلاله لإيجاد<br> مصادر دعم مستدامة للأيتام من خلال العائد
          المادي الناتج عن هذا التطبيق والذي يعود إلى كفالة ورعاية الأيتام
        </div>
      </div> <!-- /.theme-title-one -->

      <div class="main-content hide-pr">
        <div class="row">
          <div class="col-lg-4 single-block p-4" v-for="item in aboutAppList">
            <div class="inner-wrapper">
              <div class="illustration-box">
                <img :src="getImgUrl(item.imageUrl)" class="bg-shape" v-bind:alt="item.imageUrl">
              </div>
              <h4 class="title" style="margin-top: -50px">{{item.title}}</h4>
              <p>{{item.desc}}</p>
            </div> <!-- /.inner-wrapper -->
          </div> <!-- /.single-block -->
        </div> <!-- /.row -->
      </div> <!-- /.main-content -->
    </div> <!-- /.our-service-app -->
    <!--Our Feature-->
    <div class="theme-title-five text-center">
      <h2 class="main-title mobile-font-size"><b>ميزات التطبيق</b></h2>
    </div>
    <div class="our-feature-app paddingTop" id="feature" style="padding-bottom: 100px">
      <div class="container">
        <div class="row single-feature-box" v-for="item in features">
          <div :class="`col-lg-6 order-lg-${item.textSort}`">
            <div class="text-wrapper">
              <h3 class="title mobile-font-size text-center">{{item.title}}</h3>
              <div class="sub-heading">{{item.subTitle}}</div>
            </div> <!-- /.text-wrapper -->
          </div> <!-- /.col- -->
          <div :class="`col-lg-6 order-lg-${item.imagesSort}`">
            <div class="feature-img-box">
              <div class="screen-mockup js-tilt">
                <img :src="getImgUrl(item.mockupImg)" class="mockup wow fadeInUp animated" data-wow-duration="1.2s"
                     v-bind:alt="item.mockupImg">
                <img :src="getImgUrl(item.screenOneImg)" :style="item.screenOneStyle"
                     class="screen-one wow fadeInLeft animated" data-wow-delay="0.5s" data-wow-duration="1.2s"
                     v-bind:alt="item.screenOneImg">
                <img :src="getImgUrl(item.screenTwoImg)" :style="item.screenTwoStyle"
                     class="screen-two wow fadeInRight animated" data-wow-delay="0.9s" data-wow-duration="1.2s"
                     v-bind:alt="item.screenTwoImg">
              </div> <!-- /.screen-mockup -->
            </div> <!-- /.feature-img-box -->
          </div> <!-- /.col- -->
        </div><!-- /.row -->
      </div>
    </div><!-- /.our-feature-app -->
    <!-- browse  the app -->
    <div class="theme-title-five text-center" style="padding-bottom: 100px;">
      <h2 class="main-title p-1 mobile-font-size"><b>قم بتصفح التطبيق</b></h2>
    </div>
    <div class="feature-img-box">
      <div class="screen-mockup js-tilt">
        <img :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'checkAppBackground.svg' " alt=""
             class="mockup centered hide wow fadeInUp animated" data-wow-duration="1.2s"
             style="right: 30%; max-height: 385px">
        <carousel-3d :autoplay="true" :height="400">
          <slide :index="i" :key="i" v-for="(imageUrl ,i) in checkAppImages">
            <img :src="getImgUrl(imageUrl)" style="max-width: 50%;" v-bind:alt="imageUrl">
          </slide>
        </carousel-3d>
      </div>
    </div>
    <!--told us your opinion-->
    <div class="theme-title-five text-center">
      <h2 class="main-title p-4 mobile-font-size"><b>أخبرنا عن آرائك</b></h2>
    </div>
    <div class="our-feature-app" style="padding: 1px">
      <div class="row single-feature-box">
        <div class="col-lg-7 order-lg-first p-5">
          <div class="contact-form">
            <ValidationObserver ref="validator" v-slot="{handleSubmit}">
              <form @submit.prevent="handleSubmit(onSubmit)" class="form form-style-two" data-toggle="validator"
                    id="contact-form" novalidate="true">
                <div class="controls">
                  <div class="row p-2">
                    <div class="col-lg-6">
                      <ValidationProvider name="الاسم الثلاثي" rules="required"
                                          v-slot="{ errors }">
                        <div class="form-group p-2">
                          <input name="firstName" placeholder="الاسم" type="text" v-model="feedback.author">
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <mobile-input inputclass="p-2" name="رقم الهاتف المحمول" v-model="feedback.mobile"/>
                    </div>
                  </div>
                  <div class="row p-2">
                    <div class="col-lg-12">
                      <ValidationProvider name="البريد الإلكتروني" rules="email" v-slot="{ errors }">
                        <div class="form-group p-2">
                          <input name="email" placeholder="البريد الإلكتروني" type="email" v-model="feedback.email">
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row p-2">
                    <div class="col-lg-12">
                      <ValidationProvider name="الرسالة" rules="required"
                                          v-slot="{ errors }">
                        <div class="form-group p-2">
                          <textarea class="form_message" id="form_message" name="message" placeholder="الرسالة"
                                    type="text" v-model="feedback.message"></textarea>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div> <!-- /.controls -->
              </form>
            </ValidationObserver>
          </div> <!-- /.contact-form -->
          <div class="container">
            <div class="main-wrapper text-left p-3">
              <button class="more-button solid-button-one wow fadeInLeft  animated" data-wow-delay="1s"
                      form="contact-form"
                      style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;"
                      type="submit">
                إرسال
              </button>
            </div>
          </div>
        </div> <!-- /.col- -->
        <div class="col-lg-5  order-lg-last hide">
          <div class="feature-img-box">
            <div class="screen-mockup js-tilt">
              <img :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'toldUsBackground.svg'" alt=""
                   class="mockup wow fadeInUp animated" data-wow-duration="1.2s" style="max-width: 80%">
              <img :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'iphone-x-mockup (14).png'"
                   alt="" class="screen-two wow fadeInLeft animated" data-wow-delay="0.5s"
                   data-wow-duration="1.2s" style="max-width: 50%; top:-3%; left:10%">
              <img :src="$websiteEnvironment.assetsDir + '/ecommerce/' + 'iphone-x-mockup (20).png'"
                   alt="" class="screen-one wow fadeInRight animated" data-wow-delay="0.9s"
                   data-wow-duration="1.2s" style="max-width: 60%; right:15%">
            </div> <!-- /.screen-mockup -->
          </div> <!-- /.feature-img-box -->
        </div> <!-- /.col- -->
      </div>
    </div>
  </div>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import Loader from '../../shared/components/Loader'
  import {RepositoryFactory} from "../../website/repositories/RepositoryFactory";
  import {Carousel3d, Slide} from 'vue-carousel-3d';
  import mobileInput from '@/app/shared/components/mobilePhoneNumberInput.vue';
  import utilities from "@/app/shared/utilities/index"


  const feedbackService = RepositoryFactory.get('feedback');


  export default {
    name: 'DonateHome',
    metaInfo: {
      title: 'متجر التميز الإلكتروني',
    },
    components: {
      DynamicMasterLayout,
      Loader,
      Carousel3d,
      Slide,
      mobileInput
    },
    data() {
      return {
        feedback: {
          type: 'Message',
          author: '',
          email: '',
          mobile: '',
          message: ''
        },
        isLoading: true,
        programsList: [],
        isLoading: false,
        swiperOption: {
          pagination: {
            el: '.swiper-pagination'
          },
          // ...
        },
        features: [
          {
            textSort: 'last',
            imagesSort: 'first',
            title: 'ميزة إرسال هدية إلى يتيم',
            subTitle: 'يمكن من خلال التطبيق شراء منتج معين من المنتجات ثم تغليفه و إرساله كهدية ليتيم ضمن جمعية التميز في كفالة اليتيم',
            screenOneStyle: 'top:4%; right:1px',
            screenTwoStyle: 'top:-30%',
            mockupImg: 'backgroundFeature1.svg',
            screenOneImg: 'twoscreenMobile.svg',
            screenTwoImg: 'iphone-x-mockup.svg'

          },
          {
            textSort: 'first',
            imagesSort: 'last',
            title: ' ميزة إرسال هدية إلى صديق',
            subTitle: 'يمكن من خلال التطبيق اختيار منتج معين أو عدة منتجات ثم إرسالهم كهدية لصديق ، عبر إدخال اسمه ورقمه وعنوانه ليتم التواصل معه من قبل موظف التوصيل وإيصال الهدية له',
            screenOneStyle: 'max-width: 50%; top:10%; right:55px',
            screenTwoStyle: 'max-width: 50%;top:-2%;',
            mockupImg: 'backgroundFeature-2.svg',
            screenOneImg: 'feature2-2.png',
            screenTwoImg: 'feature2-1.png'

          },
          {
            textSort: 'last',
            imagesSort: 'first',
            title: 'ميزة تغليف الطلبات',
            subTitle: 'يمكن تغليف الطلب عند شراءه لإضافة لمسة من الجمال عليه ، ويتم التغليف بعدة باقات مختلفة الأسعار',
            screenOneStyle: 'max-width: 50%; top:8%; right:50px; max-height: 80%',
            screenTwoStyle: 'max-width: 50%;top:-10%; right:170px',
            mockupImg: 'backgroundFeature-3.svg',
            screenOneImg: 'feature3-2.png',
            screenTwoImg: 'feature3-1.png'

          },
          {
            textSort: 'first',
            imagesSort: 'last',
            title: ' توصيل الطلبات من خلال موظف التوصيل',
            subTitle: 'عند شراء طلب معين من متجر التميز الإلكتروني سيتم ادخال عنوان المشتري ، ويقوم موظف التوصيل بالذهاب إلى هذا العنوان ، واستلام سعر الطلب و تسليم المنتج',
            screenOneStyle: 'max-width: 65%; right: 14px',
            screenTwoStyle: 'top:-10%; right:145px ; max-width: 70%',
            mockupImg: 'three.svg',
            screenOneImg: 'aljabi.png',
            screenTwoImg: 'map.png'

          }
        ],
        aboutAppList: [
          {
            title: 'سهل الاستخدام',
            imageUrl: 'aboutApp1.png',
            desc: 'يتألف التطبيق من عدة واجهات تحتوي على المنتجات ثم يتم اتباع آلية واضحة وبسيطة لإتمام عملية الشراء'
          },
          {
            title: 'منتجات متنوعة',
            imageUrl: 'aboutApp2.png',
            desc: 'يحتوي التطبيق على عدد متنوع من المنتجات بحيث يلبي مختلف الأذواق والمتطلبات'
          },
          {
            title: 'تحديد العناوين بدقة',
            imageUrl: 'aboutApp3.png',
            desc: 'يمكن تحديد العناوين الشخصية و مواقع التوصيل باستخدام الخرائط الالكترونية'
          },
          {
            title: 'نظام إشعارات',
            imageUrl: 'aboutApp4.png',
            desc: 'نظام إشعارات متكامل يتم من خلاله متابعة حالة الطلبات ومراقبتها'
          },
          {
            title: 'استخدام آمن',
            imageUrl: 'aboutApp5.png',
            desc: 'يحتوي التطبيق على أفضل أساليب حماية المعلومات لضمان تجربة استخدام آمنة'
          },
          {
            title: 'محفظة المفضلة',
            imageUrl: 'aboutApp6.png',
            desc: 'يمكنك الاحتفاظ بكل العناصر أحببتها في مكان محدد للعودة إليها لاحقاً'
          }
        ],
        checkAppImages: ['checkApp6.png', 'checkApp5.png', 'checkApp7.png', 'checkApp2.png', 'checkApp4.png', 'checkApp1.png', 'checkApp3.png']
      }
    }
    ,
    mounted() {
      this.isLoading = false;
    },
    methods: {
      NavigateToDetailsPage(blogTitle) {
        this.$router.push({name: 'Full-Details', query: {title: blogTitle}});
      },
      getImgUrl(pic) {
        return require('../../../../public/assets/images/website/ecommerce/' + pic)
      },
      init() {
        this.$objectHelpers.initObjectMembers(this.feedback);
        this.feedback.type = 'Message';
        this.$refs.validator.reset();
      },
      onSubmit() {
        this.$refs.validator.validate().then(success => {
          if (!success) {
            utilities.checkForm(this.$refs.validator);
          } else {
            this.isLoading = true;
            feedbackService.sendFeedback(this.feedback).then((response) => {
              this.isLoading = false;
              if (response.code === 201) {
                this.$swal({
                  icon: 'success',
                  title: 'تم الإرسال بنجاح',
                });
                this.init()
              } else {
                this.$swal(
                  'عذراً',
                  'حدث خطأ ما، يرجى المحاولة في وقت لاحق',
                  'error'
                )
              }
            })
          }
        })
      }
    }
  }
</script>


<style scoped>
  .centered {
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  p {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    h4 {
      font-size: 20px !important;
    }

    .mt-5, .my-5 {
      margin-top: 1rem !important;
    }

    p {
      font-size: 13px;
    }
  }

  @media (max-width: 1199px) {
    #theme-banner-five .mobile-screen-one {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .hide {
      display: none;
    }

    #theme-banner-five {
      height: 720px !important;
    }

    .paddingTop {
      padding-top: 50px;
    }

    .mobile-group {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  .form-group {
    box-shadow: 0px 25px 50px 0px rgba(218, 218, 218, 0.5);
  }

  .carousel-3d-slide {
    border-radius: 0 !important;
    border-color: rgba(0, 0, 0, 0) !important;
    border-style: none !important;
    background-color: rgba(0, 0, 0, 0) !important;

  }

  .carousel-3d-slide {
    text-align: center !important;
  }

  .carousel-3d-slide img {
    border-radius: 5%;
    margin-right: auto;
    margin-left: auto;

  }

  .carousel-3d-slide.current {
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
  }

  .our-service-app .main-content .inner-wrapper {
    margin-top: 50px !important;
  }

  #theme-banner-five .main-wrapper .button-group li a img {
    right: 15px !important;
  }

  @media (max-width: 575px) {
    .mobile-font-size {
      font-size: 30px !important;
    }
  }
</style>
