import Vue from 'vue'
import App from './app/App.vue'
import router from './app/app-routes'
import store from './app/app-state'
import VueMeta from 'vue-meta';
import Toasted from 'vue-toasted';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import Validations from './plugins/veeValidate/veeValidate';
import './plugins'
import VueGeolocation from 'vue-browser-geolocation';
import VueSocialSharing from 'vue-social-sharing'
import vSelect from 'vue-select'
import InfiniteLoading from 'vue-infinite-loading';

require('vue2-animate/dist/vue2-animate.min.css');

Vue.use(require('vue-moment'));
Vue.use(VueSocialSharing);
Vue.use(VueGeolocation);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('infinite-loading', InfiniteLoading);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

Vue.component('v-select', vSelect);
Validations.initValidations();
Vue.config.productionTip = false;
let ToasterOptions = {
  position: 'bottom-center',
  keepOnHover: true,
  singleton: true,
  duration: 4000
};
Vue.use(Toasted, ToasterOptions);
Vue.use(VueMeta);

// global variables
Vue.prototype.$showNavbar = true; // boolean

// Click Outside Custom Directive
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
