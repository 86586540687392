<template>
  <div>
    <template v-if="loading">
      <SkeletonLoader />
    </template>
    <template v-else>
      <div @click="handleClick" class="entity-item-container d-flex" style="gap: 15px;">
        <img v-if="img" class="entity-item-img" width="52" height="53" :src="img" alt="icon">
        <div class="d-flex justify-content-center flex-column w-100" style="gap: 4px;">
          <div class="entity-item-text-container d-flex align-items-center" style="gap: 10px;">
            <p class="entity-item-text-head" :title="title">
              <HighlightContent :searchTerm="searchTerm" :content="title" />
            </p>
            <p v-if="badge" class="entity-item-text-badge" :style="{ background: badge.color }">
              {{ badge.title }}
            </p>
          </div>
          <p v-if="description" v-html="description" class="entity-item-desc" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonLoader from '../../shared/components/skeleton-loader.vue';
import HighlightContent from '../highlight-content.vue';
import { BlogType, ZadiType } from '../../constants/entities';

const fullDetailsPageEntityTypes = [BlogType.NEWS, BlogType.SUCCESS_STORY, BlogType.EVENT];

export default {
  name: 'EntityItem',
  components: {
    SkeletonLoader,
    HighlightContent,
  },
  props: {
    searchTerm: {
      type: String,
      required: true,
      default: () => '',
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    badge: {
      type: Object, // { title: string, color: string }
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    tamayozId: {
      type: String,
      required: true,
    },
    magazineLink: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  methods: {
    handleClick() {
      this.handleCloseModal();

      if (this.type === ZadiType.BOOK) {
        return this.handlePush(`/zadi/books/${this.tamayozId}`);
      }

      if (this.type === ZadiType.MAGAZINE) {
        return window.open(this.magazineLink, '_blank').focus();
      }

      if (this.type === BlogType.FAQ) {
        return this.handlePush('/faqs');
      }

      if (fullDetailsPageEntityTypes.includes(this.type)) {
        this.handlePush(`/full-details?id=${this.tamayozId}`);
      }
    },
    handlePush(targetPath) {
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
    },
    handleCloseModal() {
      $(`#global-search-modal`).modal('hide');
    },
  },
}
</script>

<style scoped>
.entity-item-container {
  cursor: pointer;
}
.entity-item-text-badge {
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  padding: 0px 4px;
  line-height: 22px;
  white-space: nowrap;
}
.entity-item-img {
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
}
.entity-item-text-head {
  color: #5D4858;
  flex-grow: 1;
}
.entity-item-desc {
  font-size: 12px;
  color: #5D4858;
}
p.entity-item-desc >>> p {
  width: 350px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
