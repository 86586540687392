const environment = {
  donateApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'donate/',
  assetsDir: '/assets/images/donate'
}
export {
  environment
}
export default {
  install (Vue) {
    Vue.donateEnvironment = environment
    Vue.prototype.$donateEnvironment = environment
  }
}
