<template>
  <div class="px-3 py-3 results">
    <p class="results-text">الأكثر بحثاً</p>
    <button
      v-for="(item, index) in recentSearchResults"
      :key="index"
      @click="$emit('select', item)"
      class="results-item"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'RecentSearchResults',
  data() {
    return {
      recentSearchResults: [
        'كيف أكفل يتيم',
        'التطوع',
        'التوظيف',
        'مبلغ الكفالة'
      ]
    }
  },
}
</script>

<style scoped>
.results {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.results-text {
  font-size: 16px;
  font-weight: 500;
  color: #ccc;
}
.results-item {
  border: none;
  background: transparent;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.results-item:hover {
  text-decoration: underline;
}
</style>
