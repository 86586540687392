<template>
    <div class="error-creative-content">
        <div class="inner-wrapper">
            <h2>{{ $route.params.errorCode }}</h2>
            <b>
                <p>عذراً.. <br>
                    {{ $route.params.errorMsg }}
                </p>
            </b>
            <a href="/" class="back-button solid-button-one">العودة للرئيسية</a>
        </div>
    </div> <!-- /.error-creative-content -->
</template>
<script>
    export default {
        name: 'error',
        metaInfo: {
            title: 'حدث خطأ',
        },
        data() {
            return {
                errorCode: 0,
                errorMsg: "حدث خطأ"
            }
        }
    }
</script>
<style scoped>

</style>
