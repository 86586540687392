<template>
  <div class="row">
    <div :class="`col-lg-${labelCol} p-r`">
      <h6 class="pb-5 inputLabel secondMobileFontSize labelColor"
          style="padding-right:14%; padding-top:10px">
        {{name}} </h6>
    </div>
    <div :class="`col-lg-${inputCol} mp paddingTop`" id="textAreaWidth">
      <div :class="{'textAreaPadding' : textAreaPadding}">
        <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }">
        <textarea :style="[errors.length > 0 ? {'border-color': '#DE3557'} : {}]"
                  class="form-control"
                  style="height: 110px"
                  v-model="computedValue">
        </textarea>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "formsTextArea",
    props: {
      name: {type: String, required: false},
      value: {type: [Number, String]},
      placeholder: {type: String, default: ''},
      validationRules: {type: String, default: null},
      textAreaPadding: {type: Boolean, default: false},
      labelCol: {type: Number, default: 2},
      inputCol: {type: Number, default: 10}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },
  }
</script>

<style>
  @import '../../../../public/assets/css/formsStyle.css';

  #textAreaWidth .form-control {
    width: 94%;
  }

  .textAreaPadding {
    padding-right: 35px;
  }

  @media (max-width: 575px) {
    #textAreaWidth .form-control {
      width: 100% !important;
    }

    .textAreaPadding {
      padding-right: 0px;
    }
  }
</style>
