import {environment} from "../website-environment";
import {httpClient} from "../../shared/services";


export default {

    saveVolunteer(volunteer) {
        const url = environment.websiteApiBaseUrl + 'voluntaryForms';
        return httpClient.post(url, volunteer)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
    save(form) {
        const url =  environment.websiteApiBaseUrl + 'forms';
        return httpClient.post(url, form)
            .then(res => res.data)
            .catch(error => console.log(error))
    }
}
