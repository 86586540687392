<template>
  <div class="our-feature-app py-5">
    <div class="container">
      <div class="row single-feature-box">
        <div class="col-lg-6 order-lg-last">
          <div class="text-wrapper mt-0">

            <div class="row mt-5 mb-5">
              <div class="col-2"></div>
              <div class="col-4">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1244.svg'"
                     alt="" class="icon">
              </div>
              <div class="col-6">
                <div class="row">
                  <h3>رسالتنا</h3>
                </div>
                <div class="row">
                  <p>نموذج إنساني رائد</p>
                </div>
              </div>
            </div>

            <div class="row mt-5 mb-5">
              <div class="col-4">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1243.svg'"
                alt="" class="icon">
              </div>
              <div class="col-6">
                <div class="row">
                  <h3>رؤيتنا</h3>
                </div>
                <div class="row">
                  <p>تحقيق نموذج متكامل للأعمال الخيرية التنموية الحديثة يحتذى به على الساحة الوطنية. و الوصول إلى مجتمع متكامل ومتكافل.</p>
                </div>
              </div>
              <div class="col-2"></div>
            </div>

            <div class="row mt-5 mb-5">
              <div class="col-2"></div>
              <div class="col-4">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1242.svg'"
                     alt="" class="icon">
              </div>
              <div class="col-6">
                <div class="row">
                  <h3>قيمنا</h3>
                </div>
                <div class="row">
                  <p>الكرامة الإنسانية , الخدمة , الأمانة , الاحترام , الخصوصية</p>
                </div>
              </div>
            </div>
          </div> <!-- /.text-wrapper -->
        </div> <!-- /.col- -->

        <div class="col-lg-6 order-lg-first">
          <div class="feature-img-box">
            <img :src="$sharedEnvironment.assetsDir + '/shape/shape-45.svg'" alt="" class="shape-one">
            <img :src="$sharedEnvironment.assetsDir + '/shape/shape-46.svg'" alt="" class="shape-two">
            <img :src="$sharedEnvironment.assetsDir + '/shape/shape-47.svg'" alt="" class="shape-three">
            <img :src="$sharedEnvironment.assetsDir + '/shape/shape-48.svg'" alt="" class="shape-four">
            <img :src="$sharedEnvironment.assetsDir + '/shape/shape-49.svg'" alt="" class="shape-five">
            <div class="row">
              <div class="col" dir="ltr">
                  <img class="m-auto" :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-737.svg'">
                   <div class="text-center">
                    <h1>أهدافنا</h1>
                     <carousel
                     :autoplay="true"
                     :nav="false"
                     :dots="true"
                     :responsive="{0:{items:1}}"
                     >
                      <span v-for="(targetItem, index) in ourTargetItemsList" :key="index">
                            <span>{{targetItem.description}}</span>
                      </span>
                    </carousel>
                   </div>
              </div>
            </div> <!-- /.row -->
          </div> <!-- /.feature-img-box -->
        </div> <!-- /.col- -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.our-feature-app -->
</template>

<script>
  import carousel from 'v-owl-carousel';
  export default {
    name: 'website-about',
    components: { carousel },
    data: function() {
       return {
        ourTargetItemsList : [
            {
             description : 'تقديم الرعاية و الخدمات المتنوعة للمستفيدين بشكل موثق'
            },
            {
              description : 'تخطيط منهجي شامل لرفع مستوى التعليم وصولاً للمرحلة الجامعية'
            },
            {
              description : 'توفير الرعاية الصحية المتكاملة (إحالات طبية , عمليات جراحية)'
            },
            {
               description : 'تأمين الاحتياجات الضرورية و المستلزمات الأساسية (غذاء, كساء, قرطاسية) '
            },
            {
               description : 'الرعاية الاجتماعية و الإشراف التربوي إضافة إلى التدريب المهني والتأهيل العلمي '
            },
            {
               description : 'تقديم الكفالة المادية للأيتام المسجلين للمساهمة في تحسين المستوى المعيشي'
            },
          ],

        };
  },
  }
</script>

<style scoped>

</style>
