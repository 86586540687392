var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "wow fadeInDown animated",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-1241.svg',
      "alt": ""
    }
  })]), _c('img', {
    staticClass: "oval-one",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-five",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-six",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-seven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eight",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-nine",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-ten",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eleven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg',
      "alt": ""
    }
  }), _vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('h1', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("الرعاية بمفهوم "), _c('br'), _c('b', [_vm._v("متميز")])]), _c('p', {
    staticClass: "sub-title wow fadeInUp animated",
    staticStyle: {
      "width": "50%",
      "min-width": "300px"
    },
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" مشروع إنساني تنموي رائد يهدف إلى تقديم الكفالة لليتيم على أكمل وجه ضمن خطة متكاملة تشمل معظم جوانب حياته ، وتقديم الرعاية والخدمات المتنوعة للمستفيدين للوصول إلى مجتمع متكامل يساهم في تحقيق التنمية المستدامة ")]), _c('ul', {
    staticClass: "button-group lightgallery"
  }, [_c('li', [_c('a', {
    staticClass: "fancybox video-button-one wow fadeInLeft animated",
    attrs: {
      "data-fancybox": "",
      "href": "https://www.youtube.com/watch?v=qNCSeFXgD-k",
      "data-wow-delay": "1.5s"
    }
  }, [_vm._v("التميز في دقيقة"), _c('i', {
    staticClass: "flaticon-play-button icon-right"
  })])])])])]);
}]

export { render, staticRenderFns }