const StringCaseStyles = {
  normalCase: function (string) {
    return string.replace(/-/g, ' ')
  },
  kebabCase: function (string) {
    return (string) ? string.replace(/ /g, '-') : "";
  }
};
const ObjectHelpers = {
  initObjectMembers(object) {
    Object.keys(object).forEach((key) => {
      object[key] = null
    });
  }
};
export {
  StringCaseStyles,
  ObjectHelpers
}
export default {
  install(Vue) {
    Vue.stringCase = StringCaseStyles;
    Vue.prototype.$stringCase = StringCaseStyles;
    Vue.ObjectHelpers = ObjectHelpers;
    Vue.prototype.$objectHelpers = ObjectHelpers;
  },
  checkForm(validationRef) {
    setTimeout(() => {
      const errors = Object.entries(validationRef.errors)
        .map(([key, value]) => ({key, value}))
        .filter(error => error["value"].length);
      validationRef.refs[errors[0]["key"]].$el.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }, 100);
  }
}

