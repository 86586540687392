var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "wow fadeInDown animated",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/questions-and-answers/' + 'Group-1573.svg',
      "alt": ""
    }
  })]), _c('img', {
    staticClass: "oval-one",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-five",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-six",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-seven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eight",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-nine",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-ten",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eleven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "row text-center"
  }, [_c('div', {
    staticClass: "col-12"
  }, _vm._l(_vm.tagsList, function (tag) {
    return _c('a', {
      staticClass: "contact-button line-button-one mx-3 my-3",
      class: {
        active: tag.isActive
      },
      attrs: {
        "href": "javascript:"
      },
      on: {
        "click": function ($event) {
          return _vm.tagClicked(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 0), _c('div', {
    staticClass: "col-12"
  }, [_c('a', {
    staticClass: "btn btn-outline-danger  mx-3 my-3",
    attrs: {
      "href": "javascript:"
    },
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v(" حذف المرشحات ")])])])])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mt-150"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "faq-tab-wrapper"
  }, [_c('div', {
    staticClass: "faq-panel"
  }, [_c('div', {
    staticClass: "panel-group theme-accordion",
    attrs: {
      "id": "accordion"
    }
  }, [_vm._l(_vm.FAQList, function (faq, index) {
    return _c('div', {
      staticClass: "panel wow fadeInUp animated",
      staticStyle: {
        "margin": "0 !important"
      },
      attrs: {
        "data-wow-delay": "0.7s",
        "data-wow-duration": "0.5s"
      }
    }, [_c('div', {
      class: index ? 'panel-heading' : 'panel-heading active-panel'
    }, [_c('h6', {
      staticClass: "panel-title"
    }, [_c('a', {
      attrs: {
        "aria-expanded": "true",
        "data-parent": "#accordion",
        "data-toggle": "collapse",
        "href": '#' + faq.id + ''
      }
    }, [_vm._v(" " + _vm._s(faq.title) + " ")])])]), _c('div', {
      staticClass: "panel-collapse collapse",
      class: index ? '' : 'show',
      attrs: {
        "id": '' + faq.id + ''
      }
    }, [_c('div', {
      staticClass: "panel-body"
    }, [_c('ul', [_c('li', [_c('p', {
      attrs: {
        "id": "message"
      },
      domProps: {
        "innerHTML": _vm._s(faq.short_desc)
      }
    })])])])])]);
  }), _c('div', {
    staticClass: "element-section mb-150"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isPagenateBackHidden,
      expression: "!isPagenateBackHidden"
    }],
    staticClass: "theme-pager prev mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('previous');
      }
    }
  }, [_c('span', {
    staticClass: "flaticon-next mx-2"
  }), _vm._v("   السابق ")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.canLoadMore,
      expression: "canLoadMore"
    }],
    staticClass: "theme-pager next mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('next');
      }
    }
  }, [_vm._v(" التالي    "), _c('span', {
    staticClass: "flaticon-back mx-2"
  })])])])])], 2)])])])]), _vm._m(1)]), _c('contact-form', {
    attrs: {
      "type": "Message"
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-wrapper"
  }, [_c('h1', {
    staticClass: "main-title wow fadeInUp animated",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("سؤال وجواب")]), _c('h4', {
    staticClass: "wow fadeInUp animated title mt-5",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" لديك سؤال ؟ قد تجد إجابتك هنا ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mb-5"
  }, [_c('h2', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("أو , يمكنك سؤالنا مباشرةً")])]);
}]

export { render, staticRenderFns }