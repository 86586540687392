<template>
    <div class="agn-about-us">
        <img src="assets/images/website/website-services/shape/shape-61.svg" alt="" class="shape-one">
        <img src="assets/images/website/website-services/shape/shape-64.svg" alt="" class="shape-two"
             data-aos="fade-right">
        <img src="assets/images/website/website-services/shape/shape-65.svg" alt="" class="shape-three"
             data-aos="fade-left">
        <img src="assets/images/website/website-services/shape/shape-60.svg" alt="" class="shape-four">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="text-wrapper">
                        <div class="theme-title-one text-center">
                            <h2 class="main-title">خدمات المؤسسة</h2>

                            <div class="row p-5">
                                <div class="col-lg-4 col-md-6 col-sm-12 p-4 wow fadeInUp animated" v-for="(service, index) in servicesList"
                                     :key="index">
                                    <img class="m-auto CustomImageHeight"
                                         :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ service.image">
                                    <h4 class="title">{{service.title}}</h4>
                                    <p>{{service.description}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- /.container -->
    </div> <!-- /.agn-about-us -->
</template>
<script>
    export default {
        name: 'website-services',
        data: function () {
            return {
                servicesList: [
                    {
                        image: 'Group-1037.svg',
                        title: 'رعاية اجتماعية',
                        description: 'متابعة أحوال الأسر من خلال مقابلات دورية وزيارات منزلية إشراف اجتماعي على واقعهم لتطوير وضعهم ومساعدتهم في تحسين أحوال حياتهم .'
                    },
                    {
                        image: 'Group-939.svg',
                        title: 'رعاية نفسية',
                        description: 'إدارة الحالات نفسية وتقديم مشورات (فردية- أسرية- نفسية)تشمل التوعية (محاضرات- جلسات نقاش- عروض مرئية ومجموعات تدخّل مركّزة وأنشطة ترفيهية و زيارات منزلية داعمة.'
                    },
                    {
                        image: 'Group-1564.svg',
                        title: 'رعاية صحية',
                        description: 'نقدم جميع أنواع الرعايات الصحية من إحالات طبية وأدوية وعمليات جراحية و تجهيزات لازمة. '
                    },
                    {
                        image: 'Group-945.svg',
                        title: 'رعاية تنموية',
                        description: 'دروات تدريب مهني ( ICDL - مبادئ الإلكترون - صيانة حواسيب وجوالات - طبخ وصناعات غذائية - كروشيه وصوف - خياطة - تمريض - منظفات - .... ).'
                    },
                    {
                        image: 'Group-1036.svg',
                        title: 'رعاية مادية',
                        description: 'كفالة اليتيم بمبلغ مالي شهري، وتستمر الكفالة حتى التخرج أو العمل أو الزواج.'
                    },
                    {
                        image: 'Group-1038.svg',
                        title: 'رعاية تعليمية',
                        description: 'دورات تقوية وخاصة للشهادتين الإعدادية والثانوية ومتابعة دورية لقياس التحصيل العلمي إضافة إلى تنظيم برامج ترفيهية تحفزهم وترفع من معنوياتهم وتكريم المتفوقين في فعاليات احتفالية.'
                    }
                ],
            };
        }
    }
</script>

<style scoped>

    .CustomImageHeight {
        height: 18em;
    }

</style>
