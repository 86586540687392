<template>
    <!-- Element Style -->
    <div class="sass-testimonial-section">
        <img v-for="(item, index) in testimonials" :key="index" v-if="item.image_url" :src="item.image_url" height="110" width="110" alt=""
             class="people">
        <div class="container">
            <div class="theme-title-one text-center hide-pr">
                <div class="icon-box hide-pr">
                    <img :src="$websiteEnvironment.assetsDir + '/website-testimonials/shape/bg-shape4.svg'" alt="" class="bg-shape">
                    <img :src="$websiteEnvironment.assetsDir + '/website-testimonials/icon/icon27.svg'" alt="" class="icon">
                </div>
                <h2 class="main-title">قالوا عن التميّز</h2>
            </div> <!-- /.theme-title-one -->

            <div class="inner-container" dir="ltr">
                <div class="main-content">
                    <div class="agn-testimonial-slider">
                        <carousel :items="1" :autoplay="true" :nav="false">
                            <div class="item" v-for="(item, index) in testimonials" :key="index">
                                <p v-html="item.message"></p>
                                <h6 class="name" style="font-family: Cairo !important">{{item.author}}</h6>
                                <span class="designation">{{item.job}}</span>
                            </div>
                        </carousel>
                    </div>
                </div>
            </div> <!-- /.inner-container -->

        </div> <!-- /.container -->
    </div> <!-- /.sass-testimonial-section -->
</template>

<script>

    import carousel from 'vue-owl-carousel'

    export default {
        name: 'website-testimonial',
        components: {carousel},
        props: {
            testimonials: {
                type: Array,
            }
        },
        data: function () {
            return {
                feedbackList: [
                    {
                        name: 'متطوع ',
                        description: ' شكرا لجمعية التميز ولجميع العاملين بالخفاء على تأمين وتوفير  تدريب لل mhgap للأطباء كان تدريب مميز وبالفعل استفدت منو وان شاء الله لح يخليني اقدر فيد الناس بمسيرتي المستقبلية ',
                        other: '',
                    },
                    {
                        name: 'هشام سرميني',
                        description: ' يعني لو أمها وأبوها عايشين مابيعاملوها هيك , والحمدلله بمعية التميز مااعتزنا حدا ',
                        other: 'جد الطفلة اليتيمة (سلام متيني)',
                    },
                    {
                        name: 'متطوع صيفي',
                        description: 'التميز تجربة فريدة  تعلمت منها الكثير ',
                        other: 'دمشق',
                    },
                    {
                        name: 'السيد حسام طباع',
                        description: ' تميز على كافة مستوياته ',
                        other: 'عضو مجلس ادارة بجمعية حقوق الطفل',
                    },
                    {
                        name: '',
                        description: '  جماعة التميز أظرف ناس والله بتفائل بشوفتكن الله يعطيكم ألف عافية ويجزيكم كل الخير ',
                        other: '',
                    },
                    {
                        name: 'متطوعة',
                        description: '  فعلا التطوع معكن حياة من نوع تاني والشي اللي منعملو سوا بيطلع من القلب  ',
                        other: '',
                    },
                    {
                        name: ' سوسن عيساوي',
                        description: '  معاملتكن الطيبة هي يلي بتشفي ',
                        other: ' جدة الأيتام (الوصي)',
                    },
                    {
                        name: '',
                        description: '  مركز التميز من أهم مراكز الرعاية النفسية والتمكين الاجتماعي من خلال استخدام أفضل الوسائل الإنسانية الداعمة بخبرات تخصصية ولمسات إنسانية مميزة....',
                        other: 'أحد الشخصيات الهامة والمؤثرة في مجال الدعم النفسي الاجتماعي',
                    }
                ],
            };
        },
    }
</script>

<style scoped>

</style>
