<template>
    <div id="theme-banner-one">
        <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
            <!-- <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape1.png'" alt=""> -->
            <!-- <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape2.png'" style="margin-right: 50px" alt="" class="shape-one wow fadeInDown animated"
                 data-wow-delay="1.8s"> -->
            <!-- <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape3.png'" style="margin-right: 40px" alt="" class="shape-two wow fadeInUp animated"
                 data-wow-delay="2.7s"> -->
            <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-1241.svg'"
                 alt="" class="wow fadeInDown animated">
        </div>
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt="" class="shape-three">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt="" class="shape-four">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt="" class="shape-five">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt="" class="shape-six">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt="" class="shape-seven">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt="" class="shape-eight">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt="" class="shape-nine">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt="" class="shape-ten">
        <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt="" class="shape-eleven">
        <div class="container">
            <div class="main-wrapper">
                <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">الرعاية بمفهوم <br><b>متميز</b></h1>
                <p class="sub-title wow fadeInUp animated" data-wow-delay="0.9s" style="width: 50%; min-width: 300px;">
                    مشروع إنساني تنموي رائد
                    يهدف إلى تقديم الكفالة لليتيم على أكمل وجه ضمن خطة متكاملة
                    تشمل معظم جوانب حياته ، وتقديم الرعاية والخدمات المتنوعة للمستفيدين للوصول إلى مجتمع متكامل يساهم في تحقيق التنمية المستدامة
                </p>
                <ul class="button-group lightgallery">
                    <!--          <li><a href="about-us-standard.html" class="more-button solid-button-one wow fadeInRight animated"-->
                    <!--                 data-wow-delay="1.5s">انضم إلينا <i class="fa fa-angle-left icon-right"-->
                    <!--                                                        aria-hidden="true"></i></a>-->
                    <!--          </li>-->

                    <li><a data-fancybox href="https://www.youtube.com/watch?v=qNCSeFXgD-k"
                           class="fancybox video-button-one wow fadeInLeft animated" data-wow-delay="1.5s">التميز في دقيقة<i
                            class="flaticon-play-button icon-right"></i></a></li>
                </ul>
            </div> <!-- /.main-wrapper -->
        </div> <!-- /.container -->
    </div>
</template>
<script>
    export default {
        name: 'website-banner'
    }
</script>

<style scoped>

    #theme-banner-one {
        background: none;
        position: relative;
    }


    #theme-banner-one .illustration {
        position: absolute;
        left: -25%;
        top: 0%;
        width: 65%;
    }

</style>
