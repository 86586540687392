import Vue from 'vue'

/* ===================== Environment ======================== */
// website env
import websiteEnv from '../app/website/website-environment'
// shared env
import sharedEnv from '../app/shared/shared-environment'

Vue.use(websiteEnv)
Vue.use(sharedEnv)

// donate env
import donateEnv from '../app/donate/donate-environment'

Vue.use(donateEnv)

/* ===================== Sweet alert ======================== */
import VueSweetalert2 from 'vue-sweetalert2'
// css style
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)
/* ===================== Utilities ======================== */
import stringCaseStyles from '../app/shared/utilities/index'

Vue.use(stringCaseStyles)
