<template>
    <div>
        <Loader :isLoading="isLoading"></Loader>

        <div id="theme-banner-one">

          <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
            <img :src="$websiteEnvironment.assetsDir + '/website-svgs/success-storeis/' + 'Group-903.svg'"
                 alt="" class="wow fadeInLeft animated">
          </div>

          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt=""
               class="shape-three">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt=""
               class="shape-four">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt=""
               class="shape-five">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt=""
               class="shape-six">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt=""
               class="shape-seven">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt=""
               class="shape-eight">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt=""
               class="shape-nine">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt=""
               class="shape-ten">
          <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt=""
               class="shape-eleven">
            <div class="round-shape-one"></div>
            <div class="container">
                <div class="main-wrapper">
                    <h2 class="wow fadeInUp animated h3" data-wow-delay="0.4s">
                        قصص مؤثرة أصبح أبطالها جزء متميّز <br>
                        من  أسرة <b> التميّز</b>

                    </h2>
                    <p class="sub-title wow fadeInUp animated mt-5" data-wow-delay="0.9s">
                        تسليط الضوء على جزء من القصص المؤثرة التي رعتها  <br>
                          <b>جمعية التميّز</b> فنجحت في تحدي الظروف والمصاعب وتجاوزها <br>
                          وصولاً إلى نجاحات باهرة
                    </p>
                </div> <!-- /.main-wrapper -->
            </div> <!-- /.container -->
        </div>
        <!-- Stories -->
        <div>
            <div class="solid-inner-banner mt-0 pt-5">
                <h2 class="page-title">من قصصنا</h2>
            </div>

            <div class="our-blog blog-masonry">
                <div class="container text-center" v-if="isEmptyResult">
                    <h5>لا يوجد نتائج</h5>
                </div>
                <div class="container" v-if="!isEmptyResult">
                    <div class="row masnory-blog-wrapper  wow fadeInUp animated" v-if="blogsList">
                        <div class="isotop-item col-lg-4" v-for="(item, index) in blogsList"
                             :key="index"
                             @click="NavigateToDetailsPage(item.id)">
                            <div class="single-blog-post">
                                <div class="img-holder">
                                    <img v-if="item.image_preview_url" :src="item.image_preview_url" alt="" height="230px">
                                    <img v-if="!item.image_preview_url"
                                         :src="$websiteEnvironment.assetsDir + '/website-svgs/news/'+'Group-1574.svg'"
                                         alt="">
                                </div>

                                <div class="post-data">
                                    <a href="#" class="date">{{item.date | moment("MM-DD-YYYY") }}</a>
                                    <h5 class="blog-title-one title"  style="font-family: Cairo !important">
                                        <a href="javascript:"> {{item.title}} </a>
                                    </h5>
                                    <p class="blog-title-tow title" style="font-family: Cairo !important">
                                        <a href="javascript:" v-html="item.short_desc"> </a>
                                    </p>
                                    <a href="javascript:" class="read-more"><i class="flaticon-back-1"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center pt-90">
                    </div>
                </div>
            </div>

            <div class="element-section mb-150">
                <div class="container">
                    <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
                        <span v-show="!isPagenateBackHidden" v-on:click="paginate('previous')"
                              class="theme-pager prev mx-4">
                            <span class="flaticon-next mx-2"></span>
                             &nbsp;&nbsp;السابق
                        </span>
                        <span v-show="canLoadMore" v-on:click="paginate('next')" class="theme-pager next mx-4">
                            التالي &nbsp;&nbsp;
                            <span class="flaticon-back mx-2"></span>
                         </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '../../shared/components/Loader'
    import {RepositoryFactory} from "../repositories/RepositoryFactory";
    import {VueMasonryPlugin} from 'vue-masonry'
    import Vue from 'vue'

    const blogRepository = RepositoryFactory.get('blog')

    Vue.use(VueMasonryPlugin)
    export default {
        name: 'SuccessStories',
        metaInfo: {
            title: 'قصص مؤثرة',
        },
        components: {
            Loader
        },
        data() {
            return {
                blogsList: [],
                activeFilter: '',
                selectedType: 'SuccessStory', // set initial value
                page:'',
                nextPageUrl:'',
                prevPageUrl :'',
                perPage: 9,
                canLoadMore: true,
                isEmptyResult: true,
                isPagenateBackHidden: true,
                isLoading: true,
            }
        },
        mounted() {
            this.getBlogByType(this.selectedType);
        },
        methods: {
            getBlogByType(Type) {
                this.isLoading = true;
                this.activeFilter = Type;
                blogRepository.fetchBlogByType(Type, this.page , this.perPage).then((res) => {
                    this.blogsList = res.blogs.entities;
                    this.isLoading = false;
                    this.isEmptyResult = this.blogsList.length == 0 ? true : false;
                    this.prevPageUrl = res.blogs.prev_page_url;
                    this.isPagenateBackHidden = this.prevPageUrl != '' ? false : true;
                    this.nextPageUrl = res.blogs.next_page_url;
                    this.canLoadMore =  this.nextPageUrl  != '' ? true : false;
                });
            },

            NavigateToDetailsPage(blogId) {
                this.$router.push({name: 'Full-Details', query: {id: blogId}});
            },

            paginate(type) {
                this.getBlogByType(
                    this.activeFilter,
                    type == 'next' ?
                        this.page = this.nextPageUrl.split(/[? &]/)[1]
                        :
                        this.page = this.prevPageUrl.split(/[? &]/)[1]
                );
            }
        }
    }
</script>

<style scoped>

    #theme-banner-one .illustration {
      position: absolute;
      left: -2%;
      top: 20%;
      width: 40%;
    }

</style>
