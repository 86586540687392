<template>
  <div class="zadi-page-wrapper">
    <zadi-header />
    <slot />
    <the-footer />
  </div>
</template>

<script>
import ZadiHeader from './ZadiHeader.vue'
import TheFooter from '@/app/shared/components/TheFooter.vue'

export default {
  name: 'ZadiLayout',
  components: {
    ZadiHeader,
    TheFooter
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.zadi-page-wrapper {
  font-family: "Tajawal", sans-serif;
}
</style>