import {environment} from "../website-environment";
import {httpClient} from "../../shared/services";

export default {
    fetchRehabAlhabebAnalytics(keys) {
        let url = environment.websiteApiBaseUrl + 'getAnalyticsByKeys';
        return httpClient.post(url, keys)
            .then(res => res.data['data'])
            .catch(error => console.log(error))
    },
    sendPrays(analytics) {
        let url = environment.websiteApiBaseUrl + 'analytics';
        return httpClient.post(url, analytics)
            .then(res => res.data)
            .catch(error => console.log(error))
    }
}
