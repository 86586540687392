import analyticsRepository from "./analyticsRepository";
import feedbackRepository from "./feedbackRepository";
import homePageRepository from "./homePageRepository";
import blogRepository from "./blogRepository";
import formRepository from "./formRepository";
import visitorRepository from "./visitorRepository";

const repositories = {
    analytics: analyticsRepository,
    feedback: feedbackRepository,
    homePage: homePageRepository,
    blog: blogRepository,
    form : formRepository,
    visitor: visitorRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
