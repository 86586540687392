var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ten-yars-section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "texts-actions"
  }, [_c('p', [_vm._v("عشرة أعوام من التميز")]), _c('p', [_vm._v(" تعرّف عن قرب على أبرز معالم رحلة نجاح مؤسسة التميز التنموية في كفالة اليتيم ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'TenYears'
      }
    }
  }, [_vm._v("اكتشف رحلتنا")])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4 d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/ten-years/home-1.png")
    }
  })])]);
}]

export { render, staticRenderFns }