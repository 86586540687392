<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :loader="loaderType"
                 :color="color"
                 :height="128"
                 :width="128"
                 :lock-scroll="true"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props: ['isLoading'],

        data() {
            return {
                color: "#4B364E",
                loaderType: "dots"
            }
        },
        components: {
            Loading
        },
    }

</script>
