var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 p-r "
  }, [_c('h6', {
    staticClass: "pb-5 inputLabel secondMobileFontSize labelColor",
    class: {
      'reduceFontSize': _vm.reduceFontSize
    },
    staticStyle: {
      "padding-right": "14%",
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])]), _c('div', {
    staticClass: "col-lg-8 mp paddingTop"
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.name,
      "rules": _vm.validationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_vm.type === 'checkbox' ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.computedValue,
            expression: "computedValue"
          }],
          staticClass: "form-control",
          class: {
            'customWidth': _vm.customWidth
          },
          style: [errors.length > 0 ? {
            'border-color': '#DE3557'
          } : {}],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.computedValue) ? _vm._i(_vm.computedValue, null) > -1 : _vm.computedValue
          },
          on: {
            "change": function ($event) {
              var $$a = _vm.computedValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.computedValue = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.computedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.computedValue = $$c;
              }
            }
          }
        }) : _vm.type === 'radio' ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.computedValue,
            expression: "computedValue"
          }],
          staticClass: "form-control",
          class: {
            'customWidth': _vm.customWidth
          },
          style: [errors.length > 0 ? {
            'border-color': '#DE3557'
          } : {}],
          attrs: {
            "type": "radio"
          },
          domProps: {
            "checked": _vm._q(_vm.computedValue, null)
          },
          on: {
            "change": function ($event) {
              _vm.computedValue = null;
            }
          }
        }) : _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.computedValue,
            expression: "computedValue"
          }],
          staticClass: "form-control",
          class: {
            'customWidth': _vm.customWidth
          },
          style: [errors.length > 0 ? {
            'border-color': '#DE3557'
          } : {}],
          attrs: {
            "type": _vm.type
          },
          domProps: {
            "value": _vm.computedValue
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) { return; }
              _vm.computedValue = $event.target.value;
            }
          }
        }), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }