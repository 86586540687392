<template>
    <div id="app">
        <component :is="this.$route.meta.layout || 'div'">
            <router-view />
        </component>
    </div>

</template>

<script>
import mapboxgl from "mapbox-gl";

    export default {
        name: 'App',
        metaInfo: {
            titleTemplate: '%s | Al Tamayoz',
        },
        data() {
            return {
                layout: 'div'
            }
        },
        mounted() {
          mapboxgl.setRTLTextPlugin(
            'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
            null,
            true
          );
        }
    }
</script>
<style>

/* this css for the message of feedback in FAQ view */
  p#message table{
    width: 100% !important;
  }

  .v-toast__item .v-toast__item--error .v-toast__item--top-right{
  flex-direction: row-reverse !important;
}

.v-toast__item{
  max-width: 70% !important;
}
.v-toast__text { 
  direction:rtl !important;
  padding: 0.2em 0.5em !important;
  font-family: 'Tajawal', sans-serif !important;
}
.v-toast__item .v-toast__text {
  padding: 0.5em 1em !important;
  font-size: 15px !important;
}

.v-toast__icon{
  margin-left: 0.5em !important;
  margin-right: 1em !important;
}

</style>
