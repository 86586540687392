var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    on: {
      "click": _vm.emitClick
    }
  }, [_c('i', {
    staticClass: "search-icon fa fa-search"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }