var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('loader', {
    attrs: {
      "is-loading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "our-blog blog-masonry"
  }, [_vm.blogs.length == 0 ? _c('div', {
    staticClass: "container text-center"
  }, [_c('h5', [_vm._v("لا يوجد نتائج")])]) : _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row masnory-blog-wrapper"
  }, _vm._l(_vm.blogs, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "isotop-item col-lg-4",
      on: {
        "click": function ($event) {
          return _vm.NavigateToDetailsPage(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "single-blog-post"
    }, [_c('div', {
      staticClass: "img-holder"
    }, [item.image_preview_url ? _c('img', {
      attrs: {
        "src": item.image_preview_url,
        "alt": ""
      }
    }) : _vm._e(), !item.image_preview_url ? _c('img', {
      attrs: {
        "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/news/' + 'Group-1574.svg',
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "post-data"
    }, [_c('h5', {
      staticClass: "blog-title-one title",
      staticStyle: {
        "font-family": "Cairo !important"
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('p', {
      staticClass: "blog-title-tow title",
      staticStyle: {
        "font-family": "Cairo !important"
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:"
      },
      domProps: {
        "innerHTML": _vm._s(item.short_desc)
      }
    })]), _vm._m(0, true)])])]);
  }), 0)])]), _c('div', {
    staticClass: "element-section mb-150"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isPagenateBackHidden,
      expression: "!isPagenateBackHidden"
    }],
    staticClass: "theme-pager prev mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('previous');
      }
    }
  }, [_c('span', {
    staticClass: "flaticon-next mx-2"
  }), _vm._v("   السابق ")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.canLoadMore,
      expression: "canLoadMore"
    }],
    staticClass: "theme-pager next mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('next');
      }
    }
  }, [_vm._v(" التالي    "), _c('span', {
    staticClass: "flaticon-back mx-2"
  })])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "read-more",
    attrs: {
      "href": "javascript:"
    }
  }, [_c('i', {
    staticClass: "flaticon-back-1"
  })]);
}]

export { render, staticRenderFns }