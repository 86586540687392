var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-container"
  }, [_c('i', {
    staticClass: "fa fa-search",
    staticStyle: {
      "color": "#667085"
    }
  }), _c('input', {
    staticClass: "search-input",
    attrs: {
      "type": "text",
      "placeholder": "ابحث عن ما تريد .."
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue($event.target.value);
      }
    }
  }), _vm.value.length > 0 ? _c('button', {
    on: {
      "click": _vm.clearSearchHandler
    }
  }, [_c('i', {
    staticClass: "fa fa-close",
    staticStyle: {
      "color": "#667085"
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }