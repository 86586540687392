import {extend, localize , setInteractionMode} from 'vee-validate';
import ar from './ar.json';
import * as rules from 'vee-validate/dist/rules';

setInteractionMode('eager');

export default {
  initValidations() {
    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    });
    extend('alpha', {
      ...rules.alpha,
      ...rules.alpha_spaces,
      ...rules.alpha_num,
      ...rules.length,
      ...rules.numeric,
      ...rules.digits
    });
    localize('ar', ar);
  }
}
