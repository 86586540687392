var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 p-r"
  }, [_c('h6', {
    staticClass: "pb-5 inputLabel secondMobileFontSize labelColor",
    staticStyle: {
      "padding-right": "16%",
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.name))])]), _c('div', {
    staticClass: "col-lg-8 mp"
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.name,
      "rules": _vm.validationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "style-chooser form-control",
          class: {
            'customWidth': _vm.customWidth
          },
          style: [errors.length > 0 ? {
            'border-color': '#DE3557'
          } : {}],
          attrs: {
            "multiple": _vm.isMultiple,
            "options": _vm.options,
            "placeholder": _vm.placeholder,
            "dir": "rtl"
          },
          on: {
            "input": _vm.selectAction
          },
          model: {
            value: _vm.computedValue,
            callback: function ($$v) {
              _vm.computedValue = $$v;
            },
            expression: "computedValue"
          }
        }), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }