var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "gap": "10px"
    }
  }, [_vm.entities.length === 0 && !_vm.isLoading ? _c('SearchEmptyState') : _vm._e(), _vm._l(_vm.entities, function (entity) {
    return _c('EntityItem', {
      key: entity.tamayozId,
      attrs: {
        "title": entity.title,
        "searchTerm": _vm.searchTerm,
        "magazineLink": entity.url,
        "tamayozId": entity.tamayozId,
        "type": entity.type.toLowerCase(),
        "description": entity.short_desc || '',
        "badge": _vm.badgeConfig[entity.type.toLowerCase()],
        "img": entity.imageUrl || _vm.images[entity.type.toLowerCase()]
      }
    });
  }), _c('infinite-loading', {
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [_c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "gap": "10px"
    },
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('SkeletonLoader'), _c('SkeletonLoader'), _c('SkeletonLoader')], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }