import {environment} from "../website-environment";
import {httpClient} from "../../shared/services";

export default {
    storeZadiVisitor(visitor) {
        const url = environment.websiteApiBaseUrl + 'visitor/zadi/store';
        return httpClient.post(url, visitor)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
}
