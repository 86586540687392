<template>
  <blogs :type="type"/>
</template>

<script>
  import Blogs from "./Blogs";

  export default {
    name: "UpcomingEvent",
    data() {
      return {
        type: 'UpcomingEvent'
      }
    },
    components: {Blogs},
  }
</script>

<style scoped>

</style>
