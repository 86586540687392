<template>
    <div class="agn-counter-section">
        <img :src="$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-55.svg'" alt="" class="shape-one">
        <img :src="$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-59.svg'" alt="" class="shape-two">
        <img :src="$websiteEnvironment.assetsDir + '/website-statistics/shape/shape-61.svg'" alt="" class="shape-three">
        <div class="container">
            <div class="main-wrapper">
                <div class="theme-title-one text-center">
                    <h2 class="main-title">حقائق وأرقام عن التميّز</h2>
                </div> <!-- /.theme-title-one -->

                <div class="counter-wrapper">
                    <div class="row">
                        <div class="col-sm-4" v-for="(item, key) in analytics">
                            <div class="single-counter-box">
                                <img class="m-auto" :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Ellipse-17.svg'">
                                <h3 class="centered text-white">
                                    <countTo class="timer" :startVal='startVal' :endVal='Number(item.value)' :duration='3000'></countTo>
                                </h3>
                                <p>{{item.readable_key}}</p>
                            </div> <!-- /.single-counter-box -->
                        </div>
                    </div>
                </div> <!-- /.counter-wrapper -->
            </div> <!-- /.main-wrapper -->
        </div> <!-- /.container -->
    </div> <!-- /.agn-counter-section -->
</template>

<script>
    import countTo from 'vue-count-to'

    export default {
        name: 'website-statistics',
        components: {countTo},
        data() {
            return {
                startVal: 0,
                // analytics: [
                //     {
                //         key: 'يزيد عدد الأطفال المكفولين',
                //         value: 15000,
                //     },
                //     {
                //         key: 'يزيد عدد الأطفال  المحتاجين لكفالة',
                //         value: 3000,
                //     },
                //     {
                //         key: 'يزيد عدد المستفيدين',
                //         value: 20000,
                //     }
                // ]
            }
        },
        props: {
            analytics: Array
        },
        created() {
        }
    }
</script>

<style scoped>

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

</style>
