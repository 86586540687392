<template>
  <div :class="`form-group has-error has-danger ${inputclass}`">
    <label for="" v-show="isLabel">{{name}}</label>
    <ValidationProvider
      :name="name"
      :rules="{ required: true , digits: 10, regex: /^09\d{8}$/}"
      v-slot="{ errors }"
    >
      <input :placeholder="name"
             data-error="يجب أن يبدأ رقم الموبايل ب 09"
             required="required"
             type="number"
             v-model="computedValue"
      />
      <span class="text-danger mb">{{ errors[0] }}</span>
      <div class="help-block with-errors "></div>
    </ValidationProvider>
  </div>
</template>

<script>
  export default {
    name: "mobilePhoneNumberInput",
    props: {
      name: {type: String, required: true},
      value: {type: [Number, String]},
      inputclass: {type: String, required: true},
      isLabel: {type: Boolean, default: false}
    },
    computed: {
      computedValue: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit("input", newValue)
        }
      }
    },
  }
</script>
