var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sass-testimonial-section"
  }, [_vm._l(_vm.testimonials, function (item, index) {
    return item.image_url ? _c('img', {
      key: index,
      staticClass: "people",
      attrs: {
        "src": item.image_url,
        "height": "110",
        "width": "110",
        "alt": ""
      }
    }) : _vm._e();
  }), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "theme-title-one text-center hide-pr"
  }, [_c('div', {
    staticClass: "icon-box hide-pr"
  }, [_c('img', {
    staticClass: "bg-shape",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-testimonials/shape/bg-shape4.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-testimonials/icon/icon27.svg',
      "alt": ""
    }
  })]), _c('h2', {
    staticClass: "main-title"
  }, [_vm._v("قالوا عن التميّز")])]), _vm._v(" "), _c('div', {
    staticClass: "inner-container",
    attrs: {
      "dir": "ltr"
    }
  }, [_c('div', {
    staticClass: "main-content"
  }, [_c('div', {
    staticClass: "agn-testimonial-slider"
  }, [_c('carousel', {
    attrs: {
      "items": 1,
      "autoplay": true,
      "nav": false
    }
  }, _vm._l(_vm.testimonials, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.message)
      }
    }), _c('h6', {
      staticClass: "name",
      staticStyle: {
        "font-family": "Cairo !important"
      }
    }, [_vm._v(_vm._s(item.author))]), _c('span', {
      staticClass: "designation"
    }, [_vm._v(_vm._s(item.job))])]);
  }), 0)], 1)])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }