<template>
  <blogs :type="type"/>
</template>

<script>
  import Blogs from './Blogs'
  export default {
    name: "Event",
    components: {Blogs},
    data() {
      return {
        type: "Event",
      }
    },

  }
</script>

<style scoped>

</style>
