<template>
    <div class="our-blog blog-default pt-150">
        <div class="full-grid-container">
            <div class="single-blog-post">
                <div class="img-holder">
                    <img :src="$websiteEnvironment.assetsDir + '/website-video/bg/video-bg.jpg'" alt="">
                    <a data-fancybox href="https://youtu.be/pCk5rcN3L58" class="fancybox video-button"><i
                            class="flaticon-play-button-2"></i></a>
                </div>
                <div class="post-data">
                    <a href="#" class="date">3, Jun, 2020</a>
                    <h5 class="blog-title-two title"><a href="https://youtu.be/pCk5rcN3L58"> أحلام الأطفال ترعاها أياديكم</a></h5>
                    <p>يسعى التميز لتنفيذِ طلباتِ أطفالِه وتحقيقِ أحلامِهم عن طريقِ رصدِ احتياجاتِهم وإيصالِها للكفلاءِ </p>
                </div> <!-- /.post-data -->
            </div> <!-- /.single-blog-post -->
        </div> <!-- /.full-grid-container -->
    </div> <!-- /.our-blog -->
</template>
<script>
  export default {
    name: 'website-video'
  }
</script>

<style scoped>

</style>
