<template>
  <button @click="emitClick">
    <i class="search-icon fa fa-search" />
  </button>
</template>

<script>
import { debounceCallback } from '../utilities';
import { isMacOSMixin } from '../mixins';

export default {
  mixins: [isMacOSMixin],
  methods: {
    emitClick() {
      this.$emit('click');
    },
    keysEventHandler(event) {
      debounceCallback(() => {
        const isMacOSMatchedKeys =
          this.isMacOS && event.metaKey && event.key.toLowerCase() === 'k';

        const isWindowsOSMatchedKeys =
          !this.isMacOS &&
          event.ctrlKey &&
          event.shiftKey &&
          event.key.toLowerCase() === 'k';

        if (isMacOSMatchedKeys || isWindowsOSMatchedKeys) {
          event.preventDefault();
          this.emitClick();
        }
      }, 200)
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keysEventHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keysEventHandler);
  },
};
</script>

<style scoped>
.search-icon {
  background: #5D4858;
  color: #fff;
  border-radius: 100%;
  font-size: 15px;
  padding: 12px;
}
</style>
