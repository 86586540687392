var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "jumbotron-bg custom-padding-bottom"
  }, [_c('footer', {
    staticClass: "theme-footer-one"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "bottom-footer"
  }, [_c('div', {
    staticClass: "clearfix"
  }, [_c('p', [_vm._v("AL Tamayoz - " + _vm._s(_vm.currentYear))]), _c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'ContactUs'
      }
    }
  }, [_vm._v("تواصل معنا")])], 1)])])])])]), _vm._v(" "), _vm._m(1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-footer "
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3 col-sm-6 col-12 about-widget"
  }, [_c('a', {
    staticClass: "logo",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "assets/images/logo/large-tamayoz-logo-new.svg",
      "alt": ""
    }
  })]), _c('a', {
    staticClass: "email",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("info@t-tamayoz.com")]), _c('a', {
    staticClass: "email",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("it_huda@t-tamayoz.com")])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-3 col-lg-3 col-sm-6 col-12 footer-list"
  }, [_c('h5', {
    staticClass: "title"
  }, [_vm._v("للتواصل")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "phone",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("رباعي: 9904")])]), _c('li', [_c('a', {
    staticClass: "phone",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("الهاتف: 61 - 963118833360+")])]), _c('li', [_c('a', {
    staticClass: "phone",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("الجوال: 61 - 963948833360+")])])])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-3 col-sm-6 col-12 footer-list"
  }, [_c('h5', {
    staticClass: "title"
  }, [_vm._v("حساباتنا البنكية")]), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("بنك البركة : 7000838")])]), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("بنك الشام الإسلامي : 7000136")])]), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("بنك سورية الدولي الإسلامي : 202222")])]), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v("البنك الوطني الإسلامي : 70000")])])])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-3 col-lg-2 col-sm-6 col-12 footer-information"
  }, [_c('h5', {
    staticClass: "title"
  }, [_vm._v("العنوان")]), _c('p', [_vm._v("دمشق - ميدان كورنيش "), _c('br'), _vm._v("مقابل صيدلية الكواكبي")]), _c('ul', {
    staticStyle: {
      "margin-right": "-15px"
    }
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://www.facebook.com/Tyateem/"
    }
  }, [_c('i', {
    staticClass: "fa fa-facebook",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://twitter.com/t_tamayoz"
    }
  }, [_c('i', {
    staticClass: "fa fa-twitter",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.youtube.com/user/tyateem"
    }
  }, [_c('i', {
    staticClass: "fa fa-youtube",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.instagram.com/t_tamayoz/"
    }
  }, [_c('i', {
    staticClass: "fa fa-instagram",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://t.me/t_tamayoz"
    }
  }, [_c('i', {
    staticClass: "fa fa-telegram",
    attrs: {
      "aria-hidden": "true"
    }
  })])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "scroll-top tran3s"
  }, [_c('i', {
    staticClass: "fa fa-angle-up",
    attrs: {
      "aria-hidden": "true"
    }
  })]);
}]

export { render, staticRenderFns }