import {httpClient} from '../../shared/services'
import {environment} from '../website-environment'

export default {
    fetchHomePage() {
        const url = environment.websiteApiBaseUrl + 'homepage'
        return httpClient.get(url)
            .then(res => res.data['data'])
            .catch(error => console.log(error))
    },

    paginate(url) {
        return httpClient.get(url)
            .then(res => res.data['data'])
            .catch(error => console.log(error))
    }
}
