var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "screen-one wow fadeInLeft animated",
    staticStyle: {
      "margin-top": "500px"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2266.png',
      "alt": "",
      "data-wow-duration": "2s"
    }
  })]), _vm._m(0)]), _c('div', {
    staticClass: "mobilePadding",
    staticStyle: {
      "padding-top": "120px !important",
      "padding-bottom": "75px"
    }
  }, [_vm._m(1), _c('img', {
    staticClass: "center mobile-img",
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'text.png',
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "container reverse"
  }, [_c('div', {
    staticClass: "seo-work-progress p-0"
  }, [_c('div', {
    staticClass: "theme-title-one text-center hide-pr show-pr mt-5"
  }, [_c('div', {
    staticClass: "row single-work-list py-5 wow fadeInLeft animated",
    staticStyle: {
      "padding-top": "0!important"
    }
  }, [_c('div', {
    staticClass: "col-lg-6 py- first"
  }, [_c('div', {
    staticClass: "img-box clearfix"
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2267.png',
      "alt": ""
    }
  })])]), _vm._m(2)]), _c('div', {
    staticClass: "row single-work-list py-5 wow fadeInUp animated"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-lg-6 first"
  }, [_c('div', {
    staticClass: "img-box clearfix"
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2263.png',
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "row single-work-list py-5 wow fadeInLeft animated"
  }, [_c('div', {
    staticClass: "col-lg-6 py- first"
  }, [_c('div', {
    staticClass: "img-box clearfix"
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2264.png',
      "alt": ""
    }
  })])]), _vm._m(4)]), _c('div', {
    staticClass: "row single-work-list wow fadeInUp animated",
    staticStyle: {
      "padding-top": "0!important",
      "padding-bottom": "50px!important"
    }
  }, [_vm._m(5), _c('div', {
    staticClass: "col-lg-6 py- first"
  }, [_c('div', {
    staticClass: "img-box clearfix"
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2262.png',
      "alt": ""
    }
  })])])])]), _vm._m(6), _c('div', {
    staticClass: "row wow fadeInRight animated mt-5 mb-3"
  }, [_c('img', {
    staticClass: "center",
    staticStyle: {
      "width": "10% !important"
    },
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2218.png',
      "alt": ""
    }
  })]), _vm._m(7)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('h3', {
    staticClass: "main-title wow fadeInUp animated pb-3  mobile-font-size-first",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" انضم اليوم "), _c('b', [_vm._v("لأسرة الكفلاء")])]), _c('h4', {
    staticClass: "main-title wow fadeInUp animated pb-5 mobile-font-size-second",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" وادخل في من قال فيهم رسول الله صلّى الله عليه وسلّم: ")]), _c('h4', {
    staticClass: "main-title wow fadeInUp animated pb-3 mobile-font-size-first",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_c('b', [_vm._v(" \"أنا وكافل اليتيم كهاتين في الجنة\"")])]), _c('h5', {
    staticClass: "main-title wow fadeInUp animated  pb-3 mobile-font-size-second",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" وقرن بين إصبعيه الوسطى والتي تلي الإبهام ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "main-title h4 mobile-font-size-first pb-3"
  }, [_c('b', [_vm._v("خطوات كفالة يتيم جديد في مؤسسة")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 last"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h4', {
    staticClass: "sub-title mb-3"
  }, [_c('b', [_vm._v("القدوم إلى المبنى الرئيسي لمؤسسة")])]), _c('h4', {
    staticClass: "sub-title mb-5"
  }, [_c('b', [_vm._v("التميز التنموية")])]), _c('h5', {
    staticClass: "sub-title mb-3 text-right-alignment ",
    staticStyle: {
      "line-height": "2 !important"
    }
  }, [_vm._v("والواقع في دمشق ، كورنيش الميدان ، مقابل صيدلية الكواكبي")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 last"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h4', {
    staticClass: "sub-title mb-5"
  }, [_c('b', [_vm._v("التوجه إلى قسم شؤون الكفلاء")])]), _c('h5', {
    staticClass: "sub-title  text-right-alignment ",
    staticStyle: {
      "line-height": "2 !important"
    }
  }, [_vm._v("يمكنك الاستفسار عند مكتب الاستقبال في الجمعية عن مكان القسم")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 py- last"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h4', {
    staticClass: "sub-title mb-5"
  }, [_c('b', [_vm._v("تعبئة استمارة كفالة يتيم جديد")])]), _c('h5', {
    staticClass: "sub-title  text-right-alignment ",
    staticStyle: {
      "line-height": "2 !important"
    }
  }, [_vm._v("يمكن التعرف على خيارات الكفالة سواء لأسرة أو ليتيم واحد فقط ومدة دفع الكفالة والتفاصيل المتبقية")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 py- last"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h4', {
    staticClass: "sub-title mb-5"
  }, [_c('b', [_vm._v("دفع مبلغ الكفالة الأول و استلام وصل الدفع")])]), _c('h5', {
    staticClass: "sub-title  text-right-alignment ",
    staticStyle: {
      "line-height": "2 !important"
    }
  }, [_vm._v("يمكن دفع الحد الأدنى لمبلغ الكفالة و المساهمة ببرامج التبرع المتاحة أيضاً")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "text-wrapper",
    staticStyle: {
      "padding-top": "50px !important"
    }
  }, [_c('h4', {
    staticClass: "sub-heading"
  }, [_vm._v("وبعد إنجاز هذه الخطوات ..")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center wow fadeInLeft animated mt-5 pb-200 "
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_c('b', [_vm._v("مبارك لك,لقد أصبحت كافلاً ")])])]);
}]

export { render, staticRenderFns }