var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.searchTerm.length === 0 ? [_vm._v(" " + _vm._s(_vm.content) + " ")] : _vm._l(_vm.highlightedParts, function (part, index) {
    return _c('span', {
      key: index,
      class: {
        highlight: _vm.isHighlighted(part)
      }
    }, [_vm._v(_vm._s(part))]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }