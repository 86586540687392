import {environment} from "../website-environment";
import {httpClient} from "../../shared/services";


export default {
    sendFeedback(message) {
        const url = environment.websiteApiBaseUrl + 'feedback';
        return httpClient.post(url, message)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
    save(feedback) {
        const url = environment.websiteApiBaseUrl + 'feedback';
        return httpClient.post(url, feedback)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
}
