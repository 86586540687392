import Vue from 'vue'
import VueRouter from 'vue-router'
import {websiteRoutes} from './website/website-index'
// import {donateRoutes} from './donate/donate-index'
import {ecommerceRoutes} from './ecommerce/ecommerce-index'
import VueMeta from 'vue-meta';
import Error from "./shared/views/Error";
import MasterLayout from "../layouts/MasterLayout";
import { zadiRoutes, zadiGuard } from "./zadi/zadi-routes"

Vue.use(VueRouter);
Vue.use(VueMeta);

const appRoutes = [
    {
        path: '/error/:errorCode/:errorMsg',
        name: 'Error',
        component: Error,
        meta: {layout: MasterLayout},
        props: true
    },
];

const routes = [...websiteRoutes, ...appRoutes, ...ecommerceRoutes, ...zadiRoutes];
const savedPositionRoutes = ['NewsNews','NewsEvent','NewsUpcomingEvent']
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition|| savedPositionRoutes.indexOf(to.name) !== -1) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  zadiGuard(to, from, next);
});

export default router
