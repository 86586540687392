var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-3 py-3 results"
  }, [_c('p', {
    staticClass: "results-text"
  }, [_vm._v("الأكثر بحثاً")]), _vm._l(_vm.recentSearchResults, function (item, index) {
    return _c('button', {
      key: index,
      staticClass: "results-item",
      on: {
        "click": function ($event) {
          return _vm.$emit('select', item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }