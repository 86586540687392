var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('WebsiteBanner'), _c('website-ten-years'), _c('WebsiteAbout'), _c('WebsiteServices'), _c('WebsiteVideo'), _vm.isDataFetched ? _c('WebsiteTestimonial', {
    attrs: {
      "testimonials": _vm.testimonials
    }
  }) : _vm._e(), _vm.isDataFetched ? _c('WebsiteStatistics', {
    attrs: {
      "analytics": _vm.analytics
    }
  }) : _vm._e(), _c('WebsiteContactBanner')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }