<template>
  <div class="modal fade" id="global-search-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content" style="border-radius: 16px; height: 560px;">
        <div class="modal-header">
          <SearchInput v-model="searchTerm" @clear="clearSearchHandler" />
        </div>
        <div v-if="searchTerm.length > 0" class="modal-header" style="padding-top: 0px; padding-bottom: 0px;">
          <div class="entity-tabs-container">
            <EntityTabItem
              title="عام"
              :count="entitiesCount.blog"
              :disabled="entitiesCount.blog === 0"
              :is-active="activeEntityTab === SearchAutocompleteType.BLOG"
              @click="activeEntityTab = SearchAutocompleteType.BLOG"
            />
            <EntityTabItem
              title="زادي"
              :count="entitiesCount.zadi"
              :disabled="entitiesCount.zadi === 0"
              :is-active="activeEntityTab === SearchAutocompleteType.ZADI"
              @click="activeEntityTab = SearchAutocompleteType.ZADI"
            />
          </div>
        </div>
        <div class="modal-body" style="overflow: auto; height: 497px;">
          <EntityList
            v-if="searchTerm.length > 0"
            :searchTerm="searchTerm"
            :type="activeEntityTab"
          />
          <RecentSearchResults v-else @select="searchTerm = $event" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormsInput from '@/app/shared/components/formsInput.vue';
import RecentSearchResults from './recent-search-results.vue';
import EntityTabItem from './entity-tabs/entity-tab-item.vue';
import EntityList from './entities/entity-list.vue';
import SearchInput from './search-input.vue';
import AutoCompleteRepository from '../repositories/auto-complete.js';
import { SearchAutocompleteType as _SearchAutocompleteType } from '../constants/entities';

const initActiveEntityTab = _SearchAutocompleteType.BLOG;
const initEntitiesCount = {
  zadi: 0,
  blog: 0,
}
const zadiKeywords = ['العدد', 'زادي', 'مجلة'];

export default {
  name: 'SearchBox',
  data() {
    return {
      searchTerm: '',
      entitiesCount: initEntitiesCount,
      activeEntityTab: initActiveEntityTab,
      SearchAutocompleteType: _SearchAutocompleteType,
    }
  },
  components: {
    RecentSearchResults,
    EntityTabItem,
    FormsInput,
    EntityList,
    SearchInput
  },
  watch: {
    'searchTerm': async function(newValue) {
      if (!newValue) return;

      if (zadiKeywords.includes(newValue)) {
        this.activeEntityTab = _SearchAutocompleteType.ZADI;
      }

      const response = await AutoCompleteRepository.getEntitiesCount(newValue);

      if (response.zadiCounts === 0 && this.activeEntityTab === _SearchAutocompleteType.ZADI) {
        this.activeEntityTab = _SearchAutocompleteType.BLOG;
      }
      else if (response.blogCounts === 0 && this.activeEntityTab === _SearchAutocompleteType.BLOG) {
        this.activeEntityTab = _SearchAutocompleteType.ZADI;
      }

      this.entitiesCount = {
        zadi: response.zadiCounts,
        blog: response.blogCounts,
      }
    }
  },
  methods: {
    clearSearchHandler() {
      this.activeEntityTab = initActiveEntityTab;
      this.entitiesCount = initEntitiesCount;
    }
  },
};
</script>

<style scoped>
.entity-tabs-container {
  gap: 15px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
