const DEFAULT_STORAGE_TYPE = localStorage;

export const set = (key, value, storage = DEFAULT_STORAGE_TYPE) => {
  if (typeof value === 'object') {
    storage.setItem(key, JSON.stringify(value));
  } else {
    storage.setItem(key, value);
  }
}

export const get = (key, storage = DEFAULT_STORAGE_TYPE) => {
  const value = storage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export const remove = (key, storage = DEFAULT_STORAGE_TYPE) => {
  storage.removeItem(key);
}

export const clear = (storage = DEFAULT_STORAGE_TYPE) => {
  storage.clear();
}