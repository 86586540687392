<template>
  <div class="training-form">
    <Loader :is-loading="isLoading"/>
    <div class="solid-inner-banner">
      <div class="container">
        <img :src="$websiteEnvironment.assetsDir + '/website-forms/Group 2293.png'" alt=""
             class="centered" style="padding-bottom:30px">
        <h4 class="pb-4 font-wight-bold"><b>طلب تدريب</b></h4>
        <h5 class=" font-wight-bold">لدى مؤسسة التميز التنموية</h5>
      </div>
    </div>

    <div class="solid-inner-banner banner">
      <div class="contact-form">
        <ValidationObserver ref="validator">
          <form @submit.prevent="onSubmit" class="form form-style-two" data-toggle="validator"
                id="contact-form"
                novalidate="true">
            <div class="row">
              <div class="col-lg-6 card-p paddingBottom" style="padding-right: 5%; padding-left: 2%;">
                <div class="card cardWidth paddingBottom" style="border-radius: 15px">
                  <div class="container">
                    <h5 class="firstMobileFontSize sub-heading"><b>المعلومات الشخصية</b></h5>
                    <forms-input name="الاسم الثلاثي" type="text" v-model="personalInfo.fullname"
                                 validationRules="required"/>

                    <div class="row">
                      <div class="col-lg-4 p-r ">
                        <h6 class="pb-5 inputLabel secondMobileFontSize labelColor"
                            style="padding-right:16%; padding-top:10px">
                          الجنس</h6>
                      </div>
                      <div class="col-lg-8 mp paddingTop" style="padding-right:5%">
                        <ValidationProvider name="الجنس" rules="required" v-slot="{ errors }">
                          <div class="form-check form-check-inline" style="padding-right:15px">
                            <input class="payment-radio-button" id="male" name="gender"
                                   type="radio" v-model="personalInfo.gender"
                                   value="male"
                            >
                            <h6 style="padding-right:10px"><b>ذكر</b></h6>
                          </div>

                          <div class="form-check form-check-inline" style="padding-right:50px">
                            <input class="payment-radio-button" id="female" name="gender"
                                   type="radio" v-model="personalInfo.gender"
                                   value="female"
                            >
                            <h6 style="padding-right:10px"><b>أنثى</b></h6>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <forms-select :options="nationalityOptions" :selectAction="selectOther" name="الجنسية"
                                  type="text" v-model="personalInfo.nationality" validationRules="required"/>
                    <forms-input name="الجنسية" type="text" v-if="addOtherNationality"
                                 v-model="personalInfo.nationality" validationRules="required"/>
                    <forms-input name="مكان القيد" type="text" v-model="personalInfo.kayd" validationRules="required"/>
                    <div class="row">
                      <div class="col-lg-4 p-r">
                        <h6 class="pb-5 inputLabel secondMobileFontSize labelColor"
                            style="padding-right:16%; padding-top:10px">
                          تاريخ الميلاد</h6>
                      </div>
                      <div class="col-lg-8 mp">
                        <ValidationProvider name="تاريخ الميلاد" rules="required" v-slot="{ errors }">
                          <date-picker align="center" dialog hide-overlay max-date="2008-12-31"
                                       min-date="1990-01-1" v-bind:class="[errors.length > 0 ? 'datePickerBorder' : '']"
                                       v-model="personalInfo.birthday"/>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <forms-input name="مكان الولادة" type="text" v-model="personalInfo.birthplace"
                                 validationRules="required"/>

                    <h5 class="firstMobileFontSize mp sub-heading"><b>معلومات التواصل</b></h5>
                    <forms-input name="رقم الهاتف" type="number" v-model="personalInfo.phone"
                                 validationRules="digits:7"/>

                    <forms-input :validationRules="{ required: true , digits: 10, regex: /^09\d{8}$/}"
                                 name="رقم الواتساب" type="number"
                                 v-model="personalInfo.mobile"/>

                    <forms-input name="العنوان" type="text" v-model="personalInfo.address" validationRules="required"/>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 card-p" style="padding-right:2%; padding-left: 5%">
                <forms-card :data-array="personalInfo.previous_expertise" :data-object=" {position:'', awarding:''}"
                            add-title="إضافة خبرة"
                            title="خبرات سابقة">
                  <template slot="inputs" slot-scope="{item,index}">
                    <forms-input :customWidth="true" :reduceFontSize="true" name="المسمى الوظيفي" type="text"
                                 v-model="item.position" validationRules="required"/>
                    <forms-input name="الجهة المانحة" type="text" v-model="item.awarding" validationRules="required"/>
                  </template>
                </forms-card>
                <forms-card :data-array="personalInfo.qualifications_and_skills"
                            :data-object=" {scientific_certificate: '', specialization: '',awarding: ''}"
                            :is-padding-top="true"
                            add-title="إضافة مؤهل" title="المؤهلات والخبرات">
                  <template slot="inputs" slot-scope="{item,index}">
                    <forms-input :customWidth="true" name="الشهادة العلمية" type="text"
                                 v-model="item.scientific_certificate" validationRules="required"/>
                    <forms-input name="الاختصاص" type="text" v-model="item.specialization"
                                 validationRules="required"/>
                    <forms-input name="الجهة المانحة" type="text" v-model="item.awarding" validationRules="required"/>
                  </template>
                </forms-card>

                <forms-card :data-array="personalInfo.teaching_subjects" :data-object=" {name:'', hours:''}"
                            :is-padding-top="true"
                            add-title="إضافة مادة" title="مواد تدريبية تعطيها">
                  <template slot="inputs" slot-scope="{item,index}">
                    <forms-input :customWidth="true" name="اسم المادة" type="text" v-model="item.name"
                                 validationRules="required"/>
                    <forms-input name="الساعات التدريبية" type="number" v-model="item.hours"
                                 validationRules="required"/>
                  </template>
                </forms-card>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="row justify-content-center sendButton centered" style="padding-top: 150px; padding-bottom: 100px">
        <button class="more-button solid-button-one wow fadeInLeft  animated" data-wow-delay="0.5s" form="contact-form"
                style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;"
                type="submit">
          ارسال
        </button>
      </div>
    </div>

  </div>
</template>

<script>

  import {RepositoryFactory} from "../../repositories/RepositoryFactory";
  import Loader from '../../../shared/components/Loader'
  import {DatePicker} from 'vue-drumroll-datetime-picker'
  import 'vue-drumroll-datetime-picker/dist/style.css'
  import vSelect from 'vue-select';
  import formsInput from '@/app/shared/components/formsInput.vue';
  import formsSelect from '@/app/shared/components/formsSelect.vue';
  import formsCard from '../../website-components/forms/formsCard.vue';
  import utilities from "@/app/shared/utilities/index"

  const trainingFormService = RepositoryFactory.get('form');

  export default {
    name: 'websiteJobApplicationForm',
    metaInfo: {
      title: ' طلب تدريب',
    },
    data() {
      return {
        personalInfo: {
          type: 'Training',
          fullname: '',
          birthday: '',
          birthplace: '',
          nationality: '',
          kayd: '',
          phone: '',
          mobile: '',
          address: '',
          qualifications_and_skills: [
            {
              scientific_certificate: '',
              specialization: '',
              awarding: ''
            }
          ],
          previous_expertise: [
            {
              position: '',
              awarding: ''
            }
          ],
          teaching_subjects: [
            {
              name: '',
              hours: ''
            }
          ]
        },
        addOtherNationality: false,
        nationalityOptions: ['سورية', 'فلسطينية', 'غير ذلك'],
        isLoading: false,
      }
    },
    components: {
      Loader,
      DatePicker,
      vSelect,
      formsInput,
      formsSelect,
      formsCard
    },
    methods: {
      init() {
        this.personalInfo = {
          type: 'Training',
          fullname: '',
          birthday: '',
          birthplace: '',
          nationality: '',
          kayd: '',
          phone: '',
          mobile: '',
          address: '',
          qualifications_and_skills: [
            {
              scientific_certificate: '',
              specialization: '',
              awarding: ''
            }
          ],
          previous_expertise: [
            {
              position: '',
              awarding: ''
            }
          ],
          teaching_subjects: [
            {
              name: '',
              hours: ''
            }
          ]
        };
        this.$refs.validator.reset();
      },
      selectOther(selected) {
        if (selected === 'غير ذلك') {
          this.addOtherNationality = true;
          this.personalInfo.nationality = '';
        }
        if ((selected === 'سورية') || (selected === 'فلسطينية'))
          this.addOtherNationality = false;
      },
      onSubmit() {
        this.$refs.validator.validate().then(success => {
          if (!success) {
            utilities.checkForm(this.$refs.validator);
          } else {
            this.isLoading = true;
            trainingFormService.save(this.personalInfo).then(response => {
                this.isLoading = false;
                this.$swal({
                  icon: 'success',
                  title: 'نجح',
                  text: response.message
                });
                this.init();
              }
            );
          }
        });
      }
    },
  }
</script>

<style>
  @import '../../../../../public/assets/css/formsStyle.css';
</style>
