var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: ("col-lg-" + (_vm.labelCol) + " p-r")
  }, [_c('h6', {
    staticClass: "pb-5 inputLabel secondMobileFontSize labelColor",
    staticStyle: {
      "padding-right": "14%",
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])]), _c('div', {
    class: ("col-lg-" + (_vm.inputCol) + " mp paddingTop"),
    attrs: {
      "id": "textAreaWidth"
    }
  }, [_c('div', {
    class: {
      'textAreaPadding': _vm.textAreaPadding
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.name,
      "rules": _vm.validationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.computedValue,
            expression: "computedValue"
          }],
          staticClass: "form-control",
          staticStyle: {
            "height": "110px"
          },
          style: [errors.length > 0 ? {
            'border-color': '#DE3557'
          } : {}],
          domProps: {
            "value": _vm.computedValue
          },
          on: {
            "input": function ($event) {
              if ($event.target.composing) { return; }
              _vm.computedValue = $event.target.value;
            }
          }
        }), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }