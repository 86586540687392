var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "solid-inner-banner"
  }, [_c('h2', {
    staticClass: "page-title",
    staticStyle: {
      "padding-bottom": "1%"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.blogDetails.title) + " ")]), _c('br'), _vm.blogDetails.tag ? _c('span', {
    staticClass: "tag"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.blogDetails.tag.name) + " ")])]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "our-blog blog-default mb-80"
  }, [_c('div', {
    staticClass: "full-grid-container"
  }, [_c('div', {
    staticClass: "single-blog-post"
  }, [_c('br'), _c('div', {
    staticClass: "img-holder",
    staticStyle: {
      "width": "50%",
      "margin": "auto",
      "max-height": "30em"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.blogDetails.image_url,
      "alt": ""
    }
  }), _vm.blogDetails.video_url ? _c('a', {
    staticClass: "fancybox video-button",
    attrs: {
      "href": _vm.blogDetails.video_url,
      "data-fancybox": ""
    }
  }, [_c('i', {
    staticClass: "flaticon-play-button-2"
  })]) : _vm._e()]), _c('div', {
    staticClass: "post-data"
  }, [_vm.showDate ? _c('a', {
    staticClass: "date",
    attrs: {
      "href": "#"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm._f("moment")(_vm.blogDetails.date, "MM-DD-YYYY")))])]) : _vm._e(), _c('h5', {
    staticClass: "blog-title-two title",
    domProps: {
      "innerHTML": _vm._s(_vm.blogDetails.short_desc)
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.blogDetails.full_desc)
    }
  })]), _c('div', {
    staticClass: "single-block mt-5"
  }, [_c('ul', {
    staticClass: "keywords-tag clearfix mb-80"
  }, [_c('li', {
    staticClass: "social"
  }, [_c('ShareNetwork', {
    staticClass: "whatsapp pointer",
    attrs: {
      "title": _vm.blogDetails.title,
      "url": _vm.currentUrl,
      "hashtags": "التميز_في_كفالة_اليتيم",
      "network": "WhatsApp"
    }
  }, [_c('i', {
    staticClass: "fa fa-whatsapp"
  })])], 1), _c('li', {
    staticClass: "social"
  }, [_c('ShareNetwork', {
    staticClass: "facebook pointer",
    attrs: {
      "title": _vm.blogDetails.title,
      "url": _vm.currentUrl,
      "hashtags": "التميز_في_كفالة_اليتيم",
      "network": "facebook"
    }
  }, [_c('i', {
    staticClass: "fa fa-facebook",
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1), _c('li', {
    staticClass: "social"
  }, [_c('ShareNetwork', {
    staticClass: "twitter pointer",
    attrs: {
      "title": _vm.blogDetails.title,
      "url": _vm.currentUrl,
      "hashtags": "التميز_في_كفالة_اليتيم",
      "network": "twitter"
    }
  }, [_c('i', {
    staticClass: "fa fa-twitter",
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1), _c('li', {
    staticClass: "social"
  }, [_c('ShareNetwork', {
    staticClass: "telegram pointer",
    attrs: {
      "title": _vm.blogDetails.title,
      "url": _vm.currentUrl,
      "hashtags": "التميز_في_كفالة_اليتيم",
      "network": "Telegram"
    }
  }, [_c('i', {
    staticClass: "fa fa-telegram",
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1)])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }