<template>
  <div>
    <div id="theme-banner-one">

      <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
        <img  :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2266.png'"
             style="margin-top: 500px"
             alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
      </div>
      <div class="container">
        <div class="main-wrapper">
          <h3 class="main-title wow fadeInUp animated pb-3  mobile-font-size-first" data-wow-delay="0.4s">
            انضم اليوم  <b>لأسرة الكفلاء</b>
          </h3>
          <h4 class="main-title wow fadeInUp animated pb-5 mobile-font-size-second" data-wow-delay="0.4s">
            وادخل في من قال فيهم رسول الله صلّى الله عليه وسلّم:
          </h4>
          <h4 class="main-title wow fadeInUp animated pb-3 mobile-font-size-first" data-wow-delay="0.4s">
            <b> "أنا وكافل اليتيم كهاتين في الجنة"</b>
          </h4>
          <h5 class="main-title wow fadeInUp animated  pb-3 mobile-font-size-second" data-wow-delay="0.9s">
            وقرن بين إصبعيه الوسطى والتي تلي الإبهام
          </h5>
        </div>
      </div>
    </div>
            <div class="mobilePadding" style="padding-top:120px !important; padding-bottom: 75px">
              <div class="text-center">
          <span class="main-title h4 mobile-font-size-first pb-3"><b>خطوات كفالة يتيم جديد في مؤسسة</b> </span>
              </div>
          <img  class= "center mobile-img" :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'text.png'"
                alt="" style="width:30%">
            </div>
    <div class="container reverse">
      <div class="seo-work-progress p-0">
        <div class="theme-title-one text-center hide-pr show-pr mt-5">
        <div class="row single-work-list py-5 wow fadeInLeft animated" style="padding-top: 0!important;">
          <div class="col-lg-6 py- first">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2267.png'"
                   alt="">
            </div>
          </div>

          <div class="col-lg-6 last">
            <div class="text-wrapper">
              <h4 class="sub-title mb-3"><b>القدوم إلى المبنى الرئيسي لمؤسسة</b></h4>
               <h4 class="sub-title mb-5"><b>التميز التنموية</b></h4>
              <h5 class="sub-title mb-3 text-right-alignment " style="line-height: 2 !important;">والواقع في دمشق ، كورنيش الميدان ، مقابل صيدلية الكواكبي</h5>
            </div>
          </div>
        </div>

        <div class="row single-work-list py-5 wow fadeInUp animated">
          <div class="col-lg-6 last">
            <div class="text-wrapper">
              <h4 class="sub-title mb-5"><b>التوجه إلى قسم شؤون الكفلاء</b></h4>
              <h5 class="sub-title  text-right-alignment " style="line-height: 2 !important;">يمكنك الاستفسار عند مكتب الاستقبال  في الجمعية عن مكان القسم</h5>
            </div>
          </div>
          <div class="col-lg-6 first">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2263.png'"
                   alt="">
            </div>
          </div>
        </div>

        <div class="row single-work-list py-5 wow fadeInLeft animated">
          <div class="col-lg-6 py- first">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2264.png'"
                   alt="">
            </div>
          </div>
          <div class="col-lg-6 py- last">
            <div class="text-wrapper">
              <h4 class="sub-title mb-5"><b>تعبئة استمارة كفالة يتيم جديد</b></h4>
              <h5 class="sub-title  text-right-alignment " style="line-height: 2 !important;">يمكن التعرف على خيارات الكفالة سواء لأسرة أو ليتيم واحد فقط ومدة دفع الكفالة والتفاصيل المتبقية</h5>
            </div>
          </div>
        </div>
        <div class="row single-work-list wow fadeInUp animated" style="padding-top: 0!important; padding-bottom: 50px!important;">
          <div class="col-lg-6 py- last">
            <div class="text-wrapper">
              <h4 class="sub-title mb-5"><b>دفع مبلغ الكفالة الأول و استلام وصل الدفع</b></h4>
              <h5 class="sub-title  text-right-alignment " style="line-height: 2 !important;">يمكن دفع الحد الأدنى لمبلغ الكفالة و المساهمة ببرامج التبرع المتاحة أيضاً</h5>
            </div>
          </div>
          <div class="col-lg-6 py- first">
            <div class="img-box clearfix">
              <img   :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2262.png'"
                   alt="">
            </div>
          </div>
        </div>
      </div>
          <div class="row justify-content-center">
          <div class="text-wrapper" style="padding-top: 50px !important;">
            <h4 class="sub-heading">وبعد إنجاز هذه الخطوات ..</h4>
          </div>
          </div>
      <div class="row wow fadeInRight animated mt-5 mb-3">
      <img  style="width:10% !important;" class="center" :src="$websiteEnvironment.assetsDir + '/orphanSponsorshipSteps/' + 'Group 2218.png'"
            alt="">
    </div>
      <div class="row justify-content-center wow fadeInLeft animated mt-5 pb-200 "><h4 class="text-center"><b>مبارك لك,لقد أصبحت كافلاً </b></h4></div>
    </div>
  </div>
  </div>
</template>

<script>
    export default {
        name: "orphanSponsorshipSteps",
        metaInfo: {
        title: 'خطوات كفالة يتيم',
       },
      data() {
        return {}
            // ...
          },
    }
</script>

<style scoped>
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
  }
  #theme-banner-one {
   background: none !important;
  }
  #theme-banner-one .illustration {
    left: -10% !important;
    top: -50% !important;
    width: 50% !important;
  }
  .seo-work-progress .single-work-list {
    padding-bottom: 200px !important;
  }
  @media (max-width: 575px) {
    #theme-banner-one .main-wrapper {
      padding-bottom: 30px !important;
    }
    .seo-work-progress .single-work-list {
      padding-bottom: 50px !important;
      padding-top: 50px!important;
    }
    .mobile-font-size-first {
      font-size: 30px !important;
    }

    .mobile-font-size-second {
      font-size: 15px !important;
      padding-bottom: 0px !important;
    }
    .seo-work-progress .single-work-list .text-wrapper {
      padding-right: 15px !important;
      padding-bottom: 0px !important;
    }
    .seo-work-progress .single-work-list:last-child {
      padding-bottom: 50px !important;
    }
    .reverse {
      display: flex !important;
    }

    .reverse .first {
      order: 1 !important;
    }

    .reverse .last {
      order: 2 !important;
    }

    .text-right-alignment {
      text-align: right;
    }

    .mobilePadding {
      padding-top:30px !important;
      padding-bottom:0px !important;
  }
    .mobile-img{
      width: 50% !important;
    }
    }
  .seo-work-progress .single-work-list:nth-child(odd):before {
    content: url(../../../../public/assets/images/website/website-svgs/line-2.svg) !important;
    width: 50% !important;
  }
  .seo-work-progress .single-work-list:nth-child(even):before {
    content: url(../../../../public/assets/images/website/website-svgs/line-1.svg) !important;
    width: 50% !important;
  }
</style>
