<template>
  <div class="d-flex flex-column" style="gap: 10px;">
    <SearchEmptyState v-if="entities.length === 0 && !isLoading" />
    <EntityItem
      v-for="(entity) in entities"
      :key="entity.tamayozId"
      :title="entity.title"
      :searchTerm="searchTerm"
      :magazineLink="entity.url"
      :tamayozId="entity.tamayozId"
      :type="entity.type.toLowerCase()"
      :description="entity.short_desc || ''"
      :badge="badgeConfig[entity.type.toLowerCase()]"
      :img="entity.imageUrl || images[entity.type.toLowerCase()]"
    />
    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-results"></div>
      <div slot="no-more"></div>
      <div class="d-flex flex-column" style="gap: 10px;" slot="spinner">
        <SkeletonLoader />
        <SkeletonLoader />
        <SkeletonLoader />
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import EntityItem from './entity-item';
import SearchEmptyState from '../search-empty-state.vue';
import AutoCompleteRepository from '../../repositories/auto-complete';
import SkeletonLoader from '../../shared/components/skeleton-loader.vue';
import { BlogType, ZadiType, globalSearchLimit } from '../../constants/entities';

export default {
  name: 'EntityList',
  props: {
    searchTerm: String,
    type: String,
  },
  components: {
    EntityItem,
    SearchEmptyState,
    SkeletonLoader,
  },
  data() {
    return {
      isLoading: false,
      entities: [],
      count: 0,
      limit: globalSearchLimit,
      badgeConfig: {
        [BlogType.NEWS]: {
          title: 'آخر الأخبار',
          color: '#4D5F2F',
        },
        [BlogType.FAQ]: {
          title: 'سؤال وجواب',
          color: '#5D4858',
        },
        [BlogType.SUCCESS_STORY]: {
          title: 'قصص نجاح',
          color: '#DDAB26',
        },
        [BlogType.EVENT]: {
          title: 'النشاطات',
          color: '#2396B5',
        },
      },
      images: {
        [ZadiType.BOOK]: '/assets/images/website/global-search/zadi-entity-default-icon.svg',
        [ZadiType.MAGAZINE]: '/assets/images/website/global-search/zadi-entity-default-icon.svg',
        [BlogType.NEWS]: '/assets/images/website/global-search/news-entity-default-icon.svg',
        [BlogType.EVENT]: '/assets/images/website/global-search/event-entity-default-icon.svg',
        [BlogType.FAQ]: '/assets/images/website/global-search/question-entity-default-icon.svg',
        [BlogType.SUCCESS_STORY]: '/assets/images/website/global-search/success-story-entity-default-icon.svg',
      }
    }
  },
  watch: {
    'searchTerm': function(newValue) {
      if (newValue) {
        this.search({ limit: globalSearchLimit, query: newValue, type: this.type });
      }
    },
    'type': async function(newValue) {
      if (this.searchTerm) {
        this.search({ limit: globalSearchLimit, query: this.searchTerm, type: newValue });
      }
    }
  },
  methods: {
    async infiniteHandler($state) {
      try {
        await this.search({
          type: this.type,
          limit: this.limit,
          query: this.searchTerm,
        });

        if (this.entities.length > 0 && this.count >= this.limit) {
          this.limit += globalSearchLimit;
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (error) {
        $state.error();
      }
    },
    async search({ limit, query, type }) {
      this.isLoading = true;
      try {
        const response = await AutoCompleteRepository.search({ limit, query, type });
        this.entities = response.data;
        this.count = response.count;
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>
