<template>
  <div>
    <Loader :isLoading="isLoading"></Loader>
    <div id="theme-banner-four">
      <img :src="$websiteEnvironment.assetsDir + '/website-svgs/contact-us/' + 'Group-1576.svg'"
           alt=""
           class="screen-one wow fadeInLeft animated" data-wow-duration="2s" style="margin-top: 100px; width: 1000px">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt=""
           class="shape-three">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt=""
           class="shape-four">
      <div class="round-shape-one"></div>
      <div class="round-shape-two"></div>
      <div class="round-shape-three"></div>
      <div class="round-shape-four"></div>
      <div class="container">
        <div class="main-wrapper">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s"> تواصل معنا </h1>
          <h4 class="wow fadeInUp animated mt-5" data-wow-delay="0.9s">
            أخبرنا بما يجول في بالك
          </h4>
        </div> <!-- /.main-wrapper -->
      </div> <!-- /.container -->
    </div>
    <contact-form type="Message"></contact-form>
    <div class="contact-us-section contact-minimal container-fluid">
      <div class="row">
        <div class="col">
          <iframe
            allowfullscreen=""
            aria-hidden="false" frameborder="0" height="400em"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1663.7412049451823!2d36.291312042986625!3d33.48882398715922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1518e0e9a8d34ea9%3A0x73637e4d79db5587!2z2KfZhNiq2YXZitiyINmB2Yog2YPZgdin2YTYqSDYp9mE2YrYqtmK2YU!5e0!3m2!1sen!2snl!4v1605352300764!5m2!1sen!2snl"
            style="border:0;"
            tabindex="0" width="100%"></iframe>
        </div>
      </div> <!-- /.row -->
    </div> <!-- /.contact-us-section -->
  </div>
</template>

<script>
  import Loader from '../../shared/components/Loader'
  import contactForm from "../website-components/website-contact/contactForm";


  export default {
    name: 'website-contact',
    metaInfo: {
      title: 'تواصل معنا',
    },
    components: {
      Loader,
      contactForm
    },
    data() {
      return {
        feedback: {
          type: 'Message',
          author: '',
          email: '',
          mobile: '',
          message: ''
        },
        isLoading: true
      }
    },
    mounted() {
      this.isLoading = false;
    },
    methods: {
      init() {
        this.$objectHelpers.initObjectMembers(this.feedback);
        this.feedback.type = 'Message';
        this.$refs.validator.reset();
      }
    },
  }
</script>

<style scoped>

  .custom-map-height {
    height: 100vh;
  }

  #theme-banner-four .screen-one {
    position: absolute;
    left: -10%;
    top: -20%;
    z-index: 1;
    width: 55% !important;
  }

</style>
