var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "solid-inner-banner banner",
    staticStyle: {
      "padding-top": "70px",
      "padding-bottom": "0px !important"
    }
  }, [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('ValidationObserver', {
    ref: "validator"
  }, [_c('form', {
    staticClass: "form form-style-two",
    attrs: {
      "data-toggle": "validator",
      "id": "contact",
      "novalidate": "true"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "card-p paddingBottom",
    staticStyle: {
      "padding-right": "4%",
      "padding-left": "4%"
    }
  }, [_c('div', {
    staticClass: "card cardWidth paddingBottom",
    staticStyle: {
      "border-radius": "15px",
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row pt-5"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('tm-input', {
    attrs: {
      "reduceFontSize": true,
      "name": "الاسم الثلاثي",
      "type": "text",
      "validationRules": "required"
    },
    model: {
      value: _vm.feedback.author,
      callback: function ($$v) {
        _vm.$set(_vm.feedback, "author", $$v);
      },
      expression: "feedback.author"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-4",
    attrs: {
      "id": "email"
    }
  }, [_c('tm-input', {
    attrs: {
      "reduceFontSize": true,
      "name": "البريد الالكتروني",
      "type": "text",
      "validationRules": "email"
    },
    model: {
      value: _vm.feedback.email,
      callback: function ($$v) {
        _vm.$set(_vm.feedback, "email", $$v);
      },
      expression: "feedback.email"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('tm-input', {
    attrs: {
      "reduceFontSize": true,
      "validationRules": {
        required: true,
        digits: 10,
        regex: /^09\d{8}$/
      },
      "name": "رقم الموبايل",
      "type": "number"
    },
    model: {
      value: _vm.feedback.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.feedback, "mobile", $$v);
      },
      expression: "feedback.mobile"
    }
  })], 1)]), _c('div', {
    staticClass: "textArea"
  }, [_c('tm-text-area', {
    attrs: {
      "inputCol": 11,
      "labelCol": 1,
      "textAreaPadding": true,
      "id": "textAreaField",
      "name": "السؤال",
      "validationRules": "required"
    },
    model: {
      value: _vm.feedback.message,
      callback: function ($$v) {
        _vm.$set(_vm.feedback, "message", $$v);
      },
      expression: "feedback.message"
    }
  })], 1)])])])])]), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center sendButton centered",
    staticStyle: {
      "padding-top": "70px",
      "padding-bottom": "70px"
    }
  }, [_c('button', {
    staticClass: "more-button solid-button-one wow fadeInLeft  animated",
    staticStyle: {
      "visibility": "visible",
      "animation-delay": "1.5s",
      "animation-name": "fadeInLeft"
    },
    attrs: {
      "data-wow-delay": "0.5s",
      "form": "contact",
      "type": "submit"
    }
  }, [_vm._v(" ارسال ")])]);
}]

export { render, staticRenderFns }