var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "our-blog blog-default pt-150"
  }, [_c('div', {
    staticClass: "full-grid-container"
  }, [_c('div', {
    staticClass: "single-blog-post"
  }, [_c('div', {
    staticClass: "img-holder"
  }, [_c('img', {
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-video/bg/video-bg.jpg',
      "alt": ""
    }
  }), _vm._m(0)]), _vm._m(1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "fancybox video-button",
    attrs: {
      "data-fancybox": "",
      "href": "https://youtu.be/pCk5rcN3L58"
    }
  }, [_c('i', {
    staticClass: "flaticon-play-button-2"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "post-data"
  }, [_c('a', {
    staticClass: "date",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("3, Jun, 2020")]), _c('h5', {
    staticClass: "blog-title-two title"
  }, [_c('a', {
    attrs: {
      "href": "https://youtu.be/pCk5rcN3L58"
    }
  }, [_vm._v(" أحلام الأطفال ترعاها أياديكم")])]), _c('p', [_vm._v("يسعى التميز لتنفيذِ طلباتِ أطفالِه وتحقيقِ أحلامِهم عن طريقِ رصدِ احتياجاتِهم وإيصالِها للكفلاءِ ")])]);
}]

export { render, staticRenderFns }