var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-page-wrapper"
  }, [this.$showNavbar ? _c('the-navbar', {
    attrs: {
      "isWhite": _vm.isWhite
    }
  }) : _vm._e(), _c('search-box'), _vm._t("default"), _c('warranty-fixed-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isHidden,
      expression: "!isHidden"
    }]
  }), _c('the-footer')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }