<template>
  <div class=" fixedButton show-in-mobile">
    <div class="container">
      <div class="main-wrapper text-left p-3">
        <button
          class="more-button solid-button-one wow fadeInLeft  animated"
          data-wow-delay="1s"
          style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;" @click=" $router.push('/orphanSponsorshipSteps')">
          <img src="assets/images/website/orphanSponsorshipSteps/logo.png" style="display: inline-flex !important; padding-left: 9px"> اكفل يتيم الآن
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "WarrantyFixedButton"
    }
</script>

<style scoped>
  .show-in-mobile{
    display: none;
  }
  @media (max-width: 575px) {
    .show-in-mobile {
      display: block;
    }
    .fixedButton {
      position: fixed;
      z-index: 2;
      bottom: 0px;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
</style>
