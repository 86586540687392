var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "0.5s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "wow fadeInDown animated",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/coming-soon/' + 'coming-soon.svg',
      "alt": ""
    }
  })]), _c('img', {
    staticClass: "oval-one",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-five",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-six",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-seven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eight",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-nine",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-ten",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eleven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg',
      "alt": ""
    }
  }), _vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper text-center"
  }, [_c('div', {
    staticClass: "row my-5 wow fadeInUp animated"
  }, [_c('div', {
    staticClass: "col-md-6 col-sm-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('img', {
    staticClass: "m-auto",
    attrs: {
      "src": "assets/images/logo/logo-svg.svg",
      "alt": "tamayoz-logo",
      "width": "100px",
      "height": "150px"
    }
  })]), _c('div', {
    staticClass: "row mt-5"
  }, [_c('h1', {
    staticClass: "main-title m-auto",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(".. COMING SOON")])])]), _c('div', {
    staticClass: "col-md-6 col-sm-12"
  })])])]);
}]

export { render, staticRenderFns }