var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vld-parent"
  }, [_c('loading', {
    attrs: {
      "active": _vm.isLoading,
      "loader": _vm.loaderType,
      "color": _vm.color,
      "height": 128,
      "width": 128,
      "lock-scroll": true,
      "is-full-page": true
    },
    on: {
      "update:active": function ($event) {
        _vm.isLoading = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }