var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "zadi-page-wrapper"
  }, [_c('zadi-header'), _vm._t("default"), _c('the-footer')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }