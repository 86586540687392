var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    attrs: {
      "id": "theme-banner-one"
    }
  }, [_c('div', {
    staticClass: "illustration wow zoomIn animated",
    attrs: {
      "data-wow-delay": "1s",
      "data-wow-duration": "2s"
    }
  }, [_c('img', {
    staticClass: "wow fadeInLeft animated",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/success-storeis/' + 'Group-903.svg',
      "alt": ""
    }
  })]), _c('img', {
    staticClass: "oval-one",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-five",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-six",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-seven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eight",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-nine",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-ten",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-eleven",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "round-shape-one"
  }), _vm._m(0)]), _c('div', [_vm._m(1), _c('div', {
    staticClass: "our-blog blog-masonry"
  }, [_vm.isEmptyResult ? _c('div', {
    staticClass: "container text-center"
  }, [_c('h5', [_vm._v("لا يوجد نتائج")])]) : _vm._e(), !_vm.isEmptyResult ? _c('div', {
    staticClass: "container"
  }, [_vm.blogsList ? _c('div', {
    staticClass: "row masnory-blog-wrapper  wow fadeInUp animated"
  }, _vm._l(_vm.blogsList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "isotop-item col-lg-4",
      on: {
        "click": function ($event) {
          return _vm.NavigateToDetailsPage(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "single-blog-post"
    }, [_c('div', {
      staticClass: "img-holder"
    }, [item.image_preview_url ? _c('img', {
      attrs: {
        "src": item.image_preview_url,
        "alt": "",
        "height": "230px"
      }
    }) : _vm._e(), !item.image_preview_url ? _c('img', {
      attrs: {
        "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/news/' + 'Group-1574.svg',
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "post-data"
    }, [_c('a', {
      staticClass: "date",
      attrs: {
        "href": "#"
      }
    }, [_vm._v(_vm._s(_vm._f("moment")(item.date, "MM-DD-YYYY")))]), _c('h5', {
      staticClass: "blog-title-one title",
      staticStyle: {
        "font-family": "Cairo !important"
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('p', {
      staticClass: "blog-title-tow title",
      staticStyle: {
        "font-family": "Cairo !important"
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:"
      },
      domProps: {
        "innerHTML": _vm._s(item.short_desc)
      }
    })]), _vm._m(2, true)])])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "text-center pt-90"
  })]) : _vm._e()]), _c('div', {
    staticClass: "element-section mb-150"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isPagenateBackHidden,
      expression: "!isPagenateBackHidden"
    }],
    staticClass: "theme-pager prev mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('previous');
      }
    }
  }, [_c('span', {
    staticClass: "flaticon-next mx-2"
  }), _vm._v("   السابق ")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.canLoadMore,
      expression: "canLoadMore"
    }],
    staticClass: "theme-pager next mx-4",
    on: {
      "click": function ($event) {
        return _vm.paginate('next');
      }
    }
  }, [_vm._v(" التالي    "), _c('span', {
    staticClass: "flaticon-back mx-2"
  })])])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('h2', {
    staticClass: "wow fadeInUp animated h3",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" قصص مؤثرة أصبح أبطالها جزء متميّز "), _c('br'), _vm._v(" من أسرة "), _c('b', [_vm._v(" التميّز")])]), _c('p', {
    staticClass: "sub-title wow fadeInUp animated mt-5",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_vm._v(" تسليط الضوء على جزء من القصص المؤثرة التي رعتها "), _c('br'), _c('b', [_vm._v("جمعية التميّز")]), _vm._v(" فنجحت في تحدي الظروف والمصاعب وتجاوزها "), _c('br'), _vm._v(" وصولاً إلى نجاحات باهرة ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "solid-inner-banner mt-0 pt-5"
  }, [_c('h2', {
    staticClass: "page-title"
  }, [_vm._v("من قصصنا")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "read-more",
    attrs: {
      "href": "javascript:"
    }
  }, [_c('i', {
    staticClass: "flaticon-back-1"
  })]);
}]

export { render, staticRenderFns }