<template>
  <div :style="[isPaddingTop ? {'margin-top': '30px'} : {}]" class="card cardWidth paddingBottom"
       style="border-radius: 15px;">
    <div class="row justify-content-between mt-4">
      <div class="col-6">
        <h5 class="firstMobileFontSize pl-4"><b>{{title}}</b></h5>
      </div>
      <div class="col-6">
        <div @click="addObject()" class="d-flex flex-row justify-content-end mr-3">
          <div class="pr-2">
            <img :src="$websiteEnvironment.assetsDir + '/website-forms/add_icon.png'"
                 alt="" style="color: #43A047; cursor: pointer">
          </div>
          <div>
            <h6 class="secondMobileFontSize" style="color: #43A047; cursor: pointer">
              {{addTitle}}
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!--<slot name="loop">-->
    <div :key="index"
         class="card mp" slot="loop"
         style="border-radius: 9px; margin:30px" v-for="(item, index) in dataArray">
      <div class="container">
        <div class="row justify-content-end">
          <img :src="$websiteEnvironment.assetsDir + '/website-forms/delete_icon.png'"
               @click="removeObject(index)" alt=""
               class="deleteIcon mt-3 mr-4 "
               style="height:25px; cursor: pointer;" v-if="index !==0">
        </div>
        <div :style="[index!== 0 ? {'padding-top': '0px !important'} : {}]" class="pt-5 paddingTop">
          <slot :index="index" :item="item" name="inputs"></slot>
        </div>
      </div>
    </div>
    <!--</slot>-->
  </div>
</template>

<script>
  import toasted from "@/app/shared/utilities/toasted"

  export default {
    name: "formsCard",
    props: {
      title: {type: String, required: true},
      addTitle: {type: String, required: true},
      dataArray: {type: Array, required: true},
      dataObject: {type: Object, required: true},
      isPaddingTop: {type: Boolean, default: false}
    },
    methods: {
      addObject() {
        if (Object.keys(this.dataObject).length === 0)
          this.dataArray.push('');
        else
          this.dataArray.push(this.dataObject);
        toasted.success('تمت إضافة حقل جديد')
      },
      removeObject(index) {
        this.dataArray.splice(index, 1);
        toasted.failed('تم حذف الحقل')
      },
    }
  }
</script>

<style>
  @import '../../../../../public/assets/css/formsStyle.css';
</style>
