import Vue from 'vue';
import authRepository from "../repositories/authRepository";
import toasted from '../../shared/utilities/toasted'
import router from '../../app-routes'
import { get, set, remove } from '../../shared/utilities/storage';

const TOKEN_STORAGE_KEY = 'zadi-token';
const USER_STORAGE_KEY = 'zadi-user';

const auth = new Vue({
    data() {
        return {
            authToken: null,
            authUser: null,
            isLoading: false,
        };
    },
    created() {
        this.authToken = get(TOKEN_STORAGE_KEY);
        this.authUser = get(USER_STORAGE_KEY);
        if (!this.isAuthenticated) {
            this.clearAuth()
        }
    },
    computed: {
        isAuthenticated() {
            return !!(this.authToken && this.isValidUser);
        },
        isValidUser() {
            return !!(this.authUser && typeof this.authUser === 'object' && !Array.isArray(this.authUser) && Object.keys(this.authUser)?.length);
        },
        isTemporaryPassword() {
            return this.isAuthenticated && this.authUser?.isTemporaryPassword;
        },
        user() {
            if (!this.authUser) {
                this.authUser = get(USER_STORAGE_KEY);
            }
            return this.authUser;
        },
        token() {
            if (!this.authToken) {
                this.authToken = get(TOKEN_STORAGE_KEY);
            }
            return this.authToken;
        },
    },
    methods: {
        getToken() {
            if (!this.authToken) {
                this.authToken = get(TOKEN_STORAGE_KEY);
            }
            return this.authToken;
        },
        setToken(token) {
            this.authToken = token;
            set(TOKEN_STORAGE_KEY, token);
        },
        getUser() {
            if (!this.authUser) {
                this.authUser = get(USER_STORAGE_KEY);
            }
            return this.authUser;
        },
        setUser(user) {
            this.authUser = user;
            set(USER_STORAGE_KEY, user);
        },
        clearAuth() {
            remove(TOKEN_STORAGE_KEY);
            remove(USER_STORAGE_KEY);
            this.authToken = null;
            this.authUser = null;
        },
        async login(credentials) {
            try {
                this.isLoading = true;
                const res = await authRepository.login(credentials);
                this.setUser(res?.data?.data?.user);
                this.setToken(res?.data?.data?.tokens?.access_token);
                const isTemporaryPassword = res?.data?.data?.user?.isTemporaryPassword;
                toasted.success('تم تسجيل الدخول بنجاح')
                if (isTemporaryPassword) {
                    router.push({ name: 'ZadiResetPassword' });
                    return
                }
                router.push({ name: 'ZadiHome' });
            } catch (err) {
                toasted.failed('غير مصرح لك بالدخول');
            } finally {
                this.isLoading = false;
            }
        },
        async resetPassword(credentials) {
            try {
                this.isLoading = true;
                const res = await authRepository.resetPassword(credentials);
                this.setUser(res?.data)
                toasted.success('تم تعيين كلمة المرور بنجاح')
                router.push({ name: 'ZadiHome' });
            } finally {
                this.isLoading = false;
            }
        },
        async refreshToken() {
            // const coverLoader = document.getElementById('cover-loader');
            // coverLoader.style.display = "flex";
            try {
                this.isLoading = true;
                const res = await authRepository.getMe();
                this.setUser(res?.data);
            } finally {
                this.isLoading = false;
                // coverLoader.style.display = "none";
            }
        },
        logout() {
            this.clearAuth();
            if (window) {
                window.location.replace('#/zadi/login');
            }
        },
    },
});


export default auth;