var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? [_c('SkeletonLoader')] : [_c('div', {
    staticClass: "entity-item-container d-flex",
    staticStyle: {
      "gap": "15px"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.img ? _c('img', {
    staticClass: "entity-item-img",
    attrs: {
      "width": "52",
      "height": "53",
      "src": _vm.img,
      "alt": "icon"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center flex-column w-100",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('div', {
    staticClass: "entity-item-text-container d-flex align-items-center",
    staticStyle: {
      "gap": "10px"
    }
  }, [_c('p', {
    staticClass: "entity-item-text-head",
    attrs: {
      "title": _vm.title
    }
  }, [_c('HighlightContent', {
    attrs: {
      "searchTerm": _vm.searchTerm,
      "content": _vm.title
    }
  })], 1), _vm.badge ? _c('p', {
    staticClass: "entity-item-text-badge",
    style: {
      background: _vm.badge.color
    }
  }, [_vm._v(" " + _vm._s(_vm.badge.title) + " ")]) : _vm._e()]), _vm.description ? _c('p', {
    staticClass: "entity-item-desc",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }) : _vm._e()])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }