<template>
    <div class="main-page-wrapper">
        <slot/>
        <the-footer/>
    </div>
</template>

<script>
    import TheFooter from '@/app/shared/components/TheFooter.vue'

    export default {
        name: "BasicLayout",
        components: {
            TheFooter
        }
    }
</script>

<style scoped>

</style>
