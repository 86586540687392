var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "our-feature-app py-5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row single-feature-box"
  }, [_c('div', {
    staticClass: "col-lg-6 order-lg-last"
  }, [_c('div', {
    staticClass: "text-wrapper mt-0"
  }, [_c('div', {
    staticClass: "row mt-5 mb-5"
  }, [_c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-1244.svg',
      "alt": ""
    }
  })]), _vm._m(0)]), _c('div', {
    staticClass: "row mt-5 mb-5"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-1243.svg',
      "alt": ""
    }
  })]), _vm._m(1), _c('div', {
    staticClass: "col-2"
  })]), _c('div', {
    staticClass: "row mt-5 mb-5"
  }, [_c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-4"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-1242.svg',
      "alt": ""
    }
  })]), _vm._m(2)])])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-6 order-lg-first"
  }, [_c('div', {
    staticClass: "feature-img-box"
  }, [_c('img', {
    staticClass: "shape-one",
    attrs: {
      "src": _vm.$sharedEnvironment.assetsDir + '/shape/shape-45.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-two",
    attrs: {
      "src": _vm.$sharedEnvironment.assetsDir + '/shape/shape-46.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-three",
    attrs: {
      "src": _vm.$sharedEnvironment.assetsDir + '/shape/shape-47.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-four",
    attrs: {
      "src": _vm.$sharedEnvironment.assetsDir + '/shape/shape-48.svg',
      "alt": ""
    }
  }), _c('img', {
    staticClass: "shape-five",
    attrs: {
      "src": _vm.$sharedEnvironment.assetsDir + '/shape/shape-49.svg',
      "alt": ""
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col",
    attrs: {
      "dir": "ltr"
    }
  }, [_c('img', {
    staticClass: "m-auto",
    attrs: {
      "src": _vm.$websiteEnvironment.assetsDir + '/website-svgs/home/' + 'Group-737.svg'
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('h1', [_vm._v("أهدافنا")]), _c('carousel', {
    attrs: {
      "autoplay": true,
      "nav": false,
      "dots": true,
      "responsive": {
        0: {
          items: 1
        }
      }
    }
  }, _vm._l(_vm.ourTargetItemsList, function (targetItem, index) {
    return _c('span', {
      key: index
    }, [_c('span', [_vm._v(_vm._s(targetItem.description))])]);
  }), 0)], 1)])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('h3', [_vm._v("رسالتنا")])]), _c('div', {
    staticClass: "row"
  }, [_c('p', [_vm._v("نموذج إنساني رائد")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('h3', [_vm._v("رؤيتنا")])]), _c('div', {
    staticClass: "row"
  }, [_c('p', [_vm._v("تحقيق نموذج متكامل للأعمال الخيرية التنموية الحديثة يحتذى به على الساحة الوطنية. و الوصول إلى مجتمع متكامل ومتكافل.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('h3', [_vm._v("قيمنا")])]), _c('div', {
    staticClass: "row"
  }, [_c('p', [_vm._v("الكرامة الإنسانية , الخدمة , الأمانة , الاحترام , الخصوصية")])])]);
}]

export { render, staticRenderFns }