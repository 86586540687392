<template>
  <div class="search-container">
    <i class="fa fa-search" style="color: #667085;" />
    <input
      type="text"
      placeholder="ابحث عن ما تريد .."
      class="search-input"
      :value="value"
      @input="updateValue($event.target.value)"
    />
    <button v-if="value.length > 0" @click="clearSearchHandler">
      <i class="fa fa-close" style="color: #667085;" />
    </button>
  </div>
</template>

<script>
import { debounceCallback } from '../utilities';

export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateValue(newValue) {
      const that = this;
      debounceCallback(function () {
        that.$emit('input', newValue);
      }, 1500);
    },
    clearSearchHandler() {
      this.$emit('input', '');
      this.$emit('clear');
    }
  },
};
</script>

<style scoped>
.search-input {
  width: 100%;
  flex-grow: 1;
  border: none;
  outline: none;
}
.search-container {
  gap: 15px;
  padding: 0px 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
