import {environment} from "../website-environment";
import {httpClient} from "../../shared/services";

export default {
  fetchBlogByType(blogType, page, perPage, tagId) {
    const URL = environment.websiteApiBaseUrl + 'blog/type/' + blogType + '?perPage=' + perPage + '&' + page;
    const url = ((tagId === '') || (tagId === undefined)) ? URL : URL + '&tag=' + tagId;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error))
  },

  getBlogByTitle(title) {
    const url = environment.websiteApiBaseUrl + 'blog/' + title
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error))
  },

  fetchAllBlogsByType(blogType,) {
    const url = environment.websiteApiBaseUrl + 'blog/type/' + blogType;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error))
  },

  getById(id) {
    const url = `${environment.websiteApiBaseUrl}blogs/${id}`;
    return httpClient.get(url)
      .then(res => res.data['data'])
      .catch(error => console.log(error))
  },
}
