<template>
  <div>
    <Loader :isLoading="isLoading"></Loader>

    <div class="solid-inner-banner">
      <h2 class="page-title" style="padding-bottom:1%">
        <b>
          {{ blogDetails.title }}
        </b>
        <br>
        <span v-if="blogDetails.tag" class="tag">
                    <b>
                        {{ blogDetails.tag.name }}
                    </b>
                </span>
      </h2>
    </div> <!-- /.solid-inner-banner -->

    <div class="our-blog blog-default mb-80">
      <div class="full-grid-container">
        <div class="single-blog-post">
          <br>
          <div class="img-holder" style="width: 50%; margin: auto; max-height: 30em;">
            <img :src="blogDetails.image_url" alt="">
            <a v-if="blogDetails.video_url" :href="blogDetails.video_url" class="fancybox video-button" data-fancybox><i
              class="flaticon-play-button-2"></i></a>
          </div>
          <div class="post-data">
            <a v-if="showDate" class="date" href="#">
              <b>{{ blogDetails.date | moment("MM-DD-YYYY") }}</b></a>
            <h5 class="blog-title-two title" v-html="blogDetails.short_desc"></h5>
            <p v-html="blogDetails.full_desc"></p>

          </div>
          <div class="single-block mt-5">
            <ul class="keywords-tag clearfix mb-80">
              <li class="social">
                <ShareNetwork
                  :title="blogDetails.title"
                  :url="currentUrl"
                  class="whatsapp pointer"
                  hashtags="التميز_في_كفالة_اليتيم"
                  network="WhatsApp"
                >
                  <i class="fa fa-whatsapp"></i>
                </ShareNetwork>
              </li>
              <li class="social">
                <ShareNetwork
                  :title="blogDetails.title"
                  :url="currentUrl"
                  class="facebook pointer"
                  hashtags="التميز_في_كفالة_اليتيم"
                  network="facebook"
                >
                  <i aria-hidden="true" class="fa fa-facebook"></i>
                </ShareNetwork>
              </li>
              <li class="social">
                <ShareNetwork
                  :title="blogDetails.title"
                  :url="currentUrl"
                  class="twitter pointer"
                  hashtags="التميز_في_كفالة_اليتيم"
                  network="twitter"
                >
                  <i aria-hidden="true" class="fa fa-twitter"></i>
                </ShareNetwork>
              </li>
              <li class="social">
                <ShareNetwork
                  :title="blogDetails.title"
                  :url="currentUrl"
                  class="telegram pointer"
                  hashtags="التميز_في_كفالة_اليتيم"
                  network="Telegram"
                >
                  <i aria-hidden="true" class="fa fa-telegram"></i>
                </ShareNetwork>
              </li>
            </ul>
          </div> <!-- /.single-block -->
        </div>
      </div>
    </div>

    <!-- <DonateFormComponent :donationProgramTitle="blogDetails.title"></DonateFormComponent> -->
  </div>
</template>

<script>
import { watch } from 'vue';
import Loader from '../../shared/components/Loader'
import {RepositoryFactory} from "../repositories/RepositoryFactory";
// import DonateFormComponent from "../../donate/donate-components/DonateFormComponent";

const blogRepository = RepositoryFactory.get('blog')
export default {
  name: 'FullDetails',
  components: {
    Loader,
    // DonateFormComponent
  },
  data() {
    return {
      blogDetails: {
        id: null,
        title: '',
      },
      styleObject: {},
      isLoading: true,
      currentUrl: '',
      showDate: false
    }
  },
  watch: {
    $route() {
      this.getBlogDetails();
    }
  },
  methods: {
    getBlogDetails() {
      this.currentUrl = window.location.origin + '/#' + this.$route.fullPath;

      if (this.$route.query.id)
        blogRepository.getById(this.$route.query.id).then((res) => {
          this.getBlogData(res);
        });
      else if (this.$route.query.title)
        blogRepository.getBlogByTitle(this.$route.query.title).then((res) => {
          this.getBlogData(res);
        });
    },
    getBlogData(response) {
      this.blogDetails = response;
      this.styleObject['background-image'] = 'url(' + this.blogDetails.image_url + ')';
      this.isLoading = false;
    }
  },

  mounted() {
    this.getBlogDetails();
  }
}
</script>

<style scoped>
.custom-image-background {
  background-image: url('../../../../public/assets/images/blog/31.jpg');
}

.tag {
  display: inline-block;
  padding: 5px;
  border: 1px solid #e0e0e0;
  margin: 5px;
  color: #989ca2;
  transition: all .3s ease-in-out;
  font-size: 50%;
  text-transform: uppercase;
}

.tag:hover {
  border-color: #483c51;
  background-color: #483c51;
  color: white;
}

.solid-inner-banner {
  text-align: center;
}

.facebook {
  color: white !important;
  background-color: #2d88ff;
}

.whatsapp {
  color: white !important;
  background-color: #1ebea5;
}

.telegram {
  color: white !important;
  background-color: #0088cc;
}

.twitter {
  color: white !important;
  background-color: #1a91da;
}

.social {
  padding: 5px;
  display: inline-block;
  margin: 3px;
}

.pointer {
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
</style>
