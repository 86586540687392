export const SearchAutocompleteType = {
  BLOG: 'blog',
  ZADI: 'zadi',
};

export const BlogType = {
  NEWS: 'news',
  SUCCESS_STORY: 'successstory',
  FAQ: 'faq',
  EVENT: 'event',
};

export const ZadiType = {
  MAGAZINE: 'magazine',
  BOOK: 'book',
}

export const globalSearchLimit = 10;
