import Vue from "vue";

export default {
  options: {
    theme: 'bubble',
    position: "bottom-left",
    duration: 2000,
    singleton: true
  },
  failed(message) {
    Vue.toasted.error(message, this.options)
  },
  notify(message) {
    Vue.toasted.error(message, this.notificationOptions)
  },
  success(message) {
    Vue.toasted.success(message, this.options)
  }
}
