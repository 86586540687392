const environment = {
    assetsDir: 'assets/images'
}
export {
    environment
}
export default {
    install (Vue) {
        Vue.sharedEnvironment = environment
        Vue.prototype.$sharedEnvironment = environment
    }
}
