var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-5"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.seconds) + " ")]), _c('h5', [_vm._v("Seconds")])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.minutes) + " ")]), _c('h5', [_vm._v("mins")])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.hours) + " ")]), _c('h5', [_vm._v("hours")])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.days) + " ")]), _c('h5', [_vm._v("days")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }